import HomePage from "pages/home";
import AvailabilityPage from "pages/availability";

const Route = (title, link, slug, icon = null, section = "pages", component) => {
    return {
        'title': title,
        'link': link,
        'slug': slug,
        'icon': icon,
        'section': section
    }
}
export const WEBSITE_ROUTES = {
    HOME: Route('Home', '/', null, null, null, <HomePage/>),
    AVAILABILITY: Route('Availability', '/availability', null, null, null, AvailabilityPage),
    CONFIRMATION_DETAILS: Route('Confirmation Details', '/confirmation-details', null, null, null, null),
    BOOKING_CONFIRMATION: Route('Booking Confirmation', '/booking-confirmation', null, null, null, null),
    PAGES:[]
}