import { useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInfoCircle,
  faTimesCircle,
  faBriefcase,
  faUsers,
  faMusic,
} from '@fortawesome/free-solid-svg-icons'
import VehiclePreview from 'components/availability/vehicle-preview'
import VehicleWaiver from 'components/availability/vehicle-waiver'
import VehicleWaiverDaily from 'components/availability/vehicle-waiver-daily'
import D2DVehicleWaiver from 'components/d2d/d2d-vehicle-waiver'
import VehiclePreviewHeader from 'components/availability/vehicle-preview-header'
import VehicleDetails from 'components/availability/vehicle-details'
import { DetailsContext } from 'contexts/details'

const VehicleItem = ({
  vehicleItem,
  setSelectedVehicle,
  executeChooseVehicleScroll,
  vehicleData,
}) => {
  const [toggleVehicleDetails, setToggleVehicleDetails] = useState(false)
  const [displayWaiverInstruction, setDisplayWaiverInstruction] = useState(
    false,
  )
  let { details } = useContext(DetailsContext)

  return (
    <>
      <div
        className="grid grid-cols-12 relative rounded-lg border border-gray-100"
        onClick={() => setDisplayWaiverInstruction(true)}
        onMouseLeave={() => setDisplayWaiverInstruction(false)}
      >
        {displayWaiverInstruction && (
          <div className="absolute w-full left-0 top-36 lg:top-28 z-10 p-2 rounded-full text-xs bg-white opacity-90 text-center cursor-default">
            {vehicleItem.standardCover !== '0' &&
              vehicleItem.superCover !== '0' &&
              `Select Standard or Super Waiver option below`}
            {vehicleItem.standardCover !== '0' &&
              vehicleItem.superCover === '0' &&
              `Select Standard Waiver option below`}
            {vehicleItem.standardCover === '0' &&
              vehicleItem.superCover !== '0' &&
              `Select Super Waiver option below`}
          </div>
        )}

        <VehiclePreview
          vehicleItem={vehicleItem}
          executeChooseVehicleScroll={executeChooseVehicleScroll}
          size="normal"
          vehicleData={vehicleData}
        />
        <div
          className={`${
            !toggleVehicleDetails && `hidden`
          } col-span-12 absolute bottom-0 h-full w-full left-0 z-10 bg-white opacity-95 p-3 text-gray-900 text-sm rounded-lg`}
        >
          <div className="leading-6">
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="float-right text-gray-900 text-lg mr-1 cursor-pointer focus:outline-none"
              onClick={() =>
                setToggleVehicleDetails(toggleVehicleDetails ? false : true)
              }
            />
            <VehiclePreviewHeader
              vehicleItem={vehicleItem}
              size="normal"
              vehicleData={vehicleData}
            />
          </div>
          <div className="leading-5">
            <div className="font-bold uppercase pt-3 mt-3 border-t border-gray-200">
              Vehicle Details
            </div>
            <VehicleDetails vehicleData={vehicleData} />
          </div>
        </div>

        <div className="col-span-12">
          <div className="grid grid-cols-12 relative">
            <button
              type="button"
              className="absolute left-3 -top-10 z-8 px-1 rounded-full text-sm bg-white focus:outline-none flex flex-wrap content-center"
              onClick={() =>
                setToggleVehicleDetails(toggleVehicleDetails ? false : true)
              }
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="text-blue-900 text-lg mr-1"
              />
              View Car Details
            </button>

            {!details.isD2D ? (
              <>
                {vehicleItem.standardCover !== '0' ? (
                  <div
                    className="col-span-6 bg-gray-100 p-3 leading-none cursor-pointer focus:outline-none rounded-bl-lg border-b-4 border-gray-100 hover:border-blue-400"
                    onClick={() => setSelectedVehicle(vehicleItem, 'CDW')}
                  >
                    <VehicleWaiver vehicleItem={vehicleItem} waiverType="CDW" />
                  </div>
                ) : (
                  <div className="col-span-6 bg-gray-100 p-3 leading-none focus:outline-none rounded-bl-lg border-b-4 border-gray-100"></div>
                )}

                {vehicleItem.superCover !== '0' ? (
                  <div
                    className="col-span-6 bg-gray-200 p-3 leading-none cursor-pointer focus:outline-none rounded-br-lg border-b-4 border-gray-200 hover:border-blue-400"
                    onClick={() => setSelectedVehicle(vehicleItem, 'SCDW')}
                  >
                    <VehicleWaiver
                      vehicleItem={vehicleItem}
                      waiverType="SCDW"
                    />
                  </div>
                ) : vehicleItem.dailyChargeAmount !== '0' ? (
                  <div
                    className="col-span-6 bg-gray-200 p-3 leading-none cursor-pointer focus:outline-none rounded-br-lg border-b-4 border-gray-200 hover:border-blue-400"
                    onClick={() => setSelectedVehicle(vehicleItem, 'SCDW')}
                  >
                    <VehicleWaiverDaily vehicleItem={vehicleItem} />
                  </div>
                ) : (
                  <div className="col-span-6 bg-gray-200 p-3 leading-none focus:outline-none rounded-br-lg border-b-4 border-gray-200"></div>
                )}
              </>
            ) : (
              <div
                className={`${
                  details.isD2D
                    ? `col-span-12 rounded-br-lg rounded-bl-lg`
                    : `col-span-6 rounded-br-lg`
                } bg-gray-200 p-3 leading-none cursor-pointer focus:outline-none border-b-4 border-gray-200 hover:border-blue-400`}
                onClick={() => setSelectedVehicle(vehicleItem, 'SCDW')}
              >
                <D2DVehicleWaiver vehicleItem={vehicleItem} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default VehicleItem
