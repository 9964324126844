import {useState, useEffect} from "react";
import {useParams} from 'react-router-dom';
import {ApiConsumer} from "api/ApiConsumer";
import BookNowBanner from "components/footer/booknow-banner";
import FleetItem from "pages/fleet-item";
import Header from "components/header";
import Footer from "components/footer";
import * as Sentry from "@sentry/react";
import {Helmet} from "react-helmet";

const Fleet = () => {

    const params = useParams()

    const [fleetCategoriesData, setFleetCategoriesData] = useState({});
    const [fleetCategoriesLoaded, setFleetCategoriesLoaded] = useState(false);

    const [fleetData, setFleetData] = useState({});
    const [fleetLoaded, setFleetLoaded] = useState(false);

    const [allFleet, setAllFleet] = useState([]);

    const [fleetPage, setFleetPage] = useState([]);


    useEffect(() => {

        window.scrollTo(0, 0);

        //Get fleet categories
        const fleetCategoriesUrl = "https://cms.bluucarrental.com/wp-json/wp/v2/fleet_categories";
        //Reset
        setFleetCategoriesData({})
        setFleetCategoriesLoaded(false)

        ApiConsumer.get(fleetCategoriesUrl)
            .then(res => {

                let categories =
                    (params.slug !== undefined) ?
                        res.data.filter(category => category.slug === params.slug && category.count !== 0)
                        : res.data.filter(category => category.count !== 0);

                setFleetCategoriesData(categories);

                //Reset
                setFleetData({});
                setFleetLoaded(false)
                setFleetPage([]);

                categories.map((category, i) => {

                    const categoryUrl = "https://cms.bluucarrental.com/wp-json/wp/v2/fleet?fleet_categories=" + category.id + "&per_page=100"

                    ApiConsumer.get(categoryUrl)
                        .then(res => {

                            let fleet = res.data.filter(fleet => fleet.status === "publish");
                            setFleetData((prevState) => ({...prevState, [category.slug]: fleet}))
                        })
                        .catch(err => {
                            Sentry.captureException(err)
                        })
                        .finally(() => {
                            setFleetLoaded(true)
                        })

                })

            })
            .catch(err => {
                Sentry.captureException(err)
            })
            .finally(() => {
                setFleetCategoriesLoaded(true)
            })

    }, [params])

    useEffect(() => {
        if (fleetCategoriesLoaded && fleetLoaded) {

            let fleet = [];

            fleetCategoriesData.map((category, i) => {
                fleet[i] = {
                    name: category.name,
                    fleet: fleetData[category.slug]
                }
            })
            setAllFleet(fleet)
        }

    }, [fleetCategoriesLoaded, fleetCategoriesData, fleetLoaded, fleetData])

    return <>

        {(fleetCategoriesLoaded && fleetLoaded) ? <>
                <Helmet>
                    <title>
                        {
                            fleetCategoriesData.filter(x => x.slug === params.slug).length ? fleetCategoriesData[0].name : "Our Fleet"
                        } | Bluu Car Rental
                    </title>
                </Helmet>
            </>
            : null
        }


        <Header/>


        {
            (fleetCategoriesLoaded && fleetLoaded) ? <>


                    <div className="w-full bg-gray-200 ">
                        <div className="container m-auto py-10 max-w-screen-xl">
                            <div className="col-span-12 ">
                                <h1 className="text-3xl lg:text-5xl px-4 lg:p-6 text-blue-900">{
                                    fleetCategoriesData.filter(x => x.slug === params.slug).length ? <span
                                        dangerouslySetInnerHTML={{__html: fleetCategoriesData[0].name}}/> : "Our Fleet"
                                }</h1>
                            </div>
                        </div>
                    </div>


                    <div className="container m-auto py-8">
                        <div className="flex flex-wrap">
                            {
                                <FleetItem allFleet={allFleet}/>
                            }
                        </div>
                    </div>

                </>

                : null

        }

        <BookNowBanner/>

        <Footer/>
    </>
}
export default Fleet;
