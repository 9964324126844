import { useEffect, useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faLock } from '@fortawesome/free-solid-svg-icons'
import { CONSTANTS } from 'contants/constants'
import Loader from 'components/loader'
import { ApiConsumer } from 'api/ApiConsumer'
import * as Sentry from '@sentry/react'
import { API_ROUTES } from 'routes/api'
import { QuoteContext, SaveQuoteContext } from 'contexts/quote'
import { useHistory } from 'react-router-dom'
import { useUtils } from 'hooks/utils'
import { add } from 'date-fns'
import { BranchContext } from 'contexts/branch'
import { DetailsContext } from 'contexts/details'

export default function SendQuoteModal({
  setVisibleModal,
  setPagePostModalSlug,
}) {
  const [isSendingQuote, setIsSendingQuote] = useState(false)
  const [quoteError, setQuoteError] = useState('')
  const [validSendQuoteDetails, setValidSendQuoteDetails] = useState({})
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [quoteDetails, setQuoteDetails] = useState({})
  const [termsChecked, setTermsChecked] = useState(false)
  const { branches } = useContext(BranchContext)
  const { details } = useContext(DetailsContext)
  let { validEmail, getDate, getDateTime, encodeHTMLEntities } = useUtils()

  let history = useHistory()
  let { quote, setQuote } = useContext(QuoteContext)
  let { saveQuote, setSaveQuote } = useContext(SaveQuoteContext)
  let userData = JSON.parse(sessionStorage.getItem('sessionUser'))

  const validateSendQuoteDetails = () => {
    let invalidFieldFound = false
    setQuoteError('')

    const formFields = [
      { name: 'firstName', displayName: 'First name' },
      { name: 'lastName', displayName: 'Last name' },
      { name: 'email', displayName: 'Email address' },
      {
        name: 'consent',
        displayName: 'you have read and accept the Terms & Conditions',
      },
    ]

    formFields.map((field, i) => {
      if (
        !invalidFieldFound &&
        (quoteDetails[field.name] === undefined ||
          quoteDetails[field.name].length === 0 ||
          (typeof quoteDetails[field.name] === 'string' &&
            quoteDetails[field.name].trim() === '') ||
          quoteDetails[field.name] === 'selected')
      ) {
        setValidSendQuoteDetails((prevState) => ({
          ...prevState,
          [field.name]: true,
        }))

        if (field.name === 'consent' && !termsChecked)
          setQuoteError('Please confirm ' + field.displayName + '.')
        else setQuoteError('Please enter your ' + field.displayName + '.')
        invalidFieldFound = true
      } else if (
        !invalidFieldFound &&
        field.name === 'email' &&
        quoteDetails[field.name].length &&
        !validEmail(quoteDetails[field.name])
      ) {
        setValidSendQuoteDetails((prevState) => ({
          ...prevState,
          [field.name]: true,
        }))
        setQuoteError('Please enter a valid  ' + field.displayName + '.')
        invalidFieldFound = true
      } else {
        setValidSendQuoteDetails((prevState) => ({
          ...prevState,
          [field.name]: false,
        }))
      }
    })

    if (invalidFieldFound) return false
    else SendQuote()
  }

  function SendQuote(method) {
    setIsSendingQuote(true)

    const vanSelected = branches.filter(
      (branch) =>
        quote.requestedPickUpBranch === branch.branchCode &&
        branch.isVanRental === true,
    )

    let payload = {
      ...quoteDetails,
      ...saveQuote,
      method: method,
      requestReference: quote?.quoteNumber,
    }

    payload = details.isD2D
      ? {
          ...payload,
          moP_Type: details.moP_Type,
          pickupFlightType: details.pickupFlightType,
          pickupFlightNumber: details.pickupFlightNumber,
          dropOffFlightNumber: '',
          collectionArea: details.PICKUP_AREA_DETAILS,
          collectionAddress: encodeHTMLEntities(details.PICKUP_BRANCH_ADDRESS),
          collectFromAirportName: encodeHTMLEntities(
            details.PICKUP_BRANCH_ADDRESS,
          ),
          deliveryAddress: encodeHTMLEntities(details.DROPOFF_BRANCH_ADDRESS),
          deliverToAirportName: encodeHTMLEntities(
            details.DROPOFF_BRANCH_ADDRESS,
          ),
          tripKms: details.tripKms.toString(),
          numberPassengers: details.numberPassengers.toString(),
          collectionAddress1: encodeHTMLEntities(
            details.COLLECTIONADDRESS1_BRANCH_ADDRESS,
          ),
          collectionAddress2: encodeHTMLEntities(
            details.COLLECTIONADDRESS2_BRANCH_ADDRESS,
          ),
          deliveryAddress1: encodeHTMLEntities(
            details.DELIVERYADDRESS1_BRANCH_ADDRESS,
          ),
          deliveryAddress2: encodeHTMLEntities(
            details.DELIVERYADDRESS2_BRANCH_ADDRESS,
          ),
          rateCodeDesc: saveQuote.rateDesc,
          vehicleGroupDesc: saveQuote.vehicleGrp_Desc,
        }
      : payload

    ApiConsumer.post(
      details.isD2D ? API_ROUTES.DOOR2DOOR.SAVE : API_ROUTES.QUOTE.SAVE,
      payload,
    )
      .then((res) => {
        ApiConsumer.post(API_ROUTES.QUOTE.REMINDER, {
          name: quoteDetails.firstName,
          surname: quoteDetails.lastName,
          emailAddress: quoteDetails.email,
          quoteNumber: quote?.quoteNumber,
        }).then((res) => {})

        payload = {
          page: 'get-quote',
          isQuote: true,
          isVanRental: vanSelected.length ? 'Y' : 'N',
          isCorporate: quote.requestedAccountType === 'C' ? 'Y' : 'N',
          isTour: quote.requestedAccountType === 'T' ? 'Y' : 'N',
          isD2D: details.isD2D ? 'Y' : 'N',
          ...quoteDetails,
          ...saveQuote,
          quote,
          expiryDate:
            getDate(add(new Date(), { days: 2 }).toString()) + ' 23:59:59',
        }

        payload = details.isD2D
          ? {
              ...payload,
              pickupFlight:
                details.pickupFlightType === 'selected'
                  ? 'N/A'
                  : details.pickupFlightType +
                    ' (' +
                    details.pickupFlightNumber +
                    ')',
              dropOffFlightNumber: '',
              collectionAddress: encodeHTMLEntities(
                details.PICKUP_BRANCH_ADDRESS,
              ),
              collectionArea: encodeHTMLEntities(details.PICKUP_AREA_DETAILS),
              deliveryAddress: encodeHTMLEntities(
                details.DROPOFF_BRANCH_ADDRESS,
              ),
              deliveryAddress: encodeHTMLEntities(
                details.DROPOFF_BRANCH_ADDRESS,
              ),
              numberPassengers: details.numberPassengers,
              collectionAddress1: encodeHTMLEntities(
                details.COLLECTIONADDRESS1_BRANCH_ADDRESS,
              ),
              collectionAddress2: encodeHTMLEntities(
                details.COLLECTIONADDRESS2_BRANCH_ADDRESS,
              ),
              deliveryAddress1: encodeHTMLEntities(
                details.DELIVERYADDRESS1_BRANCH_ADDRESS,
              ),
              deliveryAddress2: encodeHTMLEntities(
                details.DELIVERYADDRESS2_BRANCH_ADDRESS,
              ),
              cc: userData.accountEmail ? userData.accountEmail : '',
            }
          : payload

        ApiConsumer.post(
          'https://weblogs.bluevinegroup.co.za/booking/send-external-email/ajax/',
          JSON.stringify(payload),
        )
          .then((res) => {
            if (res.data.error !== undefined) setQuoteError(res.data.error)
            else {
              let userReservationData = {
                reservationFirstname: quoteDetails.firstName,
                reservationLastname: quoteDetails.lastName,
                reservationNumber: quote.quoteNumber,
                reservationEmail: quoteDetails.email,
              }
              sessionStorage.setItem(
                'sessionUserReservation',
                JSON.stringify(userReservationData),
              )

              setFormSubmitted(true)
            }
          })
          .catch((error) => {
            setQuoteError(error)
            Sentry.captureException(error)
          })
          .finally(() => {
            setIsSendingQuote(false)
            setFormSubmitted(true)
          })
      })
      .catch((error) => {
        setQuoteError(error)
        Sentry.captureException(error)
      })
      .finally(() => {
        setIsSendingQuote(false)
        setFormSubmitted(true)
      })
  }

  useEffect(() => {
    setQuoteError('')
    setValidSendQuoteDetails({})
    setFormSubmitted(false)
  }, [quoteDetails])

  useEffect(() => {
    if (userData !== null) {
      setQuoteDetails((prevState) => ({
        ...prevState,
        [`firstName`]: userData.accountFirstname,
      }))

      setQuoteDetails((prevState) => ({
        ...prevState,
        [`lastName`]: userData.accountLastname,
      }))
      setQuoteDetails((prevState) => ({
        ...prevState,
        [`email`]: userData.accountEmail,
      }))
      setSaveQuote((prevState) => ({
        ...prevState,
        [`fee`]: details.fee,
      }))
      setQuote((prevState) => ({
        ...prevState,
        [`fee`]: details.fee,
      }))
    }
  }, [])

  return (
    <>
      <div className="mb-4">
        <h3
          className="text-xl leading-6 font-medium text-gray-900"
          id="modal-title"
        >
          Send Your Quotation
        </h3>
        {!formSubmitted && (
          <div className="mt-2 text-sm ">
            <p className="text-gray-500">
              <strong>Quotation will be saved</strong> under the details you
              enter below and the person will be able to access it via "Manage
              Booking".
            </p>
          </div>
        )}
      </div>

      {formSubmitted ? (
        <div className="col-span-12">
          <div
            className="bg-green-100 border-t-4 border-green-300 rounded-b-lg p-6 shadow-sm mb-5"
            role="alert"
          >
            <div className="flex">
              <div>
                <p>
                  <strong>Successfully Sent.</strong>
                  <br />
                  Quote have been successfully sent to {quoteDetails.email}.
                  Your quote reference is <strong>{quote?.quoteNumber}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <form
          onSubmit={(event) => {
            event.preventDefault()
            return validateSendQuoteDetails()
          }}
          method="POST"
        >
          <div className="mt-2">
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700"
            >
              First name: <span className="text-red-600">*</span>
            </label>
            <input
              id="firstName"
              name="firstName"
              type="text"
              className={`style-input ${
                validSendQuoteDetails.firstName
                  ? `border-red-700`
                  : `border-gray-300`
              }`}
              onChange={(event) => {
                setQuoteDetails((prevState) => ({
                  ...prevState,
                  [`firstName`]: event.target.value,
                }))
              }}
              value={quoteDetails.firstName}
            />
          </div>

          <div className="mt-2">
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-gray-700"
            >
              Last name: <span className="text-red-600">*</span>
            </label>
            <input
              id="lastName"
              name="lastName"
              type="text"
              className={`style-input ${
                validSendQuoteDetails.lastName
                  ? `border-red-700`
                  : `border-gray-300`
              }`}
              onChange={(event) => {
                setQuoteDetails((prevState) => ({
                  ...prevState,
                  [`lastName`]: event.target.value,
                }))
              }}
              value={quoteDetails.lastName}
            />
          </div>

          <div className="mt-2">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email address: <span className="text-red-600">*</span>
            </label>
            <input
              id="email"
              name="email"
              type="text"
              className={`style-input ${
                validSendQuoteDetails.email
                  ? `border-red-700`
                  : `border-gray-300`
              }`}
              onChange={(event) => {
                setQuoteDetails((prevState) => ({
                  ...prevState,
                  [`email`]: event.target.value,
                }))
              }}
              value={quoteDetails.email}
            />
          </div>

          <div className="my-2">
            <label className=" rounded-full text-sm cursor-pointer focus:outline-none py-2 lg:mx-2">
              <input
                type="checkbox"
                name="consent"
                value={!termsChecked ? 'Terms &amp; Conditions' : ''}
                className="h-4 w-4 mt-0.5 cursor-pointer"
                aria-labelledby="terms-conditions"
                onChange={(event) => {
                  setQuoteDetails((prevState) => ({
                    ...prevState,
                    ['consent']: event.target.value,
                  }))
                }}
                onClick={() => setTermsChecked(termsChecked ? false : true)}
                checked={termsChecked}
              />
              <span className="ml-1">
                I have read and accept the{' '}
                <span
                  onClick={() => {
                    setVisibleModal(CONSTANTS.MODAL_TYPE.PAGEPOST)
                    setPagePostModalSlug('terms-and-conditions')
                  }}
                  className="text-blue-400 hover:underline cursor-pointer"
                >
                  Website Terms and Conditions
                </span>
              </span>
            </label>

            <div className="col-span-12 text-red-500 text-right my-1">
              {quoteError}
            </div>
            <div className="clear-both" />

            <button
              type="submit"
              disabled={isSendingQuote}
              className={`float-right inline-flex justify-center btn-light ml-2 ${
                isSendingQuote
                  ? `bg-gray-300 hover:bg-gray-300`
                  : `bg-blue-400 hover:bg-blue-400`
              }`}
            >
              Share via email
            </button>
            {isSendingQuote && (
              <div className="mt-2 float-right">
                <Loader />
              </div>
            )}
          </div>
          <div className="clear-both" />

          <hr className="border-b border-gray-300 my-4" />

          <div className="my-2 text-gray-500 text-sm">
            <FontAwesomeIcon icon={faLock} className="mr-1" />
            Your email address is 100% safe with us.
            <br />
            <span
              onClick={() => {
                setVisibleModal(CONSTANTS.MODAL_TYPE.PAGEPOST)
                setPagePostModalSlug('privacy-policy')
              }}
              className="text-blue-400 hover:underline cursor-pointer"
            >
              Read Privacy Policy
            </span>
            .
          </div>
        </form>
      )}
    </>
  )
}
