import {Fragment, useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {Dialog, Transition} from '@headlessui/react'
import {CONSTANTS} from "contants/constants";
import Loader from "components/loader";
import {ApiConsumer} from "api/ApiConsumer";
import * as Sentry from "@sentry/react";
import {API_ROUTES} from "routes/api";
import {UserContext} from "contexts/user";
import {useHistory} from "react-router-dom";
import {DetailsContext} from "contexts/details";

export default function CorpLoginModal({loginAccountType}) {

    const [loading, setLoading] = useState(false)
    const [loginDetails, setLoginDetails] = useState({accountType: "C"})
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [validLoginDetails, setValidLoginDetails] = useState({});
    const [corpError, setCorpError] = useState('')
    const [custError, setCustError] = useState('')
    let {setUser} = useContext(UserContext)
    let {details, setDetails} = useContext(DetailsContext)
    let history = useHistory()

    const validateCorpTourLogin = (loginAccountType) => {
    
        let invalidFieldFound = false;
        setCustError("");
        setCorpError("")

        const formFields = [
            {name: 'accountNumber', displayName : 'Account number' },
            {name: 'pinNumber', displayName : 'Pin number' },
        ]
        
        formFields.map((field, i) => {

            if (!invalidFieldFound && (
                loginDetails[field.name]===undefined || 
                loginDetails[field.name].length===0 || 
                (typeof loginDetails[field.name]==='string' && loginDetails[field.name].trim()==="") ||
                loginDetails[field.name]==="selected"
            )){
                setValidLoginDetails( prevState => ({
                    ...prevState,
                    [field.name]:true
                }));
                
                setCorpError("Please enter your "+field.displayName+".");
                
                invalidFieldFound = true;
            }
            else{
                setValidLoginDetails( prevState => ({
                    ...prevState,
                    [field.name]:false
                }));
            } 
        })

        if (invalidFieldFound)
            return false
        else{
            submit(loginAccountType)
        }
    }

    const validateGroupLogin = (loginAccountType) => {
    
        let invalidFieldFound = false;
        setCustError("");
        setCorpError("")

        const formFields = [
            {name: 'groupCorporate', displayName : 'Customer group code' },
            {name: 'groupPinCorporate', displayName : 'Customer group pin no' },
        ]
        
        formFields.map((field, i) => {

            if (!invalidFieldFound && (
                loginDetails[field.name]===undefined || 
                loginDetails[field.name].length===0 || 
                (typeof loginDetails[field.name]==='string' && loginDetails[field.name].trim()==="") ||
                loginDetails[field.name]==="selected"
            )){
                setValidLoginDetails( prevState => ({
                    ...prevState,
                    [field.name]:true
                }));
                
                setCustError("Please enter your "+field.displayName+".");
                
                invalidFieldFound = true;
            }
            else{
                setValidLoginDetails( prevState => ({
                    ...prevState,
                    [field.name]:false
                }));
            } 
        })

        if (invalidFieldFound)
            return false
        else{
            submit(loginAccountType)
        }
    }

    function submit(loginAccountType) {

        setLoading(true)

        if (loginAccountType === CONSTANTS.LOGIN_ACCOUNT_TYPE.CORPORATE || loginAccountType === CONSTANTS.LOGIN_ACCOUNT_TYPE.TOUR){
            setLoginDetails(prevState => ({
                ...prevState,
                [`accountType`]: loginAccountType === CONSTANTS.LOGIN_ACCOUNT_TYPE.CORPORATE ? "C" : "T"
            }))
        }
        else{
            setLoginDetails(prevState => ({
                ...prevState,
                [`accountType`]: "C"
            }))
        }

        ApiConsumer.post(API_ROUTES.CUSTOMER.ACCOUNTS, loginDetails)
            .then(res => {
                
                if (!res.data?.errors?.error[0].errorMessage.toLowerCase().includes("success")) {
                    if (loginAccountType === CONSTANTS.LOGIN_ACCOUNT_TYPE.CORPORATE || loginAccountType === CONSTANTS.LOGIN_ACCOUNT_TYPE.TOUR){
                        setCorpError(res.data?.errors?.error[0].errorMessage)
                        setLoading(false)
                    }
                    else{
                        setCustError(res.data?.errors?.error[0].errorMessage)
                        setLoading(false)
                    }
                    return
                } else {
                    if (loginAccountType === CONSTANTS.LOGIN_ACCOUNT_TYPE.CORPORATE || loginAccountType === CONSTANTS.LOGIN_ACCOUNT_TYPE.TOUR) {
                        setDetails(prevState => ({
                            ...prevState,
                            [`accountName`]: res.data.accounts.accounts[0].accountName
                        }))
                        setDetails(prevState => ({
                            ...prevState,
                            [`accountNo`]: res.data.accounts.accounts[0].accountNo
                        }))
                        setDetails(prevState => ({
                            ...prevState,
                            [`accountType`]: loginDetails.accountType
                        }))
                        let userData = res.data.accounts.accounts[0];
                        userData.accountType = loginDetails.accountType
                        setUser(userData)
                        sessionStorage.clear();
                        sessionStorage.setItem('sessionUser', JSON.stringify(userData));
                        history.push('/get-quote')
                    }
                }
            })
            .catch(err => {
                console.error(err)
                setLoading(false)
                if (loginAccountType === CONSTANTS.LOGIN_ACCOUNT_TYPE.CORPORATE || loginAccountType === CONSTANTS.LOGIN_ACCOUNT_TYPE.TOUR)
                    setCorpError("Invalid login details.")
                else
                    setCustError("Invalid login details.")
                Sentry.captureException(err)
            }).finally(() => {
                setFormSubmitted(true)
                setLoading(false)
            })

    }

    useEffect(() => {
        setCorpError('')
        setCustError('')
        setValidLoginDetails({})
        setFormSubmitted(false)
    }, [loginDetails])

    useEffect(() => {
        setLoginDetails(prevState => ({
            ...prevState,
            [`accountType`]: (loginAccountType===CONSTANTS.LOGIN_ACCOUNT_TYPE.TOUR)? "T" : "C"
        }))
    }, [loginAccountType])

    return <>
        <div className="mt-0">
            <h3 className="text-xl leading-6 font-medium text-gray-900" id="modal-title">
                Corporate / Tour Operator
            </h3>
            <div className="mt-2">
                <p className="text-sm text-gray-500">
                    Please supply account number and pin number to sign into your corporate or tour operator account.
                </p>
            </div>
        </div>

        <form onSubmit={event => {
            event.preventDefault()
            return validateCorpTourLogin(loginDetails.accountType==='C'? CONSTANTS.LOGIN_ACCOUNT_TYPE.CORPORATE : CONSTANTS.LOGIN_ACCOUNT_TYPE.TOUR)
        }}>


        <div className="my-4">
            <label
                className={`radio-checkbox-label text-sm ${loginDetails.accountType==='C' ? `bg-gray-200` : `bg-transparent`} `}>
                <input type="radio" name="TourCorporate" value="C"
                        className="h-4 w-4 mt-0.5 cursor-pointer"
                        aria-labelledby="corporate-customer"
                        checked={loginDetails.accountType==='C'? true:false }
                        onClick={event => {
                            setLoginDetails(prevState => ({
                                ...prevState,
                                [`accountType`]: event.target.value
                            }))
                        } } 
                        />
                <span id="corporate-customer" className="ml-1">Corporate Customer</span>
            </label>

            <label
                className={`radio-checkbox-label text-sm ${loginDetails.accountType==='T' ? `bg-gray-200` : `bg-transparent`} `}>
                <input type="radio" name="TourCorporate" value="T"
                        className="h-4 w-4 mt-0.5 cursor-pointer"
                        aria-labelledby="tour-operator"
                        checked={loginDetails.accountType==='T'?true:false }
                        onClick={event => {
                            setLoginDetails(prevState => ({
                                ...prevState,
                                [`accountType`]: event.target.value
                            }))
                        } }
                />
                <span id="tour-operator" className="ml-1">Tour Operator</span>
            </label>
            <div className="clear-both"></div>
        </div>


        <div className="mt-2">
            <label htmlFor="AccountNoCorporate"
                    className="block text-sm font-medium text-gray-700">Account number: <span
                className="text-red-600">*</span></label>
            <input id="AccountNoCorporate"
                    name="AccountNoCorporate"
                    type="text"
                    className={`style-input ${(validLoginDetails.accountNumber) ? `border-red-700` : `border-gray-300` }`}
                    
                    onChange={event => {
                        setLoginDetails(prevState => ({
                            ...prevState,
                            [`accountNumber`]: event.target.value
                        }))
                    }}

                    value={loginDetails.accountNumber} 
            />
        </div>
        <div className="mt-2">
            <label htmlFor="PinNumberCorporate"
                    className="block text-sm font-medium text-gray-700">Pin number: <span
                className="text-red-600">*</span></label>
            <input id="PinNumberCorporate"
                    name="PinNumberCorporate"
                    type="password"
                    maxlength="6"
                    className={`style-input ${(validLoginDetails.pinNumber) ? `border-red-700` : `border-gray-300` }`}
                    
                    onChange={event => {
                        setLoginDetails(prevState => ({
                            ...prevState,
                            [`pinNumber`]: event.target.value
                        }))
                    }}

                    value={loginDetails.pinNumber} 
            />
        </div>
        <div className="my-2">
            <div className="col-span-12 text-red-500 text-right">{corpError}</div>
            <div className="clear-both"/>
            <button 
                type="submit" 
                disabled={loading}
                className={`float-right inline-flex justify-center btn-light ml-2 ${loading ? `bg-gray-300 hover:bg-gray-300` : `bg-blue-400 hover:bg-blue-400`}`}
                >
                Sign In
            </button>
            {loading && <div className="mt-2 float-right"><Loader/></div>}
            
        </div>
        <div className="clear-both"/>

        </form>

        {loginDetails.accountType!=='T' && <>

            <hr className="border-b border-gray-300 my-4"/>
            <div className="clear-both"/>

            <form onSubmit={event => {
                event.preventDefault()
                return validateGroupLogin(CONSTANTS.LOGIN_ACCOUNT_TYPE.CUSTOMER)
            }}>
            <div className="mt-0">
                <h3 className="text-xl leading-6 font-medium text-gray-900" id="modal-title">
                    Customer Group Login
                </h3>
            </div>
            <div className="mt-2">
                <label htmlFor="GroupCorporate"
                    className="block text-sm font-medium text-gray-700">Customer group code: <span
                    className="text-red-600">*</span></label>
                <input id="GroupCorporate"
                    name="GroupCorporate"
                    type="text"
                    className={`style-input ${(validLoginDetails.groupCorporate) ? `border-red-700` : `border-gray-300` }`}
                    onChange={event => {
                        setLoginDetails(prevState => ({
                            ...prevState,
                            [`groupCorporate`]: event.target.value
                        }))
                    }}
                    value={loginDetails.groupCorporate} 
                />
            </div>
            <div className="mt-2">
                <label htmlFor="GroupPinCorporate"
                    className="block text-sm font-medium text-gray-700">Customer group pin no: <span
                    className="text-red-600">*</span></label>
                <input id="GroupPinCorporate"
                    name="GroupPinCorporate"
                    type="password"
                    className={`style-input ${(validLoginDetails.groupPinCorporate) ? `border-red-700` : `border-gray-300` }`}
                
                    onChange={event => {
                        setLoginDetails(prevState => ({
                            ...prevState,
                            [`groupPinCorporate`]: event.target.value
                        }))
                    }}
                    value={loginDetails.groupPinCorporate} 
                />
            </div>
            <div className="my-2">
            <div className="col-span-12 text-red-500 text-right">{custError}</div>
                <div className="clear-both"/>
                <button 
                    type="submit" 
                    disabled={loading}
                    className={`float-right inline-flex justify-center btn-light ml-2 ${loading ? `bg-gray-300 hover:bg-gray-300` : `bg-blue-400 hover:bg-blue-400`}`}
                        >
                    Sign In
                </button>
                {loading && <div className="mt-2 float-right"><Loader/></div>}
            </div>

            </form>

            </>
        }
    </>
}
