import {Fragment,useState,useEffect} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {Dialog, Transition} from '@headlessui/react'
import {CONSTANTS} from "contants/constants";
import CorpLoginModal from "components/modals/corp-login";
import UserLoginModal from "components/modals/user-login";
import RegisterModal from "components/modals/register";
import ForgotPassModal from "components/modals/forgot-pass";
import SendQuoteModal from "components/modals/send-quote";
import PagePostModal from "components/modals/page-post";

const Modals = ({visibleModal,setVisibleModal,loginAccountType,setLoginAccountType,pagePostModalSlug,setPagePostModalSlug}) => {

    return <>

    <Transition.Root show={(visibleModal!=='')?true:false} as={Fragment}>
        <div className={`fixed z-50 inset-0 overflow-y-auto`} aria-labelledby="modal-title" role="dialog"
             aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"/>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div
                    className={`inline-block align-bottom bg-white rounded-md px-4 pt-5 pb-4 text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle ${(visibleModal===CONSTANTS.MODAL_TYPE.PAGEPOST)?`sm:max-w-xl`:`sm:max-w-sm`} sm:w-full sm:p-6`}>
                        <FontAwesomeIcon icon={faTimesCircle} className=" float-right text-xl cursor-pointer " onClick={()=>setVisibleModal('')}/>
                        
                        {(visibleModal===CONSTANTS.MODAL_TYPE.USER) &&
                            <UserLoginModal setVisibleModal={setVisibleModal} setLoginAccountType={setLoginAccountType} />
                        }

                        {(visibleModal===CONSTANTS.MODAL_TYPE.CORPTOUR) &&
                            <CorpLoginModal loginAccountType={loginAccountType} />
                        }

                        {(visibleModal===CONSTANTS.MODAL_TYPE.FORGOT) &&
                            <ForgotPassModal setVisibleModal={setVisibleModal} />
                        }

                        {(visibleModal===CONSTANTS.MODAL_TYPE.REGISTER) &&
                            <RegisterModal setVisibleModal={setVisibleModal} setPagePostModalSlug={setPagePostModalSlug} />
                        }

                        {(visibleModal===CONSTANTS.MODAL_TYPE.SENDQUOTE) &&
                            <SendQuoteModal setVisibleModal={setVisibleModal} setPagePostModalSlug={setPagePostModalSlug}  />
                        }

                        {(visibleModal===CONSTANTS.MODAL_TYPE.PAGEPOST) &&
                            <PagePostModal setVisibleModal={setVisibleModal} pagePostModalSlug={pagePostModalSlug} setPagePostModalSlug={setPagePostModalSlug} />
                        }
                </div>
            </div>
        </div>
    </Transition.Root>
     
    </>
}
export default Modals;
