import { useState, useEffect } from 'react'
import { ApiConsumer } from 'api/ApiConsumer'
import vehiclePlaceholder from 'assets/images/vehicle-placeholder.jpg'

const VehicleImage = ({ vehicleGrp }) => {
  const [fleetData, setFleetData] = useState({})
  const page_url =
    'https://cms.bluucarrental.com/wp-json/wp/v2/fleet?per_page=100'

  useEffect(() => {
    ApiConsumer.get(page_url)
      .then((res) => {
        setFleetData(res.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [page_url])

  const vehicle =
    Object.keys(fleetData).length !== 0
      ? fleetData.filter((x) => x.title.rendered === 'Group ' + vehicleGrp)
      : []

  return (
    <>
      <img
        alt="Vehicle"
        src={!vehicle[0]?.fimg_url ? vehiclePlaceholder : vehicle[0]?.fimg_url}
      />
    </>
  )
}
export default VehicleImage
