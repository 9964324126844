import {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretLeft} from '@fortawesome/free-solid-svg-icons';
import {CONSTANTS} from "contants/constants";
import Loader from "components/loader";
import {ApiConsumer} from "api/ApiConsumer";
import * as Sentry from "@sentry/react";
import {API_ROUTES} from "routes/api";
import {UserContext} from "contexts/user";
import {useHistory} from "react-router-dom";
import {DetailsContext} from "contexts/details";
import {useUtils} from "hooks/utils";

export default function ForgotPassModal({setVisibleModal}) {

    const [loading, setLoading] = useState(false)
    const [forgotDetails, setForgotDetails] = useState({})
    const [forgotError, setForgotError] = useState('')
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [validForgotDetails, setValidForgotDetails] = useState({});
    let {validEmail} = useUtils()
    let history = useHistory()

    useEffect(() => {
        setForgotDetails(prevState => ({
            ...prevState,
            [`SITE_HOST`]: 'https://www.bluucarrental.com'
        }))
    }, [])

    const validateForgotPass = () => {

        let invalidFieldFound = false;
        setForgotError("");

        const formFields = [
            {name: 'forgotPasswordEmail', displayName: 'Email address'},
        ]

        formFields.map((field, i) => {

            if (!invalidFieldFound && (
                forgotDetails[field.name] === undefined ||
                forgotDetails[field.name].length === 0 ||
                (typeof forgotDetails[field.name] === 'string' && forgotDetails[field.name].trim() === "") ||
                forgotDetails[field.name] === "selected"
            )) {
                setValidForgotDetails(prevState => ({
                    ...prevState,
                    [field.name]: true
                }));

                setForgotError("Please enter your " + field.displayName + ".");

                invalidFieldFound = true;
            } else if (!invalidFieldFound && (
                (field.name === 'forgotPasswordEmail' && forgotDetails[field.name].length && !validEmail(forgotDetails[field.name]))
            )) {
                setValidForgotDetails(prevState => ({
                    ...prevState,
                    [field.name]: true
                }));
                setForgotError("Please enter a valid  " + field.displayName + ".");
                invalidFieldFound = true;
            } else {
                setValidForgotDetails(prevState => ({
                    ...prevState,
                    [field.name]: false
                }));
            }
        })

        if (invalidFieldFound)
            return false
        else {
            submit()
        }
    }

    function submit() {
        setLoading(true)

        //Send confirmation email
        ApiConsumer.post("https://weblogs.bluevinegroup.co.za/user/send-external-forgot-password/ajax/", JSON.stringify({forgotDetails}) )
        .then(res => {
        
            if (res.data.error!==undefined)
                setForgotError(res.data.error);
            else
                setFormSubmitted(true)
            setLoading(false)
            window.scrollTo(0, 0)
        })
        .catch(error => Sentry.captureException(error))
        .finally(() => {
            setLoading(false)
        })

    }

    useEffect(() => {
        setForgotError('')
        setValidForgotDetails({})
        setFormSubmitted(false)
    }, [forgotDetails])

    return <>

        <div className="my-4">
            <h3 className="text-xl leading-6 font-medium text-gray-900" id="modal-title">
                Forgot password
            </h3>
            {!formSubmitted &&
            <div className="mt-2">
                <p className="text-sm text-gray-500">
                    Enter your registered email address below to retrieve your password.
                </p>
            </div>
            }
        </div>

        {formSubmitted ?
            <div className="col-span-12">

                <div className="bg-green-100 border-t-4 border-green-300 rounded-b-lg p-6 shadow-sm mb-5" role="alert">
                    <div className="flex">
                        <div>

                            <div className="font-semibold mb-2">We've emailed your password reset instructions
                                to {forgotDetails.forgotPasswordEmail}.
                            </div>
                            If you don't receive the email within a minute or two try resending the instructions or
                            check your email's spam and junk folders.
                            <div class="clear-both"/>
                            <div
                                className="float-left text-sm mt-3 text-blue-400 hover:underline cursor-pointer focus:outline-none"
                                onClick={() => setVisibleModal(CONSTANTS.MODAL_TYPE.USER)}
                            ><FontAwesomeIcon icon={faCaretLeft} className="text-lg float-left mr-1"/>Return to login
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            :

            <form onSubmit={event => {
                event.preventDefault()
                return validateForgotPass()
            }}>

                <div className="mt-2">
                    <label htmlFor="forgotPasswordEmail"
                           className="block text-sm font-medium text-gray-700">Email address: <span
                        className="text-red-600">*</span></label>
                    <input id="forgotPasswordEmail"
                           name="forgotPasswordEmail"
                           type="text"
                           className={`style-input ${(validForgotDetails.forgotPasswordEmail) ? `border-red-700` : `border-gray-300`}`}

                           onChange={event => {
                               setForgotDetails(prevState => ({
                                   ...prevState,
                                   [`forgotPasswordEmail`]: event.target.value
                               }))
                           }}
                           value={forgotDetails.forgotPasswordEmail}
                    />
                </div>


                <div className="my-2">
                    <div className="col-span-12 text-red-500 text-right">{forgotError}</div>
                    <div className="clear-both"/>
                    <button
                        type="submit"
                        disabled={loading}
                        className={`float-right inline-flex justify-center btn-light ml-2 ${loading ? `bg-gray-300 hover:bg-gray-300` : `bg-blue-400 hover:bg-blue-400`}`}
                    >
                        Submit
                    </button>
                    {loading && <div className="mt-2 float-right"><Loader/></div>}
                    <div className="clear-both"/>
                </div>


                <div className="clear-both"/>
                <div
                    className="float-left text-sm text-blue-400 hover:underline cursor-pointer focus:outline-none"
                    onClick={() => setVisibleModal(CONSTANTS.MODAL_TYPE.USER)}
                ><FontAwesomeIcon icon={faCaretLeft} className="text-lg float-left mr-1"/>Return to login
                </div>

            </form>
        }

    </>
}
