import { useEffect, useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { CONSTANTS } from 'contants/constants'
import Loader from 'components/loader'
import { ApiConsumer } from 'api/ApiConsumer'
import * as Sentry from '@sentry/react'
import { API_ROUTES } from 'routes/api'
import { UserContext } from 'contexts/user'
import { useHistory } from 'react-router-dom'
import { DetailsContext } from 'contexts/details'
import { useUtils } from 'hooks/utils'

export default function UserLoginModal({
  setVisibleModal,
  setLoginAccountType,
}) {
  const [loading, setLoading] = useState(false)
  const [loginDetails, setLoginDetails] = useState({})
  const [loginError, setLoginError] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [showActivationLink, setShowActivationLink] = useState(false)
  const [validLoginDetails, setValidLoginDetails] = useState({})
  let { user, setUser } = useContext(UserContext)
  let { validEmail } = useUtils()
  let history = useHistory()

  const validateUserLogin = () => {
    let invalidFieldFound = false
    setLoginError('')
    setShowActivationLink(false)

    const formFields = [
      { name: 'loginEmail', displayName: 'Email address' },
      { name: 'loginPassword', displayName: 'Password' },
    ]

    formFields.map((field, i) => {
      if (
        !invalidFieldFound &&
        (loginDetails[field.name] === undefined ||
          loginDetails[field.name].length === 0 ||
          (typeof loginDetails[field.name] === 'string' &&
            loginDetails[field.name].trim() === '') ||
          loginDetails[field.name] === 'selected')
      ) {
        setValidLoginDetails((prevState) => ({
          ...prevState,
          [field.name]: true,
        }))

        setLoginError('Please enter your ' + field.displayName + '.')

        invalidFieldFound = true
      } else if (
        !invalidFieldFound &&
        field.name === 'loginEmail' &&
        loginDetails[field.name].length &&
        !validEmail(loginDetails[field.name])
      ) {
        setValidLoginDetails((prevState) => ({
          ...prevState,
          [field.name]: true,
        }))
        setLoginError('Please enter a valid  ' + field.displayName + '.')
        invalidFieldFound = true
      } else {
        setValidLoginDetails((prevState) => ({
          ...prevState,
          [field.name]: false,
        }))
      }
    })

    if (invalidFieldFound) return false
    else {
      submit()
    }
  }

  function submit() {
    setLoading(true)
    setUser({})
    sessionStorage.clear()

    ApiConsumer.post(
      'https://weblogs.bluevinegroup.co.za/user/send-external-login/ajax/',
      JSON.stringify({ loginDetails }),
    )
      .then((res) => {
        if (res.data.error !== undefined) {
          if (res.data.error.includes('not been activated'))
            setShowActivationLink(true)

          setLoginError(res.data.error)
        } else {
          const userName =
            res.data.sessionUserFirstname.charAt(0) +
            '. ' +
            res.data.sessionUserLastname
          let userData = {
            //Uppercase first character of each word
            accountName: userName
              .split(' ')
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(' '),
            accountNo: null,
            accountType: 'I',
            accountFirstname: res.data.sessionUserFirstname,
            accountLastname: res.data.sessionUserLastname,
            accountUser: res.data.sessionUserId,
            accountEmail: res.data.sessionUserEmail,
          }
          setUser(userData)
          sessionStorage.setItem('sessionUser', JSON.stringify(userData))
          history.push('/profile')
          window.scrollTo(0, 0)
        }
        setLoading(false)
      })
      .catch((error) => Sentry.captureException(error))
      .finally(() => {
        setLoading(false)
      })
  }

  function resendActivationEmail() {
    setLoading(true)

    ApiConsumer.post(
      'https://weblogs.bluevinegroup.co.za/user/resend-activation-email/ajax/',
      JSON.stringify({ loginDetails }),
    )
      .then((res) => {
        if (res.data.error !== undefined) setLoginError(res.data.error)
        else
          setLoginError(
            'Your activation email has been sent to ' + res.data.valid,
          )

        setShowActivationLink(false)
        setLoading(false)
      })
      .catch((error) => Sentry.captureException(error))
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoginError('')
    setValidLoginDetails({})
    setFormSubmitted(false)
    setShowActivationLink(false)
  }, [loginDetails])

  return (
    <>
      <div className="mt-0">
        <div
          className="inline-block text-white btn-navy bg-blue-900 hover:bg-blue-900 mr-1 ml-0 float-left text-sm cursor-pointer focus:outline-none "
          onClick={() => {
            setVisibleModal(CONSTANTS.MODAL_TYPE.CORPTOUR)
            setLoginAccountType(CONSTANTS.LOGIN_ACCOUNT_TYPE.CORPORATE)
          }}
        >
          Corporate Customer
        </div>

        <div
          className="inline-block text-white btn-navy bg-blue-900 hover:bg-blue-900 mr-1 ml-0 float-left text-sm cursor-pointer focus:outline-none "
          onClick={() => {
            setVisibleModal(CONSTANTS.MODAL_TYPE.CORPTOUR)
            setLoginAccountType(CONSTANTS.LOGIN_ACCOUNT_TYPE.TOUR)
          }}
        >
          Tour Operator
        </div>

        <div className="clear-both"></div>
      </div>

      <hr className="border-b border-gray-300 my-4" />

      <div className="my-4">
        <h3
          className="text-xl leading-6 font-medium text-gray-900"
          id="modal-title"
        >
          Online Profile Sign In
        </h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Sign into your online profile to manage your quotes and
            reservations.
          </p>
        </div>
      </div>

      <form
        onSubmit={(event) => {
          event.preventDefault()
          return validateUserLogin()
        }}
      >
        <div className="mt-2">
          <label
            htmlFor="LoginEmail"
            className="block text-sm font-medium text-gray-700"
          >
            Email address: <span className="text-red-600">*</span>
          </label>
          <input
            id="LoginEmail"
            name="LoginEmail"
            type="text"
            className={`style-input ${
              validLoginDetails.loginEmail
                ? `border-red-700`
                : `border-gray-300`
            }`}
            onChange={(event) => {
              setLoginDetails((prevState) => ({
                ...prevState,
                [`loginEmail`]: event.target.value,
              }))
            }}
            value={loginDetails.loginEmail}
          />
        </div>
        <div className="mt-2">
          <label
            htmlFor="LoginPassword"
            className="block text-sm font-medium text-gray-700"
          >
            Password: <span className="text-red-600">*</span>
          </label>
          <input
            id="LoginPassword"
            name="LoginPassword"
            type="password"
            className={`style-input ${
              validLoginDetails.loginPassword
                ? `border-red-700`
                : `border-gray-300`
            }`}
            onChange={(event) => {
              setLoginDetails((prevState) => ({
                ...prevState,
                [`loginPassword`]: event.target.value,
              }))
            }}
            value={loginDetails.loginPassword}
          />
        </div>

        <div className="my-2 text-gray-500 text-sm">
          All passwords are case-sensitive. Please check CAPS LOCK is turned
          off.
        </div>
        <div className="my-2">
          <div className="col-span-12 text-red-500 text-right">
            {loginError}{' '}
            {showActivationLink && (
              <div
                onClick={() => resendActivationEmail()}
                className="text-blue-400 hover:underline cursor-pointer focus:outline-none"
              >
                Resend Activation Email
              </div>
            )}
          </div>
          <div className="clear-both" />
          <button
            type="submit"
            disabled={loading}
            className={`float-right inline-flex justify-center btn-light ml-2 ${
              loading
                ? `bg-gray-300 hover:bg-gray-300`
                : `bg-blue-400 hover:bg-blue-400`
            }`}
          >
            Sign In
          </button>
          {loading && (
            <div className="mt-2 float-right">
              <Loader />
            </div>
          )}
          <div className="clear-both" />
        </div>
        <div className="clear-both" />

        <div
          className="float-left my-1 text-sm text-blue-400 hover:underline cursor-pointer focus:outline-none"
          onClick={() => setVisibleModal(CONSTANTS.MODAL_TYPE.REGISTER)}
        >
          <p className="float-left">
            I do not have an online profile. Register
          </p>{' '}
          <FontAwesomeIcon
            icon={faCaretRight}
            className="text-lg float-left ml-1"
          />
          <div className="clear-both" />
        </div>
        <div className="clear-both" />
        <div
          className="float-left text-sm text-blue-400 hover:underline cursor-pointer focus:outline-none"
          onClick={() => setVisibleModal(CONSTANTS.MODAL_TYPE.FORGOT)}
        >
          I have forgotten my password.
        </div>
      </form>
    </>
  )
}
