import { useContext } from "react";
import { DetailsContext } from "contexts/details";
import { UserContext } from "contexts/user";

const D2DVehicleWaiver = ({ vehicleItem }) => {
  let namibiaBranches = ["WH", "WB", "KM", "WT"];
  let { details } = useContext(DetailsContext);
  const numbFormatter = new Intl.NumberFormat();
  let { user } = useContext(UserContext);

  return (
    <>
      <h3 className="text-sm py-4 text-center">
        <span className="text-2xl font-semibold">
          {Object.keys(user).length === 0 || user.accountNo === null ? (
            <>
              {namibiaBranches.includes(details?.PICKUP_BRANCH) ? "N$" : "R"}
              {numbFormatter.format(vehicleItem.superCover)}
            </>
          ) : (
            `Select`
          )}
        </span>
      </h3>
    </>
  );
};
export default D2DVehicleWaiver;
