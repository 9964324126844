import {useState, useEffect, useContext} from "react";
import {API_ROUTES} from "routes/api";
import {ApiConsumer} from "api/ApiConsumer";
import Loader from "components/loader";
import VehicleImage from 'components/availability/vehicle-image';
import VehicleName from 'components/availability/vehicle-name';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faCalendarAlt, faCar, faClock, faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import {CONSTANTS} from "contants/constants";
import {WEBSITE_ROUTES} from "routes/website";
import {useHistory} from "react-router-dom";
import {DetailsContext} from "contexts/details";
import * as Sentry from "@sentry/react";
import {BranchContext} from "contexts/branch";
import {useUtils} from "hooks/utils";

const ModifyBooking = ({quoteNo, setPage, reservation}) => {
    let history = useHistory();
    let {setDetails} = useContext(DetailsContext)
    let {branches} = useContext(BranchContext)
    let [contact, setContacts] = useState({})
    const [showLoader, setShowLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    let {getBranchById, formatDate, formatTime} = useUtils()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    function modify() {
        setShowLoader(true)
        setErrorMessage("")
        if (contact?.name === "" || contact?.name === undefined) {
            setErrorMessage('Please provide First name')
            setShowLoader(false)
            return
        }
        if (contact?.surname === "" || contact?.surname === undefined) {
            setErrorMessage('Please provide Last name')
            setShowLoader(false)
            return
        }
        if (contact?.contact === "" || contact?.contact === undefined) {
            setErrorMessage('Please provide Contact number')
            setShowLoader(false)
            return
        }
        ApiConsumer.post(API_ROUTES.BOOKING.AVAILABILITY, reservation.quote)
            .then(res => {
                
                if (Array.isArray(res.data?.errors?.error) && res.data?.errors?.error.length > 0) {
                    if (!res.data?.errors?.error[0].errorMessage?.toLowerCase().includes("success")) {
                        setErrorMessage(res.data?.errors?.error[0].errorMessage);
                        return
                    }
                    if (!res.data?.errors?.error[0].errorMessage?.toLowerCase().includes("success")) {
                        setErrorMessage(res.data?.errors?.error[0].errorMessage);
                        return
                    }

                    //Overwrite quote with reservation on location times
                    reservation.quote.vehicleGroup = reservation.reservation.vehGroup

                    reservation.quote.pickupBranch = reservation.reservation.pickupBranch
                    reservation.quote.pickUpDateTime = formatDate(reservation.reservation.pickupDate) + " " + formatTime(reservation.reservation.pickupTime)

                    reservation.quote.dropOffBranch = reservation.reservation.duebackBranch
                    reservation.quote.dropOffDateTime = formatDate(reservation.reservation.duebackDate) + " " + formatTime(reservation.reservation.duebackTime)

                    setDetails({...reservation.quote, ...contact, ...{modify: true}})
                    history.push({
                        pathname: WEBSITE_ROUTES.AVAILABILITY.link,
                        state: {rates: res.data.rates.rate, payload: {...reservation.booking,...reservation.reservation,...reservation.quote, ...contact}}
                    });
                    window.scrollTo(0, 0);
                }
            })
            .catch(err => {
                Sentry.captureException(err)
            })
            .finally(() => setShowLoader(false))
    }

    return <>

        <div className="grid gap-4 p-5 lg:p-10 lg:grid-cols-12">

            <div className="col-span-12 bg-gray-100">
                <h2 className="text-2xl mb-2">Modify Your Booking ({quoteNo})</h2>
                <p>Your current reservation details are below.<br/>Click <strong>"Proceed to Modify"</strong> to update
                    your listing
                    and booking.</p>
            </div>


            <div className="col-span-12 lg:col-span-6 bg-white rounded-lg border border-gray-100">

                <div className="grid lg:grid-cols-12 ">

                    {/* You can grab VehiclePreview component if you can for this section */}

                    <div
                        className="col-span-12 leading-none p-3 rounded-tl-lg rounded-tr-lg border-l border-r border-t border-gray-100 z-10">
                        <h2 className="text-gray-900 text-xl font-semibold leading-tight w-full mt-2">
                            {reservation.reservation.vehGroup} - <VehicleName
                            vehicleGrp={reservation.reservation.vehGroup}/>
                        </h2>
                        <span className="text-xs text-blue-400">or similar</span>
                    </div>
                    <div className="col-span-12 relative border-l border-r border-gray-100">
                        <VehicleImage vehicleGrp={reservation.reservation.vehGroup}/>
                        <div
                            className="absolute rounded-full w-14 h-14 right-4 bottom-4 border border-blue-400 text-blue-400 bg-white text-center px-2 pt-2.5 pb-2 leading-none">
                            <div className="text-xs">Group</div>
                            <div className="text-xl leading-none">{reservation.reservation.vehGroup}</div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="col-span-12 lg:col-span-6 py-3 px-4 bg-white rounded-lg border border-gray-100">


                <div className=" leading-6 mb-3">
                    <p className="font-bold text-blue-900 uppercase">Pick-up Location</p>
                    <span className="mr-2"><FontAwesomeIcon icon={faCar}
                                                            className="text-gray-700 text-lg"/> {getBranchById(branches, reservation?.reservation?.pickupBranch)?.branchName}</span><br/>
                    <span className="mr-2"><FontAwesomeIcon icon={faCalendarAlt}
                                                            className="text-gray-700 text-lg"/> {formatDate(reservation.reservation.pickupDate)}</span>
                    <span className="mr-2"><FontAwesomeIcon icon={faClock}
                                                            className="text-gray-700 text-lg"/> {formatTime(reservation.reservation.pickupTime)}</span>
                </div>


                <div className=" leading-6 mb-5">
                    <p className="font-bold text-blue-900 uppercase">Drop-off Location</p>
                    <span className="mr-2"><FontAwesomeIcon icon={faCar}
                                                            className="text-gray-700 text-lg"/> {getBranchById(branches, reservation?.reservation?.duebackBranch)?.branchName}</span><br/>
                    <span className="mr-2"><FontAwesomeIcon icon={faCalendarAlt}
                                                            className="text-gray-700 text-lg"/> {formatDate(reservation.reservation.duebackDate)}</span>
                    <span className="mr-2"><FontAwesomeIcon icon={faClock}
                                                            className="text-gray-700 text-lg"/> {formatTime(reservation.reservation.duebackTime)}</span>
                </div>


                <p className="font-bold text-blue-900 uppercase">Selected add-ons:</p>
                <ul>
                    {
                        reservation.quote.products.map((product, index) => {
                            return <li key={index}
                                       className="w-3/6 float-left py-1 pr-1">{product.productDescription} </li>
                        })
                    }
                    {
                        reservation.quote.products.length === 0 &&
                        <li className="w-3/6 float-left py-1 pr-1">No products selected.</li>
                    }
                </ul>
            </div>

            <div className="col-span-12 "><p className="text-left font-bold text-blue-900 uppercase">Modified by:</p>
            </div>


            <div className="col-span-12 lg:col-span-4">
                <label htmlFor="CancelledByName"
                       className="block text-sm font-medium text-gray-700">First name: <span
                    className="text-red-600">*</span></label>
                <input id="CancelledByName"
                       name="CancelledByName"
                       type="text"
                       onChange={event =>
                           setContacts(prevState => ({
                               ...prevState,
                               [`name`]: event.target.value
                           }))}
                       className="style-input"/>
            </div>

            <div className="col-span-12 lg:col-span-4">
                <label htmlFor="CancelledBySurname"
                       className="block text-sm font-medium text-gray-700">Last name: <span
                    className="text-red-600">*</span></label>
                <input id="CancelledBySurname"
                       name="CancelledBySurname"
                       type="text"
                       onChange={event =>
                           setContacts(prevState => ({
                               ...prevState,
                               [`surname`]: event.target.value
                           }))}
                       className="style-input"/>
            </div>

            <div className="col-span-12 lg:col-span-4">
                <label htmlFor="CancelledByPhoneNo"
                       className="block text-sm font-medium text-gray-700">Contact number: <span
                    className="text-red-600">*</span></label>
                <input id="CancelledByPhoneNo"
                       name="CancelledByPhoneNo"
                       type="text"
                       onChange={event =>
                           setContacts(prevState => ({
                               ...prevState,
                               [`contact`]: event.target.value
                           }))}
                       className="style-input"/>
            </div>
            {errorMessage && <div className="col-span-12 text-red-500 text-right">{errorMessage}</div>}


            <div className="col-span-12">
                <button
                    type="button" onClick={() => setPage(CONSTANTS.BOOKING.STATUS.CONFIRMED)}
                    className="inline-block float-left px-4 py-2 border border-transparent rounded-full shadow-sm text-lg text-white focus:outline-none bg-blue-400 hover:bg-blue-400"
                ><FontAwesomeIcon icon={faAngleLeft} className="text-white"/> Back
                </button>
                <button
                    type="button"
                    disabled={showLoader}
                    onClick={() => modify()}
                    className={`inline-block float-right ml-2 px-4 py-2 border border-transparent rounded-full shadow-sm text-lg text-white focus:outline-none ${showLoader ? `bg-gray-300 hover:bg-gray-300` : `bg-blue-900 hover:bg-blue-900`}`}
                ><FontAwesomeIcon icon={faCheck} className="text-white"/> Proceed to Modify
                </button>
                {showLoader && <div className="mt-2 float-right"><Loader/></div>}
            </div>
        </div>
    </>
}
export default ModifyBooking;
