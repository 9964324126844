import { useHistory, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import * as ReactGA from 'react-ga'

export default function PageViews() {
  let { pathname } = useLocation()

  useEffect(() => {
    ReactGA.initialize('UA-50824989-1')
    ReactGA.pageview(pathname)

    const mainDataLayer = {
      url: pathname,
    }

    window.onload = () => window.dataLayer?.push({ ...mainDataLayer })

    gtmVirtualPageView(mainDataLayer)
  }, [pathname])

  return <></>
}

export const gtmVirtualPageView = (rest) => {
  window.dataLayer?.push({
    event: 'Page View',
    ...rest,
  })
}
