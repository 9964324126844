import { useState, useEffect, useContext } from 'react'
import * as Sentry from '@sentry/react'
import Loader from 'components/loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar, faRoad } from '@fortawesome/free-solid-svg-icons'
import ConfirmQuote from 'components/manage-booking/confirm-quote'
import CancelBooking from 'components/manage-booking/cancel-booking'
import ExtendBooking from 'components/manage-booking/extend-booking'
import ModifyBooking from 'components/manage-booking/modify-booking'
import { API_ROUTES } from 'routes/api'
import { ApiConsumer } from 'api/ApiConsumer'
import { CONSTANTS } from 'contants/constants'
import { DetailsContext } from 'contexts/details'

export default function ManageBooking() {
  const [manageDetails, setManageDetails] = useState({})
  const [showLoader, setShowLoader] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [page, setPage] = useState('')
  const [status, setStatus] = useState('')
  const [reservation, setReservation] = useState({})
  let { details, setDetails } = useContext(DetailsContext)

  useEffect(() => {
    if (
      sessionStorage.getItem('sessionUserReservation') !== null &&
      sessionStorage.getItem('sessionUserReservation') !== undefined
    ) {
      let userReservationData = JSON.parse(
        sessionStorage.getItem('sessionUserReservation'),
      )
      setManageDetails((prevState) => ({
        ...prevState,
        [`quote`]: userReservationData.reservationNumber,
      }))
      setManageDetails((prevState) => ({
        ...prevState,
        [`email`]: userReservationData.reservationEmail,
      }))
    }
  }, [])

  function reset() {
    setShowLoader(true)
    setErrorMessage('')
    setPage('')
    setManageDetails({ quote: '', email: '' })
    setShowLoader(false)
  }

  function getModify() {
    setErrorMessage('')
    setShowLoader(true)
    setPage('')
    if (
      manageDetails.quote === '' ||
      manageDetails?.quote === null ||
      manageDetails?.quote === undefined
    ) {
      setErrorMessage('Please provide a Quote/Reservation number')
      setShowLoader(false)
      return
    }
    if (
      manageDetails.email === '' ||
      manageDetails?.email === null ||
      manageDetails?.email === undefined
    ) {
      setErrorMessage('Please provide a email address')
      setShowLoader(false)
      return
    }
    ApiConsumer.get(API_ROUTES.BOOKING.STATUS(manageDetails.quote))
      .then((res) => {
        if (res.data.status === '') {
          setShowLoader(false)
          setErrorMessage(
            'Booking/Quote does not exist, please try another reference number',
          )
        } else {
          const status = res.data.status
          if (
            Array.isArray(res.data?.errors?.error) &&
            res.data?.errors?.error.length > 0
          ) {
            if (
              !res.data?.errors?.error[0].errorMessage
                .toLowerCase()
                .includes('success')
            ) {
              setErrorMessage(res.data?.errors?.error[0].errorMessage)
              return
            }

            if (
              res.data.status.toUpperCase() ===
              CONSTANTS.BOOKING.STATUS.CANCELLED
            ) {
              setErrorMessage(
                `This quote/reservation (${manageDetails.quote}) has been ${res.data.status}.`,
              )
              setShowLoader(false)
            } else {
              ApiConsumer.get(
                API_ROUTES.BOOKING.MANAGE(
                  manageDetails.quote,
                  manageDetails.email,
                ),
              )
                .then((res) => {
                  let isD2D = CONSTANTS.D2D_VEHICLES.filter(
                    (d2dVehicle) => d2dVehicle.code === res.data.vehicleGroup,
                  )
                  if (isD2D)
                    setDetails((prevState) => ({
                      ...prevState,
                      [`isD2D`]: true,
                    }))

                  if (
                    (res.data.quote.accountType === 'C' ||
                      res.data.quote.accountType === 'T') &&
                    details.accountNo !== res.data.quote.accountNo &&
                    !isD2D
                  ) {
                    setShowLoader(false)
                    setErrorMessage(
                      'Please ensure your are signed into your Corporate / Tour Operator account (' +
                        res.data.quote.accountNo +
                        ').',
                    )
                    return
                  }
                  //Remove duplicates
                  res.data.quote.products = res.data.quote.products.filter(
                    (product, index, self) =>
                      index ===
                      self.findIndex(
                        (x) => x.productCode === product.productCode,
                      ),
                  )

                  setReservation(res.data)

                  let userReservationData = {
                    reservationNumber: manageDetails.quote,
                    reservationEmail: manageDetails.email,
                  }
                  sessionStorage.setItem(
                    'sessionUserReservation',
                    JSON.stringify(userReservationData),
                  )

                  window.scrollTo(0, 0)
                  setPage(status.toUpperCase())
                  setStatus(status)
                })
                .catch((err) => {
                  setShowLoader(false)
                  setErrorMessage(
                    'Booking/Quote does not exist, please try another reference number',
                  )
                  Sentry.captureException(err)
                })
                .finally(() => setShowLoader(false))
            }
          }
        }
      })
      .catch((err) => Sentry.captureException(err))
  }

  return (
    <>
      {page === '' ? (
        <div className="grid gap-4 p-5 lg:grid-cols-12 lg:p-10">
          <div className="col-span-12 bg-gray-100">
            <h2 className="text-xl mb-2 lg:text-2xl">
              Manage Existing Booking
            </h2>
            Please enter the quote or reservation number that you wish to
            manage. You will then be able to confirm quote, modify, extend or
            cancel booking.
          </div>

          <div className="col-span-12 lg:col-span-6">
            <label
              for="ResNumber"
              className="block text-sm font-medium text-gray-700"
            >
              Quote/Reservation number: <span className="text-red-600">*</span>
            </label>
            <input
              id="ResNumber"
              name="ResNumber"
              type="text"
              className="style-input"
              disabled={showLoader}
              onChange={(event) =>
                setManageDetails((prevState) => ({
                  ...prevState,
                  [`quote`]: event.target.value,
                }))
              }
              value={manageDetails?.quote}
            />
          </div>

          <div className="col-span-12 lg:col-span-6">
            <label
              for="EmailAddress"
              className="block text-sm font-medium text-gray-700"
            >
              Email address: <span className="text-red-600">*</span>
            </label>
            <input
              id="EmailAddress"
              name="EmailAddress"
              type="text"
              className="style-input"
              disabled={showLoader}
              onChange={(event) =>
                setManageDetails((prevState) => ({
                  ...prevState,
                  [`email`]: event.target.value,
                }))
              }
              value={manageDetails?.email}
            />
          </div>
          {errorMessage && (
            <div className="col-span-12 text-red-500 text-right">
              {errorMessage}
            </div>
          )}
          <div className="col-span-12">
            <button
              type="button"
              disabled={showLoader}
              className={`inline-block float-right px-4 py-2 ml-2 border border-transparent rounded-full shadow-sm text-lg text-white focus:outline-none ${
                showLoader
                  ? `bg-gray-300 hover:bg-gray-300`
                  : `bg-blue-400 hover:bg-blue-400`
              }`}
              onClick={getModify}
            >
              Submit
            </button>
            {showLoader && (
              <div className="mt-2 float-right">
                <Loader />
              </div>
            )}
          </div>
        </div>
      ) : null}

      {page === CONSTANTS.BOOKING.STATUS.CONFIRMED ? (
        <div className="grid gap-4 p-5 lg:p-10 lg:grid-cols-12">
          <div className="col-span-12 bg-gray-100">
            <h2 className="text-2xl mb-2">
              Manage Existing Booking ({manageDetails.quote})
            </h2>
            <p>
              Select any of the following options in order to manage and make
              changes to your booking. The status of reference number{' '}
              <strong>
                {manageDetails.quote} is "{status}"
              </strong>
              .
            </p>
          </div>
          <div className="col-span-12">
            <div
              onClick={() => setPage(CONSTANTS.BOOKING.MANAGE_BOOKING.MODIFY)}
              className="inline-block mb-2 mr-2 px-4 py-2 border border-transparent rounded-full shadow-sm text-lg text-white bg-blue-900 hover:bg-blue-900 focus:outline-none cursor-pointer"
            >
              <FontAwesomeIcon icon={faCar} className="text-white" /> Modify
              Booking
            </div>
            <div
              onClick={() => setPage(CONSTANTS.BOOKING.MANAGE_BOOKING.EXTEND)}
              className="inline-block mb-2 mr-2 px-4 py-2 border border-transparent rounded-full shadow-sm text-lg text-white bg-blue-900 hover:bg-blue-900 focus:outline-none cursor-pointer"
            >
              <FontAwesomeIcon icon={faRoad} className="text-white" /> Extend
              Booking
            </div>
            <div
              onClick={() => setPage(CONSTANTS.BOOKING.MANAGE_BOOKING.CANCEL)}
              className="inline-block p-1 my-2 text-lg text-blue-400 hover:underline  focus:outline-none cursor-pointer"
            >
              Cancel Booking
            </div>
          </div>
          <div className="col-span-12">
            <div
              onClick={reset}
              className="inline-block p-1 my-2 text-lg text-blue-400 hover:underline  focus:outline-none cursor-pointer"
            >
              Use Different Quote Number
            </div>
          </div>
        </div>
      ) : null}
      {page === CONSTANTS.BOOKING.MANAGE_BOOKING.MODIFY ? (
        <ModifyBooking
          quoteNo={manageDetails.quote}
          setPage={setPage}
          reservation={reservation}
        />
      ) : null}
      {page === CONSTANTS.BOOKING.MANAGE_BOOKING.QUOTE ? (
        <ConfirmQuote
          quoteNo={manageDetails.quote}
          reset={reset}
          reservation={reservation}
          email={manageDetails.email}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      {page === CONSTANTS.BOOKING.MANAGE_BOOKING.EXTEND ? (
        <ExtendBooking
          quoteNo={manageDetails.quote}
          setPage={setPage}
          reservation={reservation}
        />
      ) : null}
      {page === CONSTANTS.BOOKING.MANAGE_BOOKING.CANCEL ? (
        <CancelBooking
          quoteNo={manageDetails.quote}
          setPage={setPage}
          reservation={reservation}
        />
      ) : null}
    </>
  )
}
