import { useContext } from 'react'
import { DetailsContext } from 'contexts/details'

const VehiclePreviewHeader = ({
  vehicleItem,
  size = 'normal',
  vehicleData,
}) => {
  let { details } = useContext(DetailsContext)
  //let vehicleHeader = vehicleItem.vehicleGrp_Desc.replace(vehicleItem.vehicleGrp+" - ","").replace("/similar","");
  let vehicleHeader = vehicleData[0]?.acf.vehicle_make_model
  let strippedVehicleHeader = vehicleHeader?.replace(
    /\(manual\)|\(auto\)|\(hybrid\)/gi,
    '',
  )

  //vehicleData[0]?.fimg_url
  return (
    <>
      <h2
        className={`text-gray-900 ${
          size === 'small' ? `text-lg` : `text-xl`
        } font-semibold leading-none w-full`}
      >
        {strippedVehicleHeader}
      </h2>
      <h3 className="text-sm font-normal m-0">
        {vehicleHeader?.toLowerCase().indexOf('manual') !== -1 && '(Manual)'}
        {vehicleHeader?.toLowerCase().indexOf('auto') !== -1 && '(Auto)'}
        {vehicleHeader?.toLowerCase().indexOf('hybrid') !== -1 && '(Hybrid)'}
      </h3>
      <h4 className="text-xs text-blue-400">
        or similar
        {!details.isD2D ? `- Free KM/Day: ${vehicleItem.freeKMS}` : ``}
      </h4>
    </>
  )
}
export default VehiclePreviewHeader
