import { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Loader from "components/loader";
import { ApiConsumer } from "api/ApiConsumer";
import { API_ROUTES } from "routes/api";
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCalendarAlt,
  faCar,
  faClock,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useUtils } from "hooks/utils";

const UserReservations = () => {
  const [loading, setLoading] = useState(false);
  const [quotes, setQuotes] = useState([]);
  let { getBranchById, formatDate, formatTime } = useUtils();
  let userData = JSON.parse(sessionStorage.getItem("sessionUser"));
  let history = useHistory();

  const setBookingNumber = (reservationNumber) => {
    let userReservationData = {
      reservationNumber: reservationNumber,
      reservationEmail: userData.accountEmail,
    };
    sessionStorage.setItem(
      "sessionUserReservation",
      JSON.stringify(userReservationData)
    );
    history.push("/manage-booking");
  };

  useEffect(() => {
    setLoading(true);
    let quoteData = [];
    ApiConsumer.get(API_ROUTES.BOOKING.USER(userData.accountEmail))
      .then((res) => {
        const dateSort = res.data.sort(
          (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
        );

        dateSort.slice(0, 10).map((reservation, i) => {
          ApiConsumer.get(API_ROUTES.QUOTE.USER(userData.accountEmail))
            .then((res) => {
              let customReservation = res.data.filter(
                (quote) => quote.requestReference === reservation.quoteNumber
              );
              quoteData[i] = customReservation[0];
              quoteData[i].reservationNumber = reservation.reservationNumber;
              if (quoteData.length === dateSort.length) setQuotes(quoteData);
            })
            .catch((error) => Sentry.captureException(error))
            .finally(() => {});
        });
      })
      .catch((error) => Sentry.captureException(error))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {}, [quotes]);

  return (
    <>
      {!loading ? (
        <div className="grid  p-5 lg:grid-cols-12 lg:p-10">
          {quotes.length === 0 ? (
            <div className="col-span-12 ">
              You have not made any reservations.{" "}
              <NavLink to="/get-quote/" className="footer-link text-blue-400">
                Make Reservation
              </NavLink>
            </div>
          ) : (
            quotes.slice(0, 10).map((quote, i) => (
              <div
                key={i}
                className="col-span-12 border-b border-gray-300 pb-3 mb-3"
              >
                <div className="grid gap-4 lg:grid-cols-12">
                  <div className="col-span-12 lg:col-span-3">
                    <p className="font-bold text-blue-900 uppercase">
                      {quote.reservationNumber}
                      <br />
                      (Group {quote.vehicleGroup})
                    </p>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <p className="font-bold text-blue-900 uppercase">Pick-up</p>
                    <p className="mb-1">
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        className="text-gray-700 text-lg"
                      />{" "}
                      {quote.pickUpDateTime.split(" ")[0]}
                    </p>
                    <p className="mb-1">
                      <FontAwesomeIcon
                        icon={faClock}
                        className="text-gray-700 text-lg"
                      />{" "}
                      {quote.pickUpDateTime.split(" ")[1]}
                    </p>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <p className="font-bold text-blue-900 uppercase">
                      Drop-off
                    </p>
                    <p className="mb-1">
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        className="text-gray-700 text-lg"
                      />{" "}
                      {quote.dropOffDateTime.split(" ")[0]}
                    </p>
                    <p className="mb-1">
                      <FontAwesomeIcon
                        icon={faClock}
                        className="text-gray-700 text-lg"
                      />{" "}
                      {quote.dropOffDateTime.split(" ")[1]}
                    </p>
                  </div>
                  <div className="col-span-12 lg:col-span-3">
                    <div
                      className="btn-transparent float-left uppercase px-3 py-2 ml-1 text-sm cursor-pointer"
                      onClick={() => setBookingNumber(quote.reservationNumber)}
                    >
                      Modify Booking
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      ) : (
        <div className="mt-2 text-center p-5 lg:p-10 ">
          <Loader />
          <br />
          <br />
          Loading Quotes
        </div>
      )}
    </>
  );
};
export default UserReservations;
