import { useState, useContext, useEffect } from 'react'
import Loader from 'components/loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { ApiConsumer } from 'api/ApiConsumer'
import { API_ROUTES } from 'routes/api'
import { DetailsContext } from 'contexts/details'
import { SaveQuoteContext } from 'contexts/quote'
import { WEBSITE_ROUTES } from 'routes/website'
import { add, isAfter } from 'date-fns'
import * as Sentry from '@sentry/react'
import { useUtils } from 'hooks/utils'
import { CONSTANTS } from 'contants/constants'

const ConfirmQuote = ({ quoteNo, email, reset, setErrorMessage }) => {
  let [error, setError] = useState('')
  let history = useHistory()
  let { details, setDetails } = useContext(DetailsContext)
  let { setSaveQuote } = useContext(SaveQuoteContext)
  let { getDate } = useUtils()
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function getQuote() {
    setShowLoader(true)
    setError('')

    let APIURL = !details.isD2D
      ? API_ROUTES.QUOTE.QUOTE(quoteNo, email)
      : API_ROUTES.DOOR2DOOR.RETRIEVEQUOTE(quoteNo, email)

    ApiConsumer.get(APIURL).then((res) => {
      if (
        isAfter(
          new Date(),
          new Date(
            getDate(add(new Date(res.data.dateCreated), { days: 2 })) +
              ' 23:59:59',
          ),
        )
      ) {
        setError(`Quote (${res.data.requestReference}) has expired.`)
        setShowLoader(false)
        return
      } else {
        setSaveQuote(res.data)
        let payload = !details.isD2D
          ? res.data
          : { [`isD2D`]: true, ...res.data }

        let APIURL = !details.isD2D
          ? API_ROUTES.BOOKING.AVAILABILITY
          : API_ROUTES.DOOR2DOOR.AVAILABILITY

        ApiConsumer.post(APIURL, res.data)
          .then((res) => {
            if (
              Array.isArray(res.data?.errors?.error) &&
              res.data?.errors?.error.length > 0
            ) {
              if (
                !res.data?.errors?.error[0].errorMessage
                  .toLowerCase()
                  .includes('success')
              ) {
                setError(res.data?.errors?.error[0].errorMessage)
                return
              }
              if (
                !res.data?.errors?.error[0].errorMessage
                  .toLowerCase()
                  .includes('success')
              ) {
                setError(res.data?.errors?.error[0].errorMessage)
                return
              }

              setDetails({ ...payload })
              history.push({
                pathname: WEBSITE_ROUTES.AVAILABILITY.link,
                state: { rates: res.data.rates.rate, payload: payload },
              })
              window.scrollTo(0, 0)
            }
            setShowLoader(false)
          })
          .catch((err) => {
            Sentry.captureException(err)
            setShowLoader(false)
          })
          .finally(() => setShowLoader(false))
      }
    })
  }

  return (
    <>
      <div className="grid gap-4 p-10 lg:grid-cols-12">
        <div className="col-span-12 bg-gray-100">
          <h2 className="text-2xl mb-2">Manage Existing Quote ({quoteNo})</h2>
          <p>
            Select any of the following options in order to manage and make
            changes to your quote. The status of reference number{' '}
            <strong>{quoteNo} is "Quote"</strong>.
          </p>
        </div>

        <div className="col-span-12">
          <button
            type="button"
            disabled={showLoader}
            onClick={getQuote}
            className={`inline-block float-left px-4 py-2 mr-2 border border-transparent rounded-full shadow-sm text-lg text-white focus:outline-none  ${
              showLoader
                ? `bg-gray-300 hover:bg-gray-300`
                : `bg-blue-900 hover:bg-blue-900`
            }`}
          >
            <FontAwesomeIcon icon={faCheck} className="text-white" /> Confirm My
            Quote
          </button>
          {showLoader && (
            <div className="mt-2 float-left">
              <Loader />
            </div>
          )}
        </div>
        <div className="col-span-12">
          <div>
            {error.length !== 0 && (
              <div className="col-span-12 text-red-500 text-left py-2">
                {error}
              </div>
            )}
          </div>
        </div>
        <div className="col-span-12">
          <div
            onClick={reset}
            className="inline-block p-1 my-2 text-lg text-blue-400 hover:underline cursor-pointer focus:outline-none"
          >
            Use Different Quote Number
          </div>
        </div>
      </div>
    </>
  )
}
export default ConfirmQuote
