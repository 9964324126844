import { useContext, useState, useEffect } from 'react'
import { API_ROUTES } from 'routes/api'
import { ApiConsumer } from 'api/ApiConsumer'
import Loader from 'components/loader'
import AddOnsProduct from 'components/availability/add-ons-product'
import * as Sentry from '@sentry/react'
import { BranchContext } from 'contexts/branch'
import { DetailsContext } from 'contexts/details'

const AddOns = ({
  accountType,
  pickUpBranch,
  dropOffBranch,
  vehicleGroup,
  addOnProducts,
  setAddOnProducts,
  selectedProducts,
  setAddOnClicked,
  vehicleData = null,
}) => {
  let { details } = useContext(DetailsContext)
  const { branches } = useContext(BranchContext)
  const allowDelivery = branches.filter(
    (branch) =>
      pickUpBranch === branch.branchCode && branch.allowDelivery === true,
  )
  const allowCollection = branches.filter(
    (branch) =>
      dropOffBranch === branch.branchCode && branch.allowCollection === true,
  )

  const [isLoaded, setLoaded] = useState(false)

  //State for add-ons
  const [addOns, setAddOns] = useState([])
  useEffect(() => {
    if (!details.isD2D) {
      ApiConsumer.get(
        API_ROUTES.BOOKING.PRODUCTS(accountType, pickUpBranch, vehicleGroup),
      )
        .then((res) => {
          setAddOns(res.data)
        })
        .catch((err) => {
          Sentry.captureException(err)
        })
        .finally(() => {
          setLoaded(true)
        })
    } else {
      setLoaded(true)

      let tmpAddOns = [
        {
          productType: 'ADC',
          productCode: 'TRAIL',
          productDescription: 'Trailer',
        },
        {
          productType: 'ADC',
          productCode: 'BABY',
          productDescription: 'Child Seat x1 (6mth-3yr)',
        },
        {
          productType: 'ADC',
          productCode: 'BOOST',
          productDescription: 'Child Seat x1 (3yr+)',
        },
        {
          productType: 'ADC',
          productCode: 'BABI1',
          productDescription: 'Child Seat x1 (0-6mth)',
        },
      ]

      if (vehicleData !== null && vehicleData[0]?.acf?.pax_seating < 4)
        tmpAddOns = tmpAddOns.filter((value) => value.productCode !== 'TRAIL')

      setAddOns(tmpAddOns.filter((value) => Object.keys(value).length !== 0))
    }
  }, [vehicleData])

  useEffect(() => {
    if (selectedProducts !== null) {
      if (selectedProducts !== undefined) {
        setAddOnProducts((prevState) => ({
          ...prevState,
          ['products']: selectedProducts,
        }))
      }
    }
  }, [selectedProducts])

  let addOnsData = []
  if (isLoaded) {
    addOnsData['waivers'] = addOns.filter(
      (product) => product.productType === 'WAI',
    )
    addOnsData['additional'] = addOns.filter(
      (product) =>
        product.productType !== 'WAI' &&
        product.productType !== 'EXS' &&
        product.productType !== 'PREM',
    )
    addOnsData['extended'] = addOns.filter(
      (product) => product.productType === 'EXS',
    )

    if (Object.keys(allowDelivery).length === 0)
      addOnsData['extended'] = addOnsData['extended'].filter(
        (x) => x.productCode !== 'DEL',
      )

    if (Object.keys(allowCollection).length === 0)
      addOnsData['extended'] = addOnsData['extended'].filter(
        (x) => x.productCode !== 'COL',
      )
  }

  return (
    <>
      <div className="col-span-8 p-3  text-sm">
        {isLoaded ? (
          <>
            <div className="mb-3">
              {addOnsData['waivers'].length ? (
                <h3 className="text-xl px-2 pb-1">
                  {details.isD2D ? `Extras` : `Waivers`}
                </h3>
              ) : null}
              {addOnsData['waivers'].map((product, index) => (
                <AddOnsProduct
                  key={index}
                  product={product}
                  addOnProducts={addOnProducts}
                  setAddOnProducts={setAddOnProducts}
                  setAddOnClicked={setAddOnClicked}
                />
              ))}
              <div className="clear-both" />
            </div>

            <div className="mb-3">
              <h3 className="text-xl px-2 pb-1">Additional Services</h3>
              {addOnsData['additional'].map((product, index) => (
                <AddOnsProduct
                  key={index}
                  product={product}
                  addOnProducts={addOnProducts}
                  setAddOnProducts={setAddOnProducts}
                  setAddOnClicked={setAddOnClicked}
                />
              ))}
              <div className="clear-both" />
            </div>
            <div className="mb-3">
              {addOnsData['extended'].length ? (
                <h3 className="text-xl px-2 pb-1">Extended Services</h3>
              ) : null}
              {addOnsData['extended'].map((product, index) => (
                <AddOnsProduct
                  key={index}
                  product={product}
                  addOnProducts={addOnProducts}
                  setAddOnProducts={setAddOnProducts}
                  setAddOnClicked={setAddOnClicked}
                />
              ))}
              <div className="clear-both" />
            </div>
          </>
        ) : (
          <div className="text-center p-2 col-span-12">
            <Loader />
          </div>
        )}
      </div>
    </>
  )
}
export default AddOns
