import React, { useState, useEffect, useContext } from "react";
import {
  GoogleMap,
  Marker,
  DirectionsService,
  DirectionsRenderer,
  DistanceMatrixService,
} from "@react-google-maps/api";
import { DetailsContext } from "contexts/details";
import { CONSTANTS } from "contants/constants";

const D2DGoogleMap = ({ mapLocations }) => {
  const [mapDistanceLocations, setMapDistanceLocations] = useState([]);
  const [mapDirectionsLocations, setMapDirectionsLocations] = useState([]);

  const { details, setDetails } = useContext(DetailsContext);

  const [mapDirectionsResponse, setMapDirectionsResponse] = useState(null);
  const [directionsLoaded, setDirectionsLoaded] = useState(false);

  const [distanceLoaded, setDistanceLoaded] = useState(false);
  const [totalDistance, setTotalDistance] = useState(0);

  const [pickUpToDropOffDistance, setPickUpToDropOffDistance] = useState({});
  const [depotToLocationDistance, setDepotToLocationDistance] = useState({});

  let D2DBranches = CONSTANTS.D2D_VEHICLES;

  let D2DBranchAddresses = [];
  D2DBranchAddresses = D2DBranches.filter(
    (branch) => branch.status === "active"
  );
  D2DBranchAddresses = D2DBranchAddresses.map((branch) => {
    return branch.address;
  });

  const containerStyle = {
    width: "100%",
    height: "300px",
  };

  var mapOptions = {
    zoom: 7,
    center: { lat: -26.109498, lng: 28.053556 },
    disableDefaultUI: true,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: true,
    rotateControl: true,
    fullscreenControl: true,
  };

  const onLoadMarker = (marker) => {
    //console.log('Marker: ', marker)
  };

  const directionsCallback = (response, status) => {
    //console.log(response)
    if (response !== null && status === "OK" && !directionsLoaded) {
      setMapDirectionsResponse(response);
      setDirectionsLoaded(true);
      setPickUpToDropOffDistance(response.routes[0].legs[0].distance);
    }
  };

  const distanceMatrixCallback = (response, status) => {
    if (response !== null && status === "OK" && !distanceLoaded) {
      setDistanceLoaded(true);
      findClosestBranch(response);
    }
  };

  const findClosestBranch = (response) => {
    let origins = response.originAddresses; //array of origin addresses
    let destinations = response.destinationAddresses; //array of destination addresses
    let rows = response.rows;

    let depotToLocation = { pickup: [], dropoff: [] };

    rows.forEach((row, i) => {
      row.elements.forEach((element, j) => {
        depotToLocation[i === 0 ? `pickup` : `dropoff`].push({
          location: origins[i],
          branch: destinations[j],
          branchCode: D2DBranches[j].code,
          distance: element.distance,
        });
      });
      depotToLocation[i === 0 ? `pickup` : `dropoff`].sort((a, b) =>
        a.distance.value > b.distance.value ? 1 : -1
      );
    });

    //Must be same as origin branch
    depotToLocation.dropoff = depotToLocation.dropoff.filter(
      (x) => x.branchCode === depotToLocation.pickup[0].branchCode
    );

    let totalDistance =
      depotToLocation.pickup[0].distance.value +
      pickUpToDropOffDistance.value +
      depotToLocation.dropoff[0].distance.value;

    setDetails((prevState) => ({
      ...prevState,
      [`tripKms`]: parseInt(totalDistance / 1000),
    }));
    setDetails((prevState) => ({
      ...prevState,
      [`PICKUP_BRANCH`]: depotToLocation.pickup[0].branchCode,
    }));
    setDetails((prevState) => ({
      ...prevState,
      [`DROPOFF_BRANCH`]: depotToLocation.dropoff[0].branchCode,
    }));
    setDepotToLocationDistance(depotToLocation);
    setTotalDistance(parseInt(totalDistance));
  };

  useEffect(() => {
    setDirectionsLoaded(false);
    setDistanceLoaded(false);
    /*if (mapLocations.origin !== null)
      console.log({ ORIGIN: mapLocations.origin.getPlace() })
    if (mapLocations.destination !== null)
      console.log({ DESTINATION: mapLocations.destination.getPlace() })*/
    setDetails((prevState) => ({
      ...prevState,
      [`tripKms`]: 0,
    }));
    /*console.log(mapLocations)
    console.log(mapLocations.waypoint1?.getPlace()?.geometry?.location)
    console.log(mapLocations.waypoint2?.getPlace()?.geometry?.location)
    console.log(mapLocations.waypoint3?.getPlace()?.geometry?.location)
    console.log(mapLocations.waypoint4?.getPlace()?.geometry?.location)
    console.log(details)*/

    setMapDistanceLocations(convertDistanceLocations(mapLocations));
    setMapDirectionsLocations(convertDirectionsLocations(mapLocations));
  }, [mapLocations]);

  const convertDistanceLocations = (mapLocations) => {
    let tempArray = [];
    let obj = {};

    Object.keys(mapLocations).forEach(function (key, i) {
      if (mapLocations[key] && mapLocations[key] !== null) {
        tempArray.push(mapLocations[key]?.getPlace()?.geometry?.location);
      }
    });
    return tempArray;
  };

  const convertDirectionsLocations = (mapLocations) => {
    let tempArray = [];
    let obj = {};
    //console.log('DIRECTION LOCATIONS')
    //console.log(mapLocations['origin']?.getPlace())
    Object.keys(mapLocations).forEach(function (key, i) {
      if (
        mapLocations[key] &&
        mapLocations[key] !== null &&
        key !== "origin" &&
        key !== "destination"
      ) {
        //console.log(mapLocations[key]?.getPlace())
        tempArray.push({
          location: mapLocations[key]?.getPlace()?.geometry?.location,
          stopover: false,
        });
      }
    });
    //console.log(mapLocations['destination']?.getPlace())
    return tempArray;
  };

  return (
    <>
      {Object.keys(pickUpToDropOffDistance).length &&
      Object.keys(depotToLocationDistance).length ? (
        <div className="mb-2">
          {/* Depot to pick-up: {depotToLocationDistance.pickup[0].distance.text} |
          Pick-up to drop-off: {pickUpToDropOffDistance.text} | Drop-off to
          depot: {depotToLocationDistance.dropoff[0].distance.text} |{' '} */}
          <span className="font-semibold">
            TOTAL TRANSFER: {parseInt(totalDistance / 1000)} km
          </span>
        </div>
      ) : null}
      <GoogleMap mapContainerStyle={containerStyle} {...mapOptions}>
        {mapLocations.destination === null && mapLocations.origin !== null && (
          <Marker
            onLoad={onLoadMarker}
            position={mapLocations.origin?.getPlace()?.geometry?.location}
          />
        )}

        {mapLocations.origin === null && mapLocations.destination !== null && (
          <Marker
            onLoad={onLoadMarker}
            position={mapLocations.destination?.getPlace()?.geometry?.location}
          />
        )}

        {mapLocations.waypoint1 !== null &&
          mapLocations.waypoint1?.length !== 0 && (
            <Marker
              onLoad={onLoadMarker}
              position={mapLocations.waypoint1?.getPlace()?.geometry?.location}
            />
          )}

        {mapLocations.waypoint2 !== null &&
          mapLocations.waypoint2?.length !== 0 && (
            <Marker
              onLoad={onLoadMarker}
              position={mapLocations.waypoint2?.getPlace()?.geometry?.location}
            />
          )}

        {mapLocations.waypoint3 !== null &&
          mapLocations.waypoint3?.length !== 0 && (
            <Marker
              onLoad={onLoadMarker}
              position={mapLocations.waypoint3?.getPlace()?.geometry?.location}
            />
          )}

        {mapLocations.waypoint4 !== null &&
          mapLocations.waypoint4?.length !== 0 && (
            <Marker
              onLoad={onLoadMarker}
              position={mapLocations.waypoint4?.getPlace()?.geometry?.location}
            />
          )}

        {mapLocations.origin !== null && mapLocations.destination !== null && (
          <>
            {mapDirectionsLocations.length !== 0 ? (
              <DirectionsService
                options={{
                  origin: mapLocations.origin?.getPlace()?.geometry?.location,
                  destination:
                    mapLocations.destination?.getPlace()?.geometry?.location,
                  waypoints: mapDirectionsLocations,
                  optimizeWaypoints: true,
                  travelMode: "DRIVING",
                }}
                callback={directionsCallback}
              />
            ) : (
              <DirectionsService
                options={{
                  origin: mapLocations.origin?.getPlace()?.geometry?.location,
                  destination:
                    mapLocations.destination?.getPlace()?.geometry?.location,
                  travelMode: "DRIVING",
                }}
                callback={directionsCallback}
              />
            )}
            {directionsLoaded && (
              <>
                <DirectionsRenderer
                  options={{
                    directions: mapDirectionsResponse,
                  }}
                />

                <DistanceMatrixService
                  options={{
                    origins: mapDistanceLocations,
                    destinations: D2DBranchAddresses,
                    travelMode: "DRIVING",
                    avoidHighways: false,
                    avoidTolls: false,
                  }}
                  callback={distanceMatrixCallback}
                />
              </>
            )}
          </>
        )}
      </GoogleMap>
    </>
  );
};
export default D2DGoogleMap;
