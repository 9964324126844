import { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ApiConsumer } from "api/ApiConsumer";
import { CONSTANTS } from "contants/constants";
import { CMS_ROUTES } from "routes/cms";
import * as Sentry from "@sentry/react";
import Header from "components/header";
import Footer from "components/footer";
import ContactForm from "forms/contact";
import BookNowBanner from "components/footer/booknow-banner";
import Loader from "components/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import Modals from "components/modals/index";
import { Helmet } from "react-helmet";

const Page = () => {
  const params = useParams();

  const pageSlugs = params.slug.split("/");
  const pageSlug = pageSlugs[pageSlugs.length - 1];
  const pageUrl = CMS_ROUTES.POST_PAGE("pages", pageSlug);

  const [visibleModal, setVisibleModal] = useState("");
  const [pagePostModalSlug, setPagePostModalSlug] = useState("");
  const [loginAccountType, setLoginAccountType] = useState(
    CONSTANTS.LOGIN_ACCOUNT_TYPE.CORPORATE
  );

  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [loaded, setLoaded] = useState(false);
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    ApiConsumer.get(pageUrl)
      .then((res) => {
        if (res.data.length) {
          setContent(res.data[0].content.rendered);
          setTitle(res.data[0].title.rendered);
        } else {
          history.push("/404");
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      })
      .finally(() => {
        setLoaded(true);
      });
  }, [pageUrl]);

  return (
    <>
      <Helmet>
        <title>{title} | Bluu Car Rental</title>
      </Helmet>

      <Header />

      <Modals
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        loginAccountType={loginAccountType}
        setLoginAccountType={setLoginAccountType}
        pagePostModalSlug={pagePostModalSlug}
        setPagePostModalSlug={setPagePostModalSlug}
      />

      {loaded ? (
        <>
          <div className="w-full bg-gray-200 ">
            <div className="container m-auto py-10 max-w-screen-xl">
              <div className="col-span-12 ">
                <h1 className="text-3xl lg:text-5xl px-4 lg:p-6 text-blue-900">
                  {title}
                </h1>
              </div>
            </div>
          </div>

          <div
            id={pageSlug}
            className="container m-auto py-10 max-w-screen-xl page-content"
          >
            <div className="flex flex-wrap">
              <div className="w-full py-5 px-6">
                <div dangerouslySetInnerHTML={{ __html: content }} />

                {/*tabContentSections.map((tabContent,i)=> (
                        
                        <div key={i} >
                            <div className="grid gap-4 p-5 text-center lg:grid-cols-12 lg:p-10">
                                <div className="col-span-12 bg-gray-100">
                                    <div dangerouslySetInnerHTML={{__html: tabContent}} />
                                </div>
                            </div>
                        </div>
                    ))*/}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="mt-2 text-center p-20">
          <Loader />
        </div>
      )}

      {pageSlug === "corporate-customers" || pageSlug === "tour-operators" ? (
        <div className="container m-auto max-w-screen-xl">
          <div className="flex flex-wrap">
            <div className="w-full pb-10 px-6 text-center">
              <div
                className="inline-block w-5/12 lg:w-auto text-white rounded-md px-4 py-5 bg-blue-900 hover:bg-blue-900 cursor-pointer focus:outline-none mx-2"
                onClick={() => {
                  setVisibleModal(CONSTANTS.MODAL_TYPE.CORPTOUR);
                  pageSlug === "corporate-customers"
                    ? setLoginAccountType(
                        CONSTANTS.LOGIN_ACCOUNT_TYPE.CORPORATE
                      )
                    : setLoginAccountType(CONSTANTS.LOGIN_ACCOUNT_TYPE.TOUR);
                }}
              >
                <FontAwesomeIcon
                  icon={faCalendarCheck}
                  className="text-white text-5xl mb-2"
                />
                <p className="mb-0">MAKE RESERVATION</p>
              </div>
              <NavLink
                to="/manage-booking/"
                className="inline-block w-5/12 lg:w-auto text-white rounded-md px-4 py-5 bg-blue-900 hover:bg-blue-900 cursor-pointer focus:outline-none mx-2"
              >
                <FontAwesomeIcon
                  icon={faCar}
                  className="text-white text-5xl mb-2"
                />
                <p className="mb-0">MANAGE BOOKING</p>
              </NavLink>
            </div>
          </div>
        </div>
      ) : null}

      {pageSlug === "contacts" && <ContactForm />}

      <BookNowBanner />
      <Footer />
    </>
  );
};
export default Page;
