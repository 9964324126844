const BranchSingle = ({branchPage}) => {

    return <>
    <div className="w-full lg:w-8/12 py-0 px-4 lg:py-5 lg:px-6">
        <div className="grid lg:grid-cols-12 gap-4">

            <div className="col-span-12 lg:col-span-6">
                <h3 className="block py-3 px-5 bg-gray-200 rounded-md uppercase text-blue-900 font-semibold">Address</h3>
                <p className="py-3 px-5"><a className="hover:underline text-blue-400" href={`https://maps.google.com/maps?q=${branchPage.acf.latitude},${branchPage.acf.longitude}&z=14`} target="_blank" rel="noreferrer">{branchPage.acf.address}</a></p>
            </div>
            <div className="col-span-12 lg:col-span-6">
                <h3 className="block py-3 px-5 bg-gray-200 rounded-md uppercase text-blue-900 font-semibold">Email</h3>
                <p className="py-3 px-5"><a className="hover:underline text-blue-400" href={`mailto:${branchPage.acf.email}`}>{branchPage.acf.email}</a></p>
            </div>

            <div className="col-span-12 lg:col-span-6">
                <h3 className="block py-3 px-5 bg-gray-200 rounded-md uppercase text-blue-900 font-semibold">Hours</h3>
                <p className="py-3 px-5"><div dangerouslySetInnerHTML={{__html: branchPage.acf.branch_hours }} /></p>
            </div>
            <div className="col-span-12 lg:col-span-6">
                <h3 className="block py-3 px-5 bg-gray-200 rounded-md uppercase text-blue-900 font-semibold">After Hours</h3>
                <p className="py-3 px-5">{branchPage.acf.after_hours_contact_person}<br />{branchPage.acf.after_hours_contact_number}</p>
            </div>

            <div className="col-span-12 lg:col-span-6">
                <h3 className="block py-3 px-5 bg-gray-200 rounded-md uppercase text-blue-900 font-semibold">Telephone</h3>
                <p className="py-3 px-5">{branchPage.acf.telephone}</p>
            </div>
            <div className="col-span-12 lg:col-span-6">
                <h3 className="block py-3 px-5 bg-gray-200 rounded-md uppercase text-blue-900 font-semibold">Fax</h3>
                <p className="py-3 px-5">{branchPage.acf.fax}</p>
            </div>

            <div className="col-span-12 lg:col-span-6">
                <h3 className="block py-3 px-5 bg-gray-200 rounded-md uppercase text-blue-900 font-semibold">Longitude (X)</h3>
                <p className="py-3 px-5">{branchPage.acf.longitude}</p>
            </div>
            <div className="col-span-12 lg:col-span-6">
                <h3 className="block py-3 px-5 bg-gray-200 rounded-md uppercase text-blue-900 font-semibold">Latitude (Y)</h3>
                <p className="py-3 px-5">{branchPage.acf.latitude}</p>
            </div>

            <div className="col-span-12 lg:col-span-6">
                <h3 className="block py-3 px-5 bg-gray-200 rounded-md uppercase text-blue-900 font-semibold">GDS</h3>
                <p className="py-3 px-5">{branchPage.acf.gds}</p>
            </div>

            <div className="col-span-12">
                {  
                    (branchPage.acf.latitude!=="" && branchPage.acf.longitude!=="") ?
                        <iframe title="branch-map" src={`https://maps.google.com/maps?q=${branchPage.acf.latitude},${branchPage.acf.longitude}&z=14&output=embed`} width="100%" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" style={{border:0}}></iframe>
                    : null
                }
            </div>
        </div> 
    </div>  
    </>
}
export default BranchSingle;
