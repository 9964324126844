import * as Sentry from "@sentry/react";

const axios = require("axios");
export const ApiConsumer = axios.create()

ApiConsumer.interceptors.request.use(request => {
        return request;
    }, error => {
    Sentry.captureException(error)
        return Promise.reject(error);
    }
);
