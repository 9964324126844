import {createContext} from "react";
import {CONSTANTS} from "contants/constants";
import {add} from "date-fns";

const dateCoeff = 1000 * 60 * 15;
const defaultDate = new Date()
const defaultTime = new Date()
const pickupDate = defaultTime.setHours(defaultTime.getHours() + 2, defaultTime.getMinutes() + 30, 0, 0)

export const baseDetailsObj = {
    [`${CONSTANTS.PICKUP.toUpperCase().replace('-', '')}_DATE`]: defaultDate,
    [`${CONSTANTS.PICKUP.toUpperCase().replace('-', '')}_TIME`]: new Date(Math.ceil(pickupDate / dateCoeff) * dateCoeff),
    [`${CONSTANTS.PICKUP.toUpperCase().replace('-', '')}_BRANCH`]: "JS",
    [`${CONSTANTS.DROPOFF.toUpperCase().replace('-', '')}_BRANCH`]: "JS",
    [`${CONSTANTS.DROPOFF.toUpperCase().replace('-', '')}_DATE`]: add(defaultDate, {days: 2}),
    [`${CONSTANTS.DROPOFF.toUpperCase().replace('-', '')}_TIME`]: defaultDate.setHours(9, 0, 0, 0),
    accountType: "I",
    accountNo: '',
    accountName: '',
    RESIDENCE: "L",
    isD2D: false,
}

export const DetailsContext = createContext({
    details: baseDetailsObj,
    setDetails: () => {
    }
});
