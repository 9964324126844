import { useState, useEffect, useRef, useContext } from "react";
import { API_ROUTES } from "routes/api";
import { ApiConsumer } from "api/ApiConsumer";
import Loader from "components/loader";
import Modals from "components/modals/index";
import VehicleItem from "components/availability/vehicle-item";
import VehiclePreview from "components/availability/vehicle-preview";
import VehicleCostsSidebar from "components/availability/vehicle-costs-sidebar";
import AddOns from "components/availability/add-ons";
import VehicleWaiver from "components/availability/vehicle-waiver";
import D2DVehicleWaiver from "components/d2d/d2d-vehicle-waiver";
import { useUtils } from "hooks/utils";
import { QuoteContext, SaveQuoteContext } from "contexts/quote";
import { VehicleContext } from "contexts/vehicle";
import SectionSelectBranch from "components/get-quote/section-select-branch";
import { CONSTANTS } from "contants/constants";
import { WEBSITE_ROUTES } from "routes/website";
import { useHistory } from "react-router-dom";
import { add, isBefore } from "date-fns";
import { DetailsContext } from "contexts/details";
import * as Sentry from "@sentry/react";
import { UserContext } from "contexts/user";

const Availability = ({ selectedData }) => {
  const [visibleModal, setVisibleModal] = useState("");
  const [pagePostModalSlug, setPagePostModalSlug] = useState("");
  const [loginAccountType, setLoginAccountType] = useState(
    CONSTANTS.LOGIN_ACCOUNT_TYPE.CORPORATE
  );

  const [rates, setRates] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [isRecalcError, setRecalcError] = useState(false);
  const [calcLoading, setCalcLoading] = useState(true);
  const [selectedGroup] = useState([]);
  const [fleetData, setFleetData] = useState({});
  const page_url =
    "https://cms.bluucarrental.com/wp-json/wp/v2/fleet?per_page=100";
  const [errorMessage, setErrorMessage] = useState("");
  const [rateCode, setRateCode] = useState("");
  const [showRateCode, setShowRateCode] = useState(false);
  const [addOnClicked, setAddOnClicked] = useState(false);
  const [otherRateCodes, setOtherRateCodes] = useState([]);
  let { getDate, getTime, encodeHTMLEntities } = useUtils();
  let { user } = useContext(UserContext);

  //State to manage add ons (MAKE CONTEXT)

  let { setQuote } = useContext(QuoteContext);
  let { saveQuote, setSaveQuote } = useContext(SaveQuoteContext);
  const [addOnProducts, setAddOnProducts] = useState({
    products: selectedData?.payload?.productCode?.productCodeLine ?? [],
    drivers: [],
    additionalDrivers: selectedData?.payload?.additionalDrivers ?? 0,
  });
  let { vehicle, setVehicle } = useContext(VehicleContext);
  let { details } = useContext(DetailsContext);

  let history = useHistory();

  //Set scroller
  const chooseWaiversRef = useRef(null);
  const executeChooseWaiversScroll = () => {
    chooseWaiversRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };
  //Set scroller
  const chooseVehicleRef = useRef(null);
  const executeChooseVehicleScroll = () => {
    chooseVehicleRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  //Only get charges when changing waiver
  const setSelectedVehicle = (vehicleItem, waiver) => {
    setVehicle({ ...vehicleItem, selectedWaiver: waiver });
    executeChooseWaiversScroll();
    setAddOnClicked(false);
  };

  const getQuoteCallBack = () => {
    setCalcLoading(false);
    setErrorMessage("");

    const data = {
      ...selectedData.payload,
      rateCode: rates[0]?.rateCode,
      rateDesc: rates[0]?.rateDesc,
      products: addOnProducts.products,
      pai: addOnProducts.products.includes("PAI") ? "Y" : "N",
      drivers: addOnProducts.drivers,
      additionalDrivers:
        saveQuote.additionalDrivers ?? addOnProducts.additionalDrivers,

      vehicleGroup: vehicle.vehicleGrp,
      vehicleGrp_Desc: vehicle.vehicleGrp_Desc,
      cdw: vehicle.selectedWaiver === "CDW" ? "Y" : "N",
      scdw: vehicle.selectedWaiver === "SCDW" ? "Y" : "N",
      tlw: vehicle.selectedWaiver === "CDW" ? "Y" : "N",
      stlw: vehicle.selectedWaiver === "SCDW" ? "Y" : "N",
    };

    setSaveQuote(data);

    let payload = details.isD2D
      ? {
          ...data,
          pickupFlightNumber: details.pickupFlightNumber,
          dropOffFlightNumber: "",
          collectionAddressLines: [
            {
              collectionAddress: encodeHTMLEntities(
                details.PICKUP_BRANCH_ADDRESS
                  ? details.PICKUP_BRANCH_ADDRESS
                  : details.collectFromAirportName
              ),
            },
          ],
          collectionAddress: encodeHTMLEntities(
            details.PICKUP_BRANCH_ADDRESS
              ? details.PICKUP_BRANCH_ADDRESS
              : details.collectFromAirportName
          ),

          collectFromAirportName: encodeHTMLEntities(
            details.PICKUP_BRANCH_ADDRESS
              ? details.PICKUP_BRANCH_ADDRESS
              : details.collectFromAirportName
          ),
          deliveryAddressLines: [
            {
              deliveryAddress: encodeHTMLEntities(
                details.DROPOFF_BRANCH_ADDRESS
                  ? details.DROPOFF_BRANCH_ADDRESS
                  : details.deliverToAirportName
              ),
            },
          ],
          deliveryAddress: encodeHTMLEntities(
            details.DROPOFF_BRANCH_ADDRESS
              ? details.DROPOFF_BRANCH_ADDRESS
              : details.deliverToAirportName
          ),
          delivToAirportName: encodeHTMLEntities(
            details.DROPOFF_BRANCH_ADDRESS
              ? details.DROPOFF_BRANCH_ADDRESS
              : details.deliverToAirportName
          ),
          collectionAddress1: encodeHTMLEntities(
            details.COLLECTIONADDRESS1_BRANCH_ADDRESS
              ? details.COLLECTIONADDRESS1_BRANCH_ADDRESS
              : details.collectionAddress1
          ),
          collectionAddress2: encodeHTMLEntities(
            details.COLLECTIONADDRESS2_BRANCH_ADDRESS
              ? details.COLLECTIONADDRESS2_BRANCH_ADDRESS
              : details.collectionAddress2
          ),
          deliveryAddress1: encodeHTMLEntities(
            details.DELIVERYADDRESS1_BRANCH_ADDRESS
              ? details.DELIVERYADDRESS1_BRANCH_ADDRESS
              : details.deliveryAddress1
          ),
          deliveryAddress2: encodeHTMLEntities(
            details.DELIVERYADDRESS2_BRANCH_ADDRESS
              ? details.DELIVERYADDRESS2_BRANCH_ADDRESS
              : details.deliveryAddress2
          ),
          tripKms: details.tripKms.toString(),
          numberPassengers: details.numberPassengers.toString(),
        }
      : data;

    ApiConsumer.post(
      details.isD2D ? API_ROUTES.DOOR2DOOR.QUOTE : API_ROUTES.BOOKING.QUOTE,
      payload
    )
      .then((res) => {
        setQuote(res.data);

        if (
          Array.isArray(res.data?.errors?.error) &&
          res.data?.errors?.error.length > 0
        ) {
          if (
            !res.data?.errors?.error[0].errorMessage
              .toLowerCase()
              .includes("success")
          ) {
            setErrorMessage(
              res.data?.errors?.error[0].errorMessage
                .replace("Bidvest", "Bluu")
                .replace("bidvestcarrental.co.za", "bcr.co.za")
            );
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      })
      .finally(() => {
        setCalcLoading(true);
      });
  };

  function Recalculate() {
    setLoaded(false);
    setErrorMessage("");

    //Check if booking is in no less than 2h15m time
    /*const pickupDateTime = new Date(details.PICKUP_DATE + ' ' + details.PICKUP_TIME);
        const dropoffDateTime = new Date(details.DROPOFF_DATE + ' ' + details.DROPOFF_TIME);
        const validBookingDateTime = add(new Date(), {hours: 2, minutes: 20});//Give 10 grace time*/

    //Check if booking is in no less than 2h15m time
    const pickupDateTime = new Date(
      `${getDate(details.PICKUP_DATE)} ${getTime(details.PICKUP_TIME)}`
    );
    const dropoffDateTime = new Date(
      `${getDate(details.DROPOFF_DATE)} ${getTime(details.DROPOFF_TIME)}`
    );
    const validBookingDateTime = add(new Date(), { hours: 2, minutes: 25 }); //Give 5 mins grace time

    if (isBefore(pickupDateTime, validBookingDateTime)) {
      setErrorMessage("Pick-up time must be 2 hours 30 minutes from now.");
      setLoaded(true);
    } else if (isBefore(dropoffDateTime, pickupDateTime)) {
      setErrorMessage("Drop-off date cannot be before pick-up.");
      setLoaded(true);
    } else if (isBefore(dropoffDateTime, add(pickupDateTime, { hours: 2 }))) {
      setErrorMessage("Drop-off time cannot be within 2 hours of pick-up.");
      setLoaded(true);
    } else {
      const payload = {
        ...selectedData.payload,
        pickUpBranch: details.PICKUP_BRANCH,
        pickUpDateTime: `${getDate(details.PICKUP_DATE)} ${getTime(
          details.PICKUP_TIME
        )}`,
        dropOffBranch: details.DROPOFF_BRANCH,
        dropOffDateTime: `${getDate(details.DROPOFF_DATE)} ${getTime(
          details.DROPOFF_TIME
        )}`,
        accountType: details.accountType,
        accountNo: details.accountNo,
        foreignLocal: details.RESIDENCE,
        rateCode:
          errorMessage === "" &&
          rateCode !== rates[0]?.rateCode &&
          showRateCode === true
            ? rateCode
            : "",
      };

      ApiConsumer.post(API_ROUTES.BOOKING.AVAILABILITY, payload)
        .then((res) => {
          if (
            Array.isArray(res.data?.errors?.error) &&
            res.data?.errors?.error.length > 0
          ) {
            if (
              !res.data?.errors?.error[0].errorMessage
                .toLowerCase()
                .includes("success")
            ) {
              //setVehicles([])
              setRecalcError(true);
              setErrorMessage(
                res.data?.errors?.error[0].errorMessage
                  .replace("Bidvest", "Bluu")
                  .replace("bidvestcarrental.co.za", "bcr.co.za")
              );
              return;
            }
            if (res.data.rates.rate[0].charges.length === 0) {
              //setVehicles([])
              setRecalcError(true);
              setErrorMessage(
                "No vehicles available for selected date/time. Contact us on 086 101 7722 or reservations@bcr.co.za"
              );
              return;
            }

            /*setQuote({})
                        setSaveQuote({})
                        setVehicle({})*/
            setRecalcError(false);

            if (res.data.rates.rate.length > 0)
              history.push({
                pathname: WEBSITE_ROUTES.AVAILABILITY.link,
                state: {
                  rates: res.data.rates.rate,
                  otherRates: res.data.otherRates ?? [],
                  payload: payload,
                },
              });
            else {
              //setVehicles([])
              setRecalcError(true);
              setErrorMessage(
                "Pick Up not in the Branch hours. Phone Bluu 086 101 7722 or refer to Location details"
              );
            }
          }
        })
        .catch((err) => {
          console.error(err);
          setRecalcError(true);
        })
        .finally(() => {
          setLoaded(true);
        });
    }
  }

  useEffect(() => {
    if (selectedData !== null && selectedData !== undefined) {
      setRates(selectedData.rates);

      let otherRates = []; //For other rate codes
      if (selectedData?.otherRates !== null) {
        for (const [key, rate] of Object.entries(
          selectedData?.otherRates?.otherRate ?? []
        )) {
          otherRates[key] = {
            rateCode: rate.rateCode,
            rateDesc: rate.rateDesc,
          };
        }

        otherRates.unshift({
          rateCode: selectedData?.rates[0]?.rateCode,
          rateDesc: selectedData?.rates[0]?.rateDesc,
        });

        setOtherRateCodes(otherRates);
      }

      setLoaded(true);
    }

    if (selectedData?.payload?.vehicleGroup !== undefined) {
      let temp = selectedData?.rates[0]?.charges.filter(
        (x) => x.vehicleGrp === selectedData?.payload.vehicleGroup
      );
      if (Array.isArray(temp)) {
        if (temp.length > 0) {
          setSelectedVehicle(
            temp[0],
            selectedData?.payload?.cdw === "Y" ||
              selectedData?.payload?.cdw === "CDW"
              ? "CDW"
              : "SCDW"
          );
        }
      }

      let payload = details.isD2D
        ? {
            ...selectedData?.payload,
            pickupFlightNumber: details.pickupFlightNumber,
            dropOffFlightNumber: "",
            collectionAddressLines: [
              {
                collectionAddress: encodeHTMLEntities(
                  details.PICKUP_BRANCH_ADDRESS
                    ? details.PICKUP_BRANCH_ADDRESS
                    : details.collectFromAirportName
                ),
              },
            ],
            collectionAddress: encodeHTMLEntities(
              details.PICKUP_BRANCH_ADDRESS
                ? details.PICKUP_BRANCH_ADDRESS
                : details.collectFromAirportName
            ),
            collectFromAirportName: encodeHTMLEntities(
              details.PICKUP_BRANCH_ADDRESS
                ? details.PICKUP_BRANCH_ADDRESS
                : details.collectFromAirportName
            ),
            deliveryAddressLines: [
              {
                deliveryAddress: encodeHTMLEntities(
                  details.DROPOFF_BRANCH_ADDRESS
                    ? details.DROPOFF_BRANCH_ADDRESS
                    : details.deliverToAirportName
                ),
              },
            ],
            deliveryAddress: encodeHTMLEntities(
              details.DROPOFF_BRANCH_ADDRESS
                ? details.DROPOFF_BRANCH_ADDRESS
                : details.deliverToAirportName
            ),
            delivToAirportName: encodeHTMLEntities(
              details.DROPOFF_BRANCH_ADDRESS
                ? details.DROPOFF_BRANCH_ADDRESS
                : details.deliverToAirportName
            ),
            collectionAddress1: encodeHTMLEntities(
              details.COLLECTIONADDRESS1_BRANCH_ADDRESS
                ? details.COLLECTIONADDRESS1_BRANCH_ADDRESS
                : details.collectionAddress1
            ),
            collectionAddress2: encodeHTMLEntities(
              details.COLLECTIONADDRESS2_BRANCH_ADDRESS
                ? details.COLLECTIONADDRESS2_BRANCH_ADDRESS
                : details.collectionAddress2
            ),
            deliveryAddress1: encodeHTMLEntities(
              details.DELIVERYADDRESS1_BRANCH_ADDRESS
                ? details.DELIVERYADDRESS1_BRANCH_ADDRESS
                : details.deliveryAddress1
            ),
            deliveryAddress2: encodeHTMLEntities(
              details.DELIVERYADDRESS2_BRANCH_ADDRESS
                ? details.DELIVERYADDRESS2_BRANCH_ADDRESS
                : details.deliveryAddress2
            ),
            tripKms: details.tripKms.toString(),
            numberPassengers: details.numberPassengers,
          }
        : selectedData?.payload;

      ApiConsumer.post(
        details.isD2D ? API_ROUTES.DOOR2DOOR.QUOTE : API_ROUTES.BOOKING.QUOTE,
        payload
      )
        .then((res) => {
          setQuote(res.data);
        })
        .catch((err) => {
          Sentry.captureException(err);
        })
        .finally(() => setCalcLoading(true));
    }
  }, [selectedData]);

  useEffect(() => {
    if (isLoaded && selectedGroup.length > 0) {
      let temp = rates?.charges?.filter((el) => {
        return selectedGroup.some((f) => {
          return f === el.vehicleGrp;
        });
      });
      setVehicles(temp ?? []);
      return;
    }
    setRateCode(rates[0]?.rateCode);
    setVehicles(rates[0]?.charges ?? []);
  }, [selectedGroup, rates]);

  useEffect(() => {
    setCalcLoading(true);
  }, [vehicle]);

  useEffect(() => {
    if (
      Object.keys(vehicle).length !== 0 &&
      rates[0]?.rateCode !== null &&
      rates[0]?.rateCode !== undefined
    ) {
      getQuoteCallBack();
    }
  }, [vehicle, rates]);

  useEffect(() => {
    const data = {
      ...selectedData.payload,
      rateCode: rates[0]?.rateCode,
      products: addOnProducts.products,
      pai: addOnProducts.products.includes("PAI") ? "Y" : "N",
      drivers: addOnProducts.drivers,
      additionalDrivers: addOnProducts.additionalDrivers,

      vehicleGroup: vehicle.vehicleGrp,
      cdw: vehicle.selectedWaiver === "CDW" ? "Y" : "N",
      scdw: vehicle.selectedWaiver === "SCDW" ? "Y" : "N",
      tlw: vehicle.selectedWaiver === "CDW" ? "Y" : "N",
      stlw: vehicle.selectedWaiver === "SCDW" ? "Y" : "N",
    };
    setSaveQuote(data);
  }, [addOnProducts]);

  useEffect(() => {
    ApiConsumer.get(page_url)
      .then((res) => {
        //Filter based on
        let namibiaBranches = ["WH", "WB", "KM", "WT"];
        let tmpFleet = namibiaBranches.includes(details?.PICKUP_BRANCH)
          ? res.data.filter((v) => v.slug.includes("namibia") === true)
          : res.data.filter((v) => v.slug.includes("namibia") === false);
        setFleetData(tmpFleet);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [page_url]);

  return (
    <>
      <Modals
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        loginAccountType={loginAccountType}
        setLoginAccountType={setLoginAccountType}
        pagePostModalSlug={pagePostModalSlug}
        setPagePostModalSlug={setPagePostModalSlug}
      />

      {!details.isD2D && (
        <div ref={chooseWaiversRef} className="w-full">
          <div className="grid rounded-lg bg-gray-100 text-sm lg:grid-cols-12 ">
            <div className="col-span-12 lg:col-span-6">
              <div className={`grid gap-2 p-3 lg:grid-cols-12`}>
                <SectionSelectBranch
                  type={CONSTANTS.PICKUP}
                  isVanRental={false}
                />
              </div>
            </div>

            <div className="col-span-12 lg:col-span-6">
              <div className={`grid gap-2 p-3 lg:grid-cols-12`}>
                <SectionSelectBranch
                  type={CONSTANTS.DROPOFF}
                  isVanRental={false}
                />
              </div>
            </div>
            <div className="col-span-12">
              <button
                className="float-right px-3 mr-3 mb-4 ml-2 py-1 border border-transparent rounded-full shadow-sm text-xs text-white bg-blue-400 hover:bg-blue-400 focus:outline-none"
                onClick={() => {
                  setShowRateCode(false);
                  Recalculate();
                }}
              >
                Update
              </button>
              {!isLoaded && (
                <div className="col-span-12 float-right">
                  <Loader />
                </div>
              )}
              <span className="text-red-500 float-right">{errorMessage}</span>
            </div>
          </div>
        </div>
      )}

      <div ref={chooseWaiversRef} className="w-full py-5 lg:w-9/12 ">
        <div className="grid gap-4 lg:grid-cols-12">
          {vehicle?.selectedWaiver && (
            <div className="col-span-12 rounded-lg bg-white">
              <div className="grid lg:grid-cols-12">
                <div className="col-span-12 py-4">
                  <h1 className="float-left text-3xl font-medium w-full">
                    Choose {details.isD2D ? `extras` : `waivers & extras`}
                  </h1>
                  {details.isD2D && (
                    <>
                      <div className="text-blue-900 uppercase">
                        DOOR2DOOR:{" "}
                        {details.accountName
                          ? details.accountName
                          : details.accountNo}
                      </div>
                      <div className="rounded-md bg-gray-100 p-4 my-2 text-sm">
                        <div className="w-1/2 float-left">
                          <p className="font-semibold">
                            PICK-UP -{" "}
                            {details.PICKUP_DATE
                              ? `${getDate(details.PICKUP_DATE)} ${getTime(
                                  details.PICKUP_TIME
                                )}`
                              : details.pickUpDateTime}{" "}
                            ({details.numberPassengers} PASSENGERS)
                          </p>

                          <p>
                            {details.PICKUP_BRANCH_ADDRESS
                              ? details.PICKUP_BRANCH_ADDRESS
                              : details.collectFromAirportName}
                          </p>

                          {details.COLLECTIONADDRESS1_BRANCH_ADDRESS !== null &&
                            details.collectionAddress1 !== null && (
                              <p>
                                <span className="font-semibold">
                                  Pick-up Address 1:
                                </span>{" "}
                                {details.COLLECTIONADDRESS1_BRANCH_ADDRESS
                                  ? details.COLLECTIONADDRESS1_BRANCH_ADDRESS
                                  : details.collectionAddress1}
                              </p>
                            )}
                          {details.COLLECTIONADDRESS2_BRANCH_ADDRESS !== null &&
                            details.collectionAddress2 !== null && (
                              <p>
                                <span className="font-semibold">
                                  Pick-up Address 2:
                                </span>{" "}
                                {details.COLLECTIONADDRESS2_BRANCH_ADDRESS
                                  ? details.COLLECTIONADDRESS2_BRANCH_ADDRESS
                                  : details.collectionAddress2}
                              </p>
                            )}
                        </div>
                        <div className="w-1/2 float-left">
                          <p className="font-semibold">DROP-OFF</p>

                          <p>
                            {details.DROPOFF_BRANCH_ADDRESS
                              ? details.DROPOFF_BRANCH_ADDRESS
                              : details.deliverToAirportName}
                          </p>
                          {details.DELIVERYADDRESS1_BRANCH_ADDRESS !== null &&
                            details.deliveryAddress1 !== null && (
                              <p>
                                <span className="font-semibold">
                                  Delivery Address 1:
                                </span>{" "}
                                {details.DELIVERYADDRESS1_BRANCH_ADDRESS
                                  ? details.DELIVERYADDRESS1_BRANCH_ADDRESS
                                  : details.deliveryAddress1}
                              </p>
                            )}
                          {details.DELIVERYADDRESS2_BRANCH_ADDRESS !== null &&
                            details.deliveryAddress2 !== null && (
                              <p>
                                <span className="font-semibold">
                                  Delivery Address 2:
                                </span>{" "}
                                {details.DELIVERYADDRESS2_BRANCH_ADDRESS
                                  ? details.DELIVERYADDRESS2_BRANCH_ADDRESS
                                  : details.deliveryAddress2}
                              </p>
                            )}
                        </div>
                        <div className="clear-both"></div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="grid border border-gray-100 rounded-lg lg:grid-cols-12">
                <div className="col-span-12 lg:col-span-4">
                  <div className="grid lg:grid-cols-12 ">
                    <VehiclePreview
                      vehicleItem={vehicle}
                      executeChooseVehicleScroll={executeChooseVehicleScroll}
                      size="normal"
                      vehicleData={
                        Object.keys(fleetData).length !== 0
                          ? fleetData.filter(
                              (x) =>
                                x.title.rendered ===
                                "Group " + vehicle.vehicleGrp
                            )
                          : []
                      }
                    />

                    {!details.isD2D ? (
                      <div className="col-span-12 leading-none p-3 z-10 text-center bg-gray-100 rounded-br-lg">
                        <VehicleWaiver
                          vehicleItem={vehicle}
                          waiverType={vehicle?.selectedWaiver}
                        />
                      </div>
                    ) : (
                      Object.keys(user).length === 0 ||
                      (user.accountNo === null && (
                        <div className="col-span-12 leading-none p-3 z-10 text-center bg-gray-100 rounded-br-lg">
                          <D2DVehicleWaiver vehicleItem={vehicle} />
                        </div>
                      ))
                    )}
                  </div>
                </div>

                <AddOns
                  accountType={details.accountType}
                  pickUpBranch={details.PICKUP_BRANCH}
                  dropOffBranch={details.DROPOFF_BRANCH}
                  vehicleGroup={vehicle.vehicleGrp}
                  addOnProducts={addOnProducts}
                  setAddOnProducts={setAddOnProducts}
                  selectedProducts={selectedData?.payload?.products}
                  setAddOnClicked={setAddOnClicked}
                  vehicleData={
                    Object.keys(fleetData).length !== 0
                      ? fleetData.filter(
                          (x) =>
                            x.title.rendered === "Group " + vehicle?.vehicleGrp
                        )
                      : []
                  }
                />
                {vehicle.selectedWaiver === "CDW" && (
                  <div className="col-span-12 bg-blue-900 p-3 text-sm text-white rounded-bl-lg rounded-br-lg">
                    <button
                      type="button"
                      className="float-right ml-2 px-4 py-2 border border-transparent rounded-full shadow-sm text-sm text-gray-900 bg-white hover:bg-blue-400 focus:outline-none"
                      onClick={() => setSelectedVehicle(vehicle, "SCDW")}
                    >
                      <strong>Upgrade to Super Waiver</strong> for an
                      additional: <br />R
                      {vehicle.superCover - vehicle.standardCover} P/day incl.
                      (Liability: R{vehicle.superCoverExcessCDW})
                    </button>
                    If Standard Cover Waiver is selected the liability will be
                    payable in the event of accident damage or theft of the
                    vehicle. By selecting Super Waiver the liability will be
                    reduced.
                    <span
                      onClick={() => {
                        setVisibleModal(CONSTANTS.MODAL_TYPE.PAGEPOST);
                        details.isD2D
                          ? setPagePostModalSlug(
                              "door2door-rental-terms-and-conditions"
                            )
                          : setPagePostModalSlug("rental-terms-and-conditions");
                      }}
                      className="inline-block text-blue-400 hover:underline cursor-pointer"
                    >
                      Please refer to Terms &amp; Conditions
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}

          {((details.isD2D && details?.email === undefined) ||
            !details.isD2D) && (
            <>
              <div ref={chooseVehicleRef} className="col-span-12 py-4">
                <h1 className="text-3xl font-medium">
                  {vehicle.selectedWaiver
                    ? "Change vehicle selection"
                    : "Choose your vehicle"}
                </h1>
                {details.isD2D && (
                  <div className="text-blue-900 uppercase">
                    DOOR2DOOR:{" "}
                    {details.accountName
                      ? details.accountName
                      : details.accountNo}
                  </div>
                )}
                <p>
                  {rates[0]?.rateDesc ?? ""} ({rates[0]?.rateCode ?? ""}){" "}
                  {!details.isD2D && (
                    <span
                      className="text-blue-400 hover:underline cursor-pointer"
                      onClick={() => setShowRateCode(!showRateCode)}
                    >
                      Change Rate Code
                    </span>
                  )}
                </p>

                {showRateCode && (
                  <div className="clear-both">
                    {details.accountType === "I" ? (
                      <input
                        type="text"
                        className="float-left w-40 border border-gray-200 p-2 block my-1 focus:outline-none rounded-full"
                        onChange={(event) => setRateCode(event.target.value)}
                        value={rateCode}
                      />
                    ) : (
                      <select
                        name="RateCode"
                        id="RateCode"
                        defaultValue={rateCode}
                        className="float-left w-50 border border-gray-200 p-2 block my-1 focus:outline-none rounded-full"
                        onChange={(event) => setRateCode(event.target.value)}
                        value={rateCode}
                        placeholder="Select option"
                      >
                        {otherRateCodes?.map((rate, i) => (
                          <option key={i} value={rate.rateCode}>
                            ({rate.rateCode}) {rate.rateDesc}
                          </option>
                        ))}
                      </select>
                    )}

                    <button
                      type="button"
                      disabled={!isLoaded}
                      onClick={() => Recalculate()}
                      className={`float-left px-4 m-1 py-1 border border-transparent rounded-full shadow-sm text-lg text-white ${
                        !isLoaded ? `bg-gray-300` : `bg-blue-400`
                      } focus:outline-none`}
                    >
                      Update
                    </button>
                    {!isLoaded && (
                      <div className="col-span-12 float-right">
                        <Loader />
                      </div>
                    )}
                    <span className="text-red-500 float-right">
                      {errorMessage}
                    </span>
                  </div>
                )}

                <div className="clear-both" />
                {vehicles.length === 0 ? (
                  <div className="col-span-12 p-4 bg-gray-100 rounded-lg my-4">
                    {errorMessage}
                  </div>
                ) : (
                  <div>
                    Please select a vehicle group and applicable waiver cover by
                    clicking on the waiver you wish to take.
                  </div>
                )}
              </div>
              {isLoaded ? (
                vehicles.map((vehicleItem, index) => (
                  <>
                    {details.isD2D ? (
                      Object.keys(fleetData).length !== 0 &&
                      parseInt(details.numberPassengers) <=
                        parseInt(
                          fleetData?.filter(
                            (x) =>
                              x.title.rendered ===
                              "Group " + vehicleItem.vehicleGrp
                          )[0]?.acf.pax_seating
                        ) && (
                        <div
                          key={index}
                          className={`col-span-12 rounded-lg bg-white lg:col-span-4`}
                        >
                          <VehicleItem
                            vehicleItem={vehicleItem}
                            setSelectedVehicle={setSelectedVehicle}
                            executeChooseVehicleScroll={
                              executeChooseVehicleScroll
                            }
                            vehicleData={
                              Object.keys(fleetData).length !== 0
                                ? fleetData.filter(
                                    (x) =>
                                      x.title.rendered ===
                                      "Group " + vehicleItem.vehicleGrp
                                  )
                                : []
                            }
                          />
                        </div>
                      )
                    ) : (
                      <div
                        key={index}
                        className={`col-span-12 rounded-lg bg-white lg:col-span-4`}
                      >
                        <VehicleItem
                          vehicleItem={vehicleItem}
                          setSelectedVehicle={setSelectedVehicle}
                          executeChooseVehicleScroll={
                            executeChooseVehicleScroll
                          }
                          vehicleData={
                            Object.keys(fleetData).length !== 0
                              ? fleetData.filter(
                                  (x) =>
                                    x.title.rendered ===
                                    "Group " + vehicleItem.vehicleGrp
                                )
                              : []
                          }
                        />
                      </div>
                    )}
                  </>
                ))
              ) : (
                <div className="text-center p-2 col-span-12">
                  <Loader />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <VehicleCostsSidebar
        loading={calcLoading}
        bookingErrorMessage={errorMessage}
        addOnClicked={addOnClicked}
        setSelectedVehicle={setSelectedVehicle}
        isRecalcError={isRecalcError}
      />
    </>
  );
};
export default Availability;
