import {BrowserRouter as Router, Switch, Route, useHistory} from "react-router-dom";
import HomePage from "pages/home";
import GetQuotePage from "pages/get-quote";
import ManageBookingPage from "pages/manage-booking";
import UserProfilePage from "pages/user-profile";
import PasswordResetPage from "pages/password-reset";
import LoginPage from "pages/login";
import AvailabilityPage from "pages/availability";
import ConfirmationDetailsPage from "pages/confirmation-details";
import BookingConfirmationPage from "pages/booking-confirmation";
import Fleet from "pages/fleet";
import Branches from "pages/branches";
import {useEffect, useState} from "react";
import {QuoteContext, SaveQuoteContext} from "contexts/quote";
import {VehicleContext} from "contexts/vehicle";
import Page from "pages/page";
import NotFound from "pages/not-found";
import {BranchContext} from "contexts/branch";
import {baseDetailsObj, DetailsContext} from "contexts/details";
import {UserContext} from "contexts/user";
import CookieConsent from "react-cookie-consent";
import PageViews from "components/page-views";

function App() {

    const [quote, setQuote] = useState({});
    const [details, setDetails] = useState({});
    const [saveQuote, setSaveQuote] = useState({});
    const [vehicle, setVehicle] = useState({});
    const [branches, setBranches] = useState([]);
    const [user, setUser] = useState({});

    let history = useHistory();

    const urlSearchParams = new URLSearchParams(window.location.search)
    useEffect(() => {
        if (Object.keys(details).length === 0 && !Array.from(urlSearchParams.entries()).length)
            setDetails(baseDetailsObj)
    }, [urlSearchParams])

    useEffect(() => {
        if (sessionStorage.getItem('sessionUser') !== null && sessionStorage.getItem('sessionUser') !== undefined) {
            let userData = JSON.parse(sessionStorage.getItem('sessionUser'))
            setDetails(prevState => ({
                ...prevState,
                [`accountNo`]: userData.accountNo
            }))
            setDetails(prevState => ({
                ...prevState,
                [`accountType`]: userData.accountType
            }))
            setUser(userData)
        }
    }, [])

    return (
        <UserContext.Provider value={{user, setUser}}>
            <DetailsContext.Provider value={{details, setDetails}}>
                <QuoteContext.Provider value={{quote, setQuote}}>
                    <SaveQuoteContext.Provider value={{saveQuote, setSaveQuote}}>
                        <VehicleContext.Provider value={{vehicle, setVehicle}}>
                            <BranchContext.Provider value={{branches, setBranches}}>
                                <Router history={history}>
                                    <PageViews/>
                                    <Switch>
                                        {/* CUSTOM PAGES */}
                                        <Route exact path="/" component={HomePage}/>
                                        <Route exact path="/login/:validHash?/:validCode?" component={LoginPage}/>
                                        <Route exact path="/get-quote" component={GetQuotePage}/>
                                        <Route path="/get-quote/*" component={GetQuotePage}/>
                                        <Route exact path="/availability" component={AvailabilityPage}/>
                                        <Route exact path="/confirmation-details"
                                               component={ConfirmationDetailsPage}/>
                                        <Route exact path="/booking-confirmation"
                                               component={BookingConfirmationPage}/>
                                        <Route exact path="/manage-booking" component={ManageBookingPage}/>
                                        <Route path="/manage-booking/*" component={ManageBookingPage}/>
                                        <Route exact path="/profile" component={UserProfilePage}/>
                                        <Route exact path="/password-reset/:txtU/:txtC+"
                                               component={PasswordResetPage}/>
                                        <Route exact path="/fleet/:slug?" component={Fleet}/>
                                        <Route path="/fleet/*" component={Fleet}/>
                                        <Route exact path="/branches/:slug?" component={Branches}/>
                                        <Route path="/branches/*" component={Branches}/>
                                        {/* CMS PAGES */}
                                        <Route exact path="/404" component={NotFound}/>
                                        <Route exact path="/:slug+" component={Page}/>
                                        <Route path="/:slug+/*" component={Page}/>
                                        <Route path="/*" component={HomePage}/>
                                    </Switch>
                                </Router>
                                <CookieConsent
                                    location="bottom"
                                    buttonText="Accept & Continue"
                                    cookieName="bcrCookieConsent"
                                    style={{background: "#0e0a4a"}}
                                    buttonStyle={{color: "#ffffff", fontSize: "14px", background: "#00aeef"}}
                                    expires={150}
                                >
                                    Our site uses cookies to enhance the user experience and improve functionality.
                                    Accept & continue if you are happy
                                    with this or <a href="https://www.bluucarrental.com/privacy-policy/"
                                                    style={{textDecoration: "underline"}}>find out more</a> on how we
                                    use cookies.
                                </CookieConsent>
                            </BranchContext.Provider>
                        </VehicleContext.Provider>
                    </SaveQuoteContext.Provider>
                </QuoteContext.Provider>
            </DetailsContext.Provider>
        </UserContext.Provider>
    );
}

export default App;
