import GetQuote from 'components/get-quote/'
import Header from 'components/header'
import Footer from 'components/footer'
import { useContext, useEffect } from 'react'
import { UserContext } from 'contexts/user'
import { Helmet } from 'react-helmet'

const GetQuotePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let { user } = useContext(UserContext)

  return (
    <>
      <Helmet>
        <title>Book a Vehicle | Bluu Car Rental</title>
      </Helmet>

      <Header />

      <div className="w-full bg-gray-200 text-center">
        <div className="container m-auto py-10 max-w-screen-xl">
          <div className="col-span-12 ">
            <h1 className="text-3xl lg:text-5xl px-4 lg:p-6 text-blue-900">
              {Object.keys(user).length === 0
                ? 'Book a Vehicle'
                : user.accountName}
            </h1>
          </div>
        </div>
      </div>

      <div className="relative bg-gray-200 pb-20 px-4 ">
        <div className="mx-auto lg:w-6/12">
          <div className="grid lg:grid-cols-12 gap-4">
            <div className="col-span-12 ">
              <GetQuote activeTab={1} />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}
export default GetQuotePage
