function convertDateToString(date) {
    return new Date(date).toLocaleDateString('en-ZA')
}

function getFullMonth(date) {
    return new Date(date).toLocaleString('default', {month: 'long'});
}

function getDate(date) {
    const d = new Date(date)
    let month =  d.getMonth() + 1
    let day = d.getDate()
    return `${d.getFullYear()}/${month < 10 ? '0' + month.toString() : month}/${day < 10 ? '0' + day.toString() : day}`
}

function formatPhoneNumber(phoneNumberString) {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        let intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, match[2], ' ', match[3], ' ', match[4]].join('');
    }
    return null;
}

function getDateTime(date, time) {
    const d = new Date(date)
    const t = new Date(time)
    let month =  d.getMonth() + 1
    let day = d.getDate()

    return `${d.getFullYear()}/${month < 10 ? '0' + month.toString() : month}/${day < 10 ? '0' + day.toString() : day} ${t.getHours() < 10 ? '0' + t.getHours().toString(): t.getHours()}:${t.getMinutes() < 10 ? '0' + t.getMinutes().toString(): t.getMinutes()}`
}

function getTime(date) {
    const t = new Date(date)
    return `${t.getHours() < 10 ? '0' + t.getHours().toString(): t.getHours()}:${t.getMinutes() < 10 ? '0' + t.getMinutes().toString(): t.getMinutes()}`
}

function getBranchById(branches, id){
    if (id===null || id===undefined)
        return '';
    return branches.filter(x=> x.branchCode === id)[0]
}

function formatDate(date){
    let cleaned = ('' + date).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{4})(\d{2})(\d{2})$/);
    if (match) {
        return [match[2], '/', match[3], '/', match[4]].join('');
    }
    return null;
}

function formatTime(time){
    if(time.length < 4){
        time = `0${time}`
    }
    return time.substring(0, 2) + ":" + time.substring(2, time.length);
}

function validEmail(value) {
    var emailFilter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return emailFilter.test(value);
}

function validAlphanumeric(value) {
    //contain letters and numbers only, underscore, space or dash
	let alphanumeric = /^[a-zA-Z0-9_]+$/;
	return alphanumeric.test(value);
}

function validNumeric(value) {
    let numeric = /^[0-9]+$/;
    return numeric.test(value);
}

function validContact(value){
    let contact = /^\+?[0-9]+$/;
	return contact.test(value);
}

function validDate(value){
    let date = /^([0-9]{4})\-([0-9]{2})\-([0-9]{2})$/;
	return date.test(value);
}

function validExpiryDate(value){
    let date = /^(202[0-9])(0[1-9]|1[0-2])$/;
	return date.test(value);
}

function validWebsite(value) {
	var websiteFilter = /^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})$/i;
	return websiteFilter.test(value);
}

function getLocation() {
    if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition(showPosition,
        function (error) {
          if (error.code === error.PERMISSION_DENIED)
            console.log("Geolocation PERMISSION_DENIED.");
        });
    } else {
       console.log("Geolocation is not supported by this browser.");
    }
}

function showPosition(position) {
    //process nearest branch
    var lat = position.coords.latitude;
    var long = position.coords.longitude;
}

function encodeHTMLEntities(text) {
  let textArea = document.createElement('textarea');
  textArea.innerText = text;
  let encodedOutput=textArea.innerHTML;
  let arr=encodedOutput.split('<br>');
  encodedOutput=arr.join('\n');
  return (text===undefined || text===null)? text : encodedOutput;
}

export const useUtils = () => {
    return {convertDateToString, formatPhoneNumber, getFullMonth, getDate, getDateTime, getTime, getBranchById, formatDate, formatTime, validEmail, validAlphanumeric, validNumeric, validContact, validDate, validExpiryDate, validWebsite, encodeHTMLEntities}
}
