const VehicleDetails = ({vehicleData}) => {
 
    return <>
    {vehicleData[0]?.acf.luggage_capacity &&
    <div className="py-1 w-full  float-left mr-1 mb-1">{vehicleData[0]?.acf.luggage_capacity}</div> }

    {vehicleData[0]?.acf.pax_seating &&
    <div className="py-1 w-3/6 float-left">Pax Seating: {vehicleData[0]?.acf.pax_seating}</div> }

    {vehicleData[0]?.acf.safety_belts &&
    <div className="py-1 w-3/6 float-left">Safety Belts: {vehicleData[0]?.acf.safety_belts}</div> }

    {vehicleData[0]?.acf.vehicle_features.map((feature, i) => 
        <div key={i} className="py-1 w-3/6 float-left">{feature}</div>
    )}
    {vehicleData[0]?.acf.licence_code &&
    <div className="py-1 w-3/6 float-left">Licence Code: {vehicleData[0]?.acf.licence_code}</div> }

    {vehicleData[0]?.acf.load_box &&
    <div className="py-1 w-3/6 float-left">Load Box: {vehicleData[0]?.acf.load_box}</div> }

    {vehicleData[0]?.acf.tank_capacity &&
    <div className="py-1 w-3/6 float-left">Tank Capacity: {vehicleData[0]?.acf.tank_capacity}</div>}
    </>
}
export default VehicleDetails;
