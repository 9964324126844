import { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCar,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { add, isAfter, isEqual } from "date-fns";
import Select, { createFilter } from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { DetailsContext } from "contexts/details";
import { CONSTANTS } from "contants/constants";
import { BranchContext } from "contexts/branch";
import "@fortawesome/fontawesome-free/css/all.min.css";

const SectionSelectBranch = ({ type, isVanRental }) => {
  const { details, setDetails } = useContext(DetailsContext);
  const { branches } = useContext(BranchContext);

  const openDateRef = useRef(null);
  const openTimeRef = useRef(null);
  const openCalendarDate = () => {
    openDateRef.current.setOpen(true);
  };
  const openCalendarTime = () => {
    openTimeRef.current.setOpen(true);
  };
  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      height: "3rem",
      zIndex: 100000,
      paddingLeft: 25,
      borderRadius: "9999px",
      backgroundColor: "transparent",
      boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    }),
  };

  function LoadOptions() {
    let vanSelected = []; //Check if selected is van or car
    vanSelected = branches.filter(
      (branch) =>
        details[`${type.toUpperCase().replace("-", "")}_BRANCH`] ===
          branch.branchCode && branch.isVanRental === true
    );
    let filterVanCar = []; //Filter by selection
    filterVanCar =
      vanSelected.length || isVanRental
        ? branches.filter(
            (branch) =>
              branch.isVanRental === true && branch.isVanRental === true
          )
        : branches.filter((branch) => branch.isVanRental === false);
    let temp = []; //Load the options
    filterVanCar.map((branch) =>
      temp.push({ value: branch.branchCode, label: branch.branchName })
    );
    return temp;
  }

  useEffect(() => {
    //Modify Booking
    if (
      details[
        `${(type.charAt(0).toLowerCase() + type.slice(1)).replace(
          "-",
          ""
        )}DateTime`
      ] !== undefined
    ) {
      setDetails((prevState) => ({
        ...prevState,
        [`${type.toUpperCase().replace("-", "")}_BRANCH`]:
          details[
            `${(type.charAt(0).toLowerCase() + type.slice(1)).replace(
              "-",
              ""
            )}Branch`
          ],
        [`${type.toUpperCase().replace("-", "")}_DATE`]: new Date(
          details[
            `${(type.charAt(0).toLowerCase() + type.slice(1)).replace(
              "-",
              ""
            )}DateTime`
          ]
        ),
        [`${type.toUpperCase().replace("-", "")}_TIME`]: new Date(
          details[
            `${(type.charAt(0).toLowerCase() + type.slice(1)).replace(
              "-",
              ""
            )}DateTime`
          ]
        ),
        RESIDENCE: details.foreignLocal,
        accountNo: details.accountNo,
        accountName: details.accountName,
        accountType: details.accountType,
      }));
    }
  }, []);

  return (
    <>
      <div className="col-span-12 text-left font-bold text-blue-900">
        SELECT {type.toUpperCase()} LOCATION
      </div>
      <div className="col-span-12 relative text-base shadow-sm text-gray-700 bg-white border-gray-300 focus:outline-none rounded-full lg:col-span-6">
        <FontAwesomeIcon icon={faCar} className="style-quote-icons z-10" />
        <Select
          id="location"
          name="location"
          className={`p-0 ${type === CONSTANTS.PICKUP ? `z-30` : `z-20`} `}
          classNamePrefix="select-location"
          defaultValue={0}
          filterOption={createFilter({ matchFrom: "start" })}
          options={LoadOptions()}
          onChange={(event) => {
            setDetails((prevState) => ({
              ...prevState,
              [`${type.toUpperCase().replace("-", "")}_BRANCH`]: event.value,
            }));
            if (
              type.toUpperCase().replace("-", "").toUpperCase() ===
              CONSTANTS.PICKUP.replace("-", "").toUpperCase()
            ) {
              setDetails((prevState) => ({
                //Change drop-off to pick-up
                ...prevState,
                [`DROPOFF_BRANCH`]: event.value,
              }));
            }
          }}
          styles={customStyles}
          value={
            LoadOptions()[
              LoadOptions().findIndex(
                (option) =>
                  option.value ===
                  (details[`${type.toUpperCase().replace("-", "")}_BRANCH`] ??
                    "")
              )
            ]
          }
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#f3f4f6",
              primary: "#0018a2",
            },
          })}
        ></Select>
      </div>

      <div className="col-span-8 relative text-base lg:col-span-4">
        <DatePicker
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          ref={openDateRef}
          minDate={
            type === CONSTANTS.PICKUP
              ? new Date()
              : new Date(details.PICKUP_DATE)
          }
          dateFormat="iii dd MMM yy"
          selected={details[`${type.toUpperCase().replace("-", "")}_DATE`]}
          onChange={(event) => {
            setDetails((prevState) => ({
              ...prevState,
              [`${type.toUpperCase().replace("-", "")}_DATE`]: event,
            }));
            if (
              type.toUpperCase().replace("-", "").toUpperCase() ===
              CONSTANTS.PICKUP.replace("-", "").toUpperCase()
            ) {
              if (isAfter(add(event, { days: 2 }), details?.DROPOFF_DATE))
                setDetails((prevState) => ({
                  ...prevState,
                  [`DROPOFF_DATE`]: add(event, { days: 2 }),
                }));
            }
          }}
          className="style-quote-input"
        />
        <FontAwesomeIcon
          icon={faCalendarAlt}
          className="style-quote-icons z-10"
          onClick={() => openCalendarDate()}
        />
      </div>
      <div className="col-span-4 relative text-base lg:col-span-2">
        <DatePicker
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          ref={openTimeRef}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          timeFormat="HH:mm"
          dateFormat="HH:mm"
          selected={details[`${type.toUpperCase().replace("-", "")}_TIME`]}
          onChange={(event) => {
            setDetails((prevState) => ({
              ...prevState,
              [`${type.toUpperCase().replace("-", "")}_TIME`]: event.getTime(),
            }));
          }}
          className="style-quote-input"
        />
        <FontAwesomeIcon
          icon={faClock}
          className="style-quote-icons z-10"
          onClick={() => openCalendarTime()}
        />
      </div>
      {/* <AlternativeBranches type={type}/> */}
    </>
  );
};
export default SectionSelectBranch;
