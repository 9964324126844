const API_VERSION = "v2"
const API_BASE_URL = `https://cms.bluucarrental.com/wp-json/wp/${API_VERSION}/`

export const CMS_ROUTES = {
    SPECIALS: `https://cms.bluucarrental.com/wp-json/wp/v2/specials`,

    POST_PAGE: function (type, slug) {
        return `${API_BASE_URL}${type}?slug=${slug}`
    },
    FLEET: {
        ALL: 'https://cms.bluucarrental.com/wp-json/wp/v2/fleet?per_page=100',
        CATEGORIES: `https://cms.bluucarrental.com/wp-json/wp/v2/fleet_categories`,
        BY_CATEGORY: function (category_id) {
            return `https://cms.bluucarrental.com/wp-json/wp/v2/fleet?fleet_categories=${category_id}&per_page=100`
        }
    },
    BRANCHES: {
        CATEGORIES: "https://cms.bluucarrental.com/wp-json/wp/v2/branches_categories",
        BY_CATEGORY: function (category_id) {
            return `https://cms.bluucarrental.com/wp-json/wp/v2/branches?branches_categories=" + ${category_id} + "&per_page=100`
        },
        BY_SLUG: function (slug) {
            return `https://cms.bluucarrental.com/wp-json/wp/v2/branches?slug=${slug}`
        }
    }
}
