import { useContext, useEffect, useState } from 'react'
import { QuoteContext, SaveQuoteContext } from 'contexts/quote'
import { DetailsContext } from 'contexts/details'

const AddOnsProduct = ({
  product,
  addOnProducts,
  setAddOnProducts,
  setAddOnClicked,
}) => {
  const [checked, setChecked] = useState(false)
  let { saveQuote } = useContext(SaveQuoteContext)
  let { details } = useContext(DetailsContext)

  const updateAddOnProducts = (selectedProduct, productDescription) => {
    let mergedObj = []

    if (
      addOnProducts.products.filter((e) => e.productCode === selectedProduct)
        .length === 0
    ) {
      mergedObj = [
        ...addOnProducts.products,
        {
          productCode: selectedProduct,
          productDescription: productDescription,
        },
      ]
      //Check additional drivers and set to 1
      if (selectedProduct === 'ADW' && addOnProducts.additionalDrivers === 0)
        addOnProducts.additionalDrivers = 1
    } else {
      //Remove if exists
      mergedObj = addOnProducts.products.filter(
        (e) => e.productCode !== selectedProduct,
      )
      //Check additional drivers and set to 0
      if (selectedProduct === 'ADW' && addOnProducts.additionalDrivers > 0)
        addOnProducts.additionalDrivers = 0
    }

    let branchObj = { ...addOnProducts, products: mergedObj }

    setAddOnProducts(branchObj)
  }

  const updateAdditionalDrivers = (amount) => {
    addOnProducts.additionalDrivers = parseInt(amount)
    setAddOnProducts({ ...addOnProducts })
    setAddOnClicked(true)
  }

  useEffect(() => {
    if (saveQuote.products?.length) {
      if (
        saveQuote.products.filter((x) => x.productCode === product.productCode)
          .length > 0
      )
        setChecked(true)
      else setChecked(false)
    } else setChecked(false)
  }, [saveQuote])

  return (
    <>
      <label className="style-addons-checkbox">
        <input
          type="checkbox"
          disabled={
            details.isD2D
              ? details?.email === undefined
                ? false
                : true
              : false
          }
          name={product.productType}
          value={product.productCode}
          className="h-4 w-4 mt-0.5 cursor-pointer"
          aria-labelledby={product.productCode}
          checked={checked}
          onClick={(e) => {
            updateAddOnProducts(e.target.value, product.productDescription)
            setAddOnClicked(true)
          }}
        />
        <span id={product.productCode} className="ml-1">
          {product.productDescription}
        </span>
      </label>
      {checked &&
      product.productCode === 'ADW' &&
      addOnProducts.additionalDrivers ? (
        <select
          id="location"
          name="location"
          className="float-left text-gray-700 bg-white border border-gray-300 p-1 focus:outline-none rounded-full"
          onChange={(e) => updateAdditionalDrivers(e.target.value)}
          defaultValue="1"
          value={checked && addOnProducts.additionalDrivers}
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
        </select>
      ) : null}
    </>
  )
}
export default AddOnsProduct
