import {Fragment, useEffect, useState} from "react";
import {ApiConsumer} from "api/ApiConsumer";
import * as Sentry from "@sentry/react";
import {Dialog, Transition} from '@headlessui/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle, faCaretRight} from '@fortawesome/free-solid-svg-icons';
import {CONSTANTS} from "contants/constants";
import Loader from "components/loader";

export default function PagePostModal({setVisibleModal,pagePostModalSlug,setPagePostModalSlug}) {

    const [pageContent,setPageContent] = useState('');
    const [pageTitle,setPageTitle] = useState('');
    const [loaded,setLoaded] = useState(false);
    
    useEffect(() => {

        const pageUrl = `https://cms.bluucarrental.com/wp-json/wp/v2/pages?slug=${pagePostModalSlug}`;
        
        ApiConsumer.get(pageUrl)
            .then(res => {
                setPageContent(res.data[0].content.rendered);
                setPageTitle(res.data[0].title.rendered)
            })
            .catch(err => {
                Sentry.captureException(err)
            })
            .finally(() => {
                setLoaded(true)
            })
    }, [pagePostModalSlug])

    return <>
        {loaded?
        
            <div id="modal">
                <h3 className="text-xl leading-8 font-medium text-gray-900 mb-5" id="modal-title">
                    <div dangerouslySetInnerHTML={{__html: pageTitle}} />
                </h3>
                <div className="mt-2">
                    {pagePostModalSlug==='terms-and-conditions' &&
                    <div className="text-blue-900 bg-gray-100 p-4 mb-4 rounded-md font-semibold " >For Rental Terms and Conditions please <span className="text-blue-400 hover:underline cursor-pointer" 
                    onClick={()=>{
                        setVisibleModal(CONSTANTS.MODAL_TYPE.PAGEPOST)
                        setPagePostModalSlug('rental-terms-and-conditions')
                    }} >click here</span>.</div>
                    }

                    {pagePostModalSlug==='rental-terms-and-conditions' &&
                    <div className="text-blue-900 bg-gray-100 p-4 mb-4 rounded-md font-semibold " >For Website Terms and Conditions please <span className="text-blue-400 hover:underline cursor-pointer" 
                    onClick={()=>{
                        setVisibleModal(CONSTANTS.MODAL_TYPE.PAGEPOST)
                        setPagePostModalSlug('terms-and-conditions')
                    }} >click here</span>.</div>
                    }
                    <p className="text-sm text-gray-500">
                    <div dangerouslySetInnerHTML={{__html: pageContent}} />
                    </p>
                </div>
            </div>

        :
            <div className="mt-2 text-center "><Loader/></div>
        } 

    </>
}
