import {useEffect} from "react";
import Availability from "components/availability/";
import {useLocation} from "react-router-dom";
import Header from "components/header";
import Footer from "components/footer";
import {Helmet} from "react-helmet";

const AvailabilityPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    let location = useLocation()

    return <>
        <Helmet>
            <title>Availability | Bluu Car Rental</title>
        </Helmet>
        <Header />
        <div className="container m-auto py-8 px-4">
            <div className="flex flex-wrap">
                <Availability selectedData={location.state}/>
            </div>
        </div>
        <Footer />
    </>

}
export default AvailabilityPage;
