const API_VERSION = "v2";
const API_BASE_URL = `https://api.bluucarrental.com`;

export const API_ROUTES = {
  BRANCHES: {
    ALL: `${API_BASE_URL}/Branches`,
    DETAIL: function (branchCode) {
      return `${API_BASE_URL}/Branches/${branchCode}`;
    },
  },
  BOOKING: {
    AVAILABILITY: `${API_BASE_URL}/Booking/availability`,
    BOOK: `${API_BASE_URL}/Booking/booking`,
    CANCELLED: `${API_BASE_URL}/Booking/cancel`,
    PRODUCTS: function (accountType, pickUpBranch, vehicleGroup) {
      return `${API_BASE_URL}/Product/additional/${accountType}/${pickUpBranch}/${vehicleGroup}`;
    },
    QUOTE: `${API_BASE_URL}/Quote`,
    STATUS: function (bookingId) {
      return `${API_BASE_URL}/Booking/status/${bookingId}`;
    },
    RESERVATION: function (reservationId) {
      return `${API_BASE_URL}/Booking/reservation/${reservationId}`;
    },
    BOOKING: function (bookingId) {
      return `${API_BASE_URL}/Booking/booking/${bookingId}`;
    },
    MANAGE: function (quoteNo, email) {
      return `${API_BASE_URL}/Booking/manage/${quoteNo}/${email}`;
    },
    USER: function (email) {
      return `${API_BASE_URL}/Booking/user/${email}`;
    },
  },
  CUSTOMER: {
    ACCOUNTS: `${API_BASE_URL}/Customer/accounts`,
    INFORMATION: `${API_BASE_URL}/Customer/information`,
    VOUCHER: function (accountNo) {
      return `${API_BASE_URL}/Customer/voucher/${accountNo}`;
    },
  },
  QUOTE: {
    SAVE: `${API_BASE_URL}/Quote/save`,
    MODIFY: function (quoteNo, email) {
      return `${API_BASE_URL}/Quote/${quoteNo}/${email}`;
    },
    QUOTE: function (quoteNo, email) {
      return `${API_BASE_URL}/Quote/${quoteNo}/${email}`;
    },
    USER: function (email) {
      return `${API_BASE_URL}/Quote/user/${email}`;
    },
    REMINDER: `${API_BASE_URL}/Quote/reminder`,
  },
  DOOR2DOOR: {
    SAVE: `${API_BASE_URL}/Quote/d2d/save`,
    MODIFY: function (quoteNo, email) {
      return `${API_BASE_URL}/Quote/d2d/${quoteNo}/${email}`;
    },
    AVAILABILITY: `${API_BASE_URL}/Booking/d2d/availability`,
    RATES: {
      ALL: `${API_BASE_URL}/Booking/d2d/rates`,
      BY_ACCOUNT: function (accountId) {
        return `${API_BASE_URL}/Booking/d2d/rates/${accountId}`;
      },
    },
    QUOTE: `${API_BASE_URL}/Quote/d2d`,
    BOOK: `${API_BASE_URL}/Booking/d2d/booking`,
    RETRIEVEQUOTE: function (quoteNo, email) {
      return `${API_BASE_URL}/Quote/d2d/${quoteNo}/${email}`;
    },
  },
};
