import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Navigation from "components/confirmation-details/navigation";
import Modals from "components/modals/index";
import { CONSTANTS } from "contants/constants";
import { useState, useRef, useContext, useEffect } from "react";
import { VehicleContext } from "contexts/vehicle";
import { QuoteContext, SaveQuoteContext } from "contexts/quote";
import VehicleCostsSidebar from "components/availability/vehicle-costs-sidebar";
import VehiclePreview from "components/availability/vehicle-preview";
import PaymentDetails from "components/confirmation-details/payment-details";
import { ApiConsumer } from "api/ApiConsumer";
import { API_ROUTES } from "routes/api";
import Loader from "components/loader";
import BookingConfirmation from "components/confirmation-details/booking-confirmation";
import * as Sentry from "@sentry/react";
import { useUtils } from "hooks/utils";
import { DetailsContext } from "contexts/details";
import { BranchContext } from "contexts/branch";

const ConfirmationDetails = () => {
  const [profileLoading, setProfileLoading] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState({});

  const [visibleModal, setVisibleModal] = useState("");
  const [pagePostModalSlug, setPagePostModalSlug] = useState("");
  const [loginAccountType, setLoginAccountType] = useState(
    CONSTANTS.LOGIN_ACCOUNT_TYPE.CORPORATE
  );
  const [confirmation, setConfirmation] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let { quote } = useContext(QuoteContext);
  let { vehicle } = useContext(VehicleContext);
  let { saveQuote } = useContext(SaveQuoteContext);
  let { details, setDetails } = useContext(DetailsContext);
  const { branches } = useContext(BranchContext);
  let userData = JSON.parse(sessionStorage.getItem("sessionUser"));

  const [progressBar, setProgressBar] = useState(1);

  //Set scroller
  const additionalInfoRef = useRef(null);
  const executeAdditionalInfoScroll = () => {
    additionalInfoRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  const [validCustomerDetails, setValidCustomerDetails] = useState({});
  const [validPaymentDetails, setValidPaymentDetails] = useState({});
  const [termsChecked, setTermsChecked] = useState(false);
  let {
    validEmail,
    validAlphanumeric,
    validNumeric,
    validExpiryDate,
    validContact,
    encodeHTMLEntities,
  } = useUtils();

  const validateCustomerDetails = () => {
    let invalidFieldFound = false;
    setError("");

    const formFields = !details.isD2D
      ? [
          { name: "title", displayName: "Title" },
          { name: "contactName", displayName: "First name" },
          { name: "surname", displayName: "Last name" },
          { name: "iD_Type", displayName: "ID type" },
          { name: "renterID", displayName: "ID/Passport number" },
          { name: "contactPhoneNo", displayName: "Contact number" },
          { name: "renterEmail", displayName: "Email address" },
          {
            name: "renterDrivingLicenseCode",
            displayName: "Driving License Code",
          },
          {
            name: "consent",
            displayName:
              "you have read and accept the Terms & Conditions of Rental",
          },
        ]
      : [
          { name: "title", displayName: "Title" },
          { name: "contactName", displayName: "First name" },
          { name: "surname", displayName: "Last name" },
          { name: "contactPhoneNo", displayName: "Contact number" },
          { name: "renterEmail", displayName: "Email address" },
          {
            name: "renterDrivingLicenseCode",
            displayName: "Driving License Code",
          },
          {
            name: "consent",
            displayName:
              "you have read and accept the Terms & Conditions of Rental",
          },
        ];

    formFields.map((field, i) => {
      if (
        !invalidFieldFound &&
        (confirmationDetails[field.name] === undefined ||
          confirmationDetails[field.name] === null ||
          confirmationDetails[field.name].length === 0 ||
          (typeof confirmationDetails[field.name] === "string" &&
            confirmationDetails[field.name].trim() === "") ||
          confirmationDetails[field.name] === "selected")
      ) {
        if (
          field.name === "renterID" &&
          confirmationDetails["iD_Type"] === "PASS"
        )
          return false;

        setValidCustomerDetails((prevState) => ({
          ...prevState,
          [field.name]: true,
        }));

        if (
          field.name === "title" ||
          field.name === "iD_Type" ||
          field.name === "renterDrivingLicenseCode" ||
          confirmationDetails[field.name] === "selected"
        )
          setError("Please select " + field.displayName + ".");
        else if (field.name === "consent" && !termsChecked)
          setError("Please confirm " + field.displayName + ".");
        else setError("Please enter your " + field.displayName + ".");

        invalidFieldFound = true;
      } else if (
        !invalidFieldFound &&
        ((field.name === "renterID" &&
          confirmationDetails[field.name].length &&
          !validAlphanumeric(confirmationDetails[field.name])) ||
          (field.name === "renterID" &&
            confirmationDetails["iD_Type"] === "SAID" &&
            (confirmationDetails["renterID"].length !== 13 ||
              !validNumeric(confirmationDetails["renterID"]))) ||
          (field.name === "contactPhoneNo" &&
            confirmationDetails[field.name].length &&
            !validContact(confirmationDetails[field.name])) ||
          (field.name === "renterEmail" &&
            confirmationDetails[field.name].length &&
            !validEmail(confirmationDetails[field.name])))
      ) {
        setValidCustomerDetails((prevState) => ({
          ...prevState,
          [field.name]: true,
        }));
        setError("Please enter a valid  " + field.displayName + ".");
        invalidFieldFound = true;
      } else {
        setValidCustomerDetails((prevState) => ({
          ...prevState,
          [field.name]: false,
        }));
      }
    });

    if (invalidFieldFound) return false;
    else {
      setProgressBar(2);
      window.scrollTo(0, 0);
    }
  };

  const validatePaymentDetails = () => {
    let invalidFieldFound = false;
    setError("");

    const formFields = !details.isD2D
      ? [
          { name: "voucherNo", displayName: "Voucher number" },
          { name: "moP_Type", displayName: "Method of payment" },
          { name: "invoiceAddress1", displayName: "Billing address" },
          { name: "invoiceAddress2", displayName: "full Billing address" },
          { name: "invoicePostCode", displayName: "Postal code" },
          { name: "collectionAddress1", displayName: "Collection address" },
          {
            name: "collectionAddress2",
            displayName: "full Collection address",
          },
          { name: "collectPhoneNo", displayName: "Collection phone number" },
          { name: "deliveryAddress1", displayName: "Delivery address" },
          { name: "deliveryAddress2", displayName: "full Delivery address" },
          { name: "deliverPhoneNo", displayName: "Delivery phone number" },
        ]
      : [];

    formFields.map((field, i) => {
      if (
        !invalidFieldFound &&
        (confirmationDetails[field.name] === undefined ||
          confirmationDetails[field.name] === null ||
          confirmationDetails[field.name].length === 0 ||
          (typeof confirmationDetails[field.name] === "string" &&
            confirmationDetails[field.name].trim() === "") ||
          confirmationDetails[field.name] === "selected")
      ) {
        if (
          (field.name === "collectionAddress1" ||
            field.name === "collectionAddress2" ||
            field.name === "collectPhoneNo") &&
          saveQuote.products.filter((x) => x.productCode === "COL").length === 0
        )
          return false;

        if (
          (field.name === "deliveryAddress1" ||
            field.name === "deliveryAddress2" ||
            field.name === "deliverPhoneNo") &&
          saveQuote.products.filter((x) => x.productCode === "DEL").length === 0
        )
          return false;

        //Validate only if corporate
        if (
          field.name === "voucherNo" &&
          quote.requestedAccountType !== "C" &&
          quote.requestedAccountType !== "T"
        )
          return false;

        if (
          field.name === "moP_Expiry" &&
          (quote.requestedAccountType === "C" ||
            quote.requestedAccountType === "T" ||
            details.isD2D)
        )
          return false;

        setValidPaymentDetails((prevState) => ({
          ...prevState,
          [field.name]: true,
        }));

        if (
          field.name === "moP_Type" ||
          confirmationDetails[field.name] === "selected"
        )
          setError("Please select " + field.displayName + ".");
        else setError("Please enter your " + field.displayName + ".");

        /* if (
          confirmationDetails['moP_No'].length &&
          !validNumeric(confirmationDetails['moP_No'])
        ) {
          setValidPaymentDetails((prevState) => ({
            ...prevState,
            ['moP_No']: true,
          }))
          setError('Please enter a valid card number.')
        } else {
          setValidPaymentDetails((prevState) => ({
            ...prevState,
            ['moP_No']: false,
          }))
        }*/

        invalidFieldFound = true;
      } else if (
        confirmationDetails["moP_Expiry"] !== undefined &&
        confirmationDetails["moP_Expiry"] !== null &&
        confirmationDetails["moP_Expiry"].length !== 0 &&
        confirmationDetails["moP_Expiry"].trim() !== "" &&
        !validExpiryDate(confirmationDetails["moP_Expiry"])
      ) {
        setError("Please enter valid card expiry date (YYYYMM).");
        setValidPaymentDetails((prevState) => ({
          ...prevState,
          ["moP_Expiry"]: true,
        }));
        invalidFieldFound = true;
      } else {
        setValidPaymentDetails((prevState) => ({
          ...prevState,
          ["moP_Expiry"]: false,
        }));
        setValidPaymentDetails((prevState) => ({
          ...prevState,
          [field.name]: false,
        }));
      }
    });

    if (invalidFieldFound) return false;
    else submit();
  };

  function submit() {
    setLoading(true);
    if (!details.modify) {
      ApiConsumer.post(API_ROUTES.QUOTE.SAVE, {
        ...confirmationDetails,
        ...saveQuote,
        method: null,
        requestReference: quote?.quoteNumber,
      }).catch((error) => Sentry.captureException(error));
    }

    if (details.modify) modifyBooking();
    else makeBooking();
  }

  function makeBooking() {
    const vanSelected = branches.filter(
      (branch) =>
        quote.requestedPickUpBranch === branch.branchCode &&
        branch.isVanRental === true
    );

    let payload = {
      ...saveQuote,
      ...confirmationDetails,
      quoteNumber: quote.requestReference ?? quote.quoteNumber,
    };

    payload = details.isD2D
      ? {
          ...saveQuote,
          ...confirmationDetails,
          quoteNumber: quote.requestReference ?? quote.quoteNumber,
          fee: details.fee,
          moP_Type: details.moP_Type,
          pickUpFlightNo: details.pickupFlightNumber,
          dropOffFlightNo: "",
          collectionArea: details.PICKUP_AREA_DETAILS
            ? details.PICKUP_AREA_DETAILS
            : details.collectionArea,
          collectionAddressLines: [
            {
              collectionAddress: encodeHTMLEntities(
                details.PICKUP_BRANCH_ADDRESS
                  ? details.PICKUP_BRANCH_ADDRESS
                  : details.collectFromAirportName
              ),
            },
          ],
          collectionAddress: encodeHTMLEntities(
            details.PICKUP_BRANCH_ADDRESS
              ? details.PICKUP_BRANCH_ADDRESS
              : details.collectFromAirportName
          ),
          collectFromAirportName: encodeHTMLEntities(
            details.PICKUP_BRANCH_ADDRESS
              ? details.PICKUP_BRANCH_ADDRESS
              : details.collectFromAirportName
          ),
          deliveryAddressLines: [
            {
              deliveryAddress: encodeHTMLEntities(
                details.DROPOFF_BRANCH_ADDRESS
                  ? details.DROPOFF_BRANCH_ADDRESS
                  : details.deliverToAirportName
              ),
            },
          ],
          deliveryAddress: encodeHTMLEntities(
            details.DROPOFF_BRANCH_ADDRESS
              ? details.DROPOFF_BRANCH_ADDRESS
              : details.deliverToAirportName
          ),
          delivToAirportName: encodeHTMLEntities(
            details.DROPOFF_BRANCH_ADDRESS
              ? details.DROPOFF_BRANCH_ADDRESS
              : details.deliverToAirportName
          ),
          tripKms: details.tripKms.toString(),
          collectionAddress1: encodeHTMLEntities(
            details.COLLECTIONADDRESS1_BRANCH_ADDRESS
              ? details.COLLECTIONADDRESS1_BRANCH_ADDRESS
              : details.collectionAddress1
          ),
          collectionAddress2: encodeHTMLEntities(
            details.COLLECTIONADDRESS2_BRANCH_ADDRESS
              ? details.COLLECTIONADDRESS2_BRANCH_ADDRESS
              : details.collectionAddress2
          ),
          deliveryAddress1: encodeHTMLEntities(
            details.DELIVERYADDRESS1_BRANCH_ADDRESS
              ? details.DELIVERYADDRESS1_BRANCH_ADDRESS
              : details.deliveryAddress1
          ),
          deliveryAddress2: encodeHTMLEntities(
            details.DELIVERYADDRESS2_BRANCH_ADDRESS
              ? details.DELIVERYADDRESS2_BRANCH_ADDRESS
              : details.deliveryAddress2
          ),
          numberPassengers: details.numberPassengers.toString(),
        }
      : payload;
    console.log(payload);
    ApiConsumer.post(
      details.isD2D ? API_ROUTES.DOOR2DOOR.BOOK : API_ROUTES.BOOKING.BOOK,
      payload
    )
      .then((res) => {
        if (
          Array.isArray(res.data?.errors?.error) &&
          res.data?.errors?.error.length > 0
        ) {
          if (
            !res.data?.errors?.error[0].errorMessage
              .toLowerCase()
              .includes("success")
          ) {
            setError(res.data?.errors?.error[0].errorMessage);
            if (
              res.data?.errors?.error[0].errorMessage
                .toLowerCase()
                .indexOf("id no") !== -1
            ) {
              //console.log(res.data?.errors?.error[0].errorMessage.toLowerCase())
              setProgressBar(1);
            }

            return;
          }
        }

        payload = {
          page: "booking-confirmation",
          accountName: details.accountName,
          accountNo: details.accountNo,
          isQuote: false,
          isVanRental: vanSelected.length ? "Y" : "N",
          isCorporate: quote.requestedAccountType === "C" ? "Y" : "N",
          isTour: quote.requestedAccountType === "T" ? "Y" : "N",
          isD2D: details.isD2D ? "Y" : "N",
          warning:
            res.data.warning === null || res.data.warning === undefined
              ? ""
              : res.data.warning.toString(),
          ...saveQuote,
          reservation: res.data,
          ...confirmationDetails,
        };

        payload = details.isD2D
          ? {
              ...payload,
              fee: details.fee,
              moP_Type: details.moP_Type,
              pickupFlight:
                details.pickupFlightType === "selected"
                  ? "N/A"
                  : details.pickupFlightType +
                    " (" +
                    details.pickupFlightNumber +
                    ")",
              dropOffFlightNo: "",
              collectionArea: encodeHTMLEntities(
                details.PICKUP_AREA_DETAILS
                  ? details.PICKUP_AREA_DETAILS
                  : details.collectionArea
              ),
              collectionAddressLines: [
                {
                  collectionAddress: encodeHTMLEntities(
                    details.PICKUP_BRANCH_ADDRESS
                      ? details.PICKUP_BRANCH_ADDRESS
                      : details.collectFromAirportName
                  ),
                },
              ],
              collectionAddress: encodeHTMLEntities(
                details.PICKUP_BRANCH_ADDRESS
                  ? details.PICKUP_BRANCH_ADDRESS
                  : details.collectFromAirportName
              ),
              collectFromAirportName: encodeHTMLEntities(
                details.PICKUP_BRANCH_ADDRESS
                  ? details.PICKUP_BRANCH_ADDRESS
                  : details.collectFromAirportName
              ),
              deliveryAddressLines: [
                {
                  deliveryAddress: encodeHTMLEntities(
                    details.DROPOFF_BRANCH_ADDRESS
                      ? details.DROPOFF_BRANCH_ADDRESS
                      : details.deliverToAirportName
                  ),
                },
              ],
              deliveryAddress: encodeHTMLEntities(
                details.DROPOFF_BRANCH_ADDRESS
                  ? details.DROPOFF_BRANCH_ADDRESS
                  : details.deliverToAirportName
              ),
              delivToAirportName: encodeHTMLEntities(
                details.DROPOFF_BRANCH_ADDRESS
                  ? details.DROPOFF_BRANCH_ADDRESS
                  : details.deliverToAirportName
              ),
              tripKms: details.tripKms.toString(),
              numberPassengers: details.numberPassengers.toString(),
              collectionAddress1: encodeHTMLEntities(
                details.COLLECTIONADDRESS1_BRANCH_ADDRESS
                  ? details.COLLECTIONADDRESS1_BRANCH_ADDRESS
                  : details.collectionAddress1
              ),
              collectionAddress2: encodeHTMLEntities(
                details.COLLECTIONADDRESS2_BRANCH_ADDRESS
                  ? details.COLLECTIONADDRESS2_BRANCH_ADDRESS
                  : details.collectionAddress2
              ),
              deliveryAddress1: encodeHTMLEntities(
                details.DELIVERYADDRESS1_BRANCH_ADDRESS
                  ? details.DELIVERYADDRESS1_BRANCH_ADDRESS
                  : details.deliveryAddress1
              ),
              deliveryAddress2: encodeHTMLEntities(
                details.DELIVERYADDRESS2_BRANCH_ADDRESS
                  ? details.DELIVERYADDRESS2_BRANCH_ADDRESS
                  : details.deliveryAddress2
              ),
              cc: userData.accountEmail ? userData.accountEmail : "",
              rateDesc: details.rateCodeDesc
                ? details.rateCodeDesc
                : saveQuote.rateDesc,
              vehicleGrp_Desc: details.vehicleGroupDesc
                ? details.vehicleGroupDesc
                : saveQuote.vehicleGrp_Desc,
            }
          : payload;

        //Send confirmation email
        ApiConsumer.post(
          "https://weblogs.bluevinegroup.co.za/booking/send-external-email/ajax/",
          JSON.stringify(payload)
        )
          .then((res) => {
            //console.log(res.data)
          })
          .catch((error) => Sentry.captureException(error))
          .finally(() => {
            setLoading(false);
          });

        setConfirmation({
          ...res.data,
          title: confirmationDetails.title,
          surname: confirmationDetails.surname,
        });

        setProgressBar(3);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  function modifyBooking() {
    const vanSelected = branches.filter(
      (branch) =>
        quote.requestedPickUpBranch === branch.branchCode &&
        branch.isVanRental === true
    );

    ApiConsumer.put(API_ROUTES.BOOKING.BOOKING(saveQuote.reservationNumber), {
      ...saveQuote,
      ...confirmationDetails,
      quoteNumber: quote.requestReference ?? quote.quoteNumber,
    })
      .then((res) => {
        if (
          Array.isArray(res.data?.errors?.error) &&
          res.data?.errors?.error.length > 0
        ) {
          if (
            !res.data?.errors?.error[0].errorMessage
              .toLowerCase()
              .includes("success")
          ) {
            setError(res.data?.errors?.error[0].errorMessage);
            return;
          }
        }

        //Send confirmation email
        ApiConsumer.post(
          "https://weblogs.bluevinegroup.co.za/booking/send-external-email/ajax/",
          JSON.stringify({
            page: "modify-booking",
            accountName: details.accountName,
            accountNo: details.accountNo,
            isQuote: false,
            isVanRental: vanSelected.length ? "Y" : "N",
            isCorporate: quote.requestedAccountType === "C" ? "Y" : "N",
            isTour: quote.requestedAccountType === "T" ? "Y" : "N",
            ...saveQuote,
            reservation: res.data,
            ...confirmationDetails,
          })
        )
          .then((res) => {
            //console.log(res.data)
          })
          .catch((error) => Sentry.captureException(error))
          .finally(() => {
            setLoading(false);
          });

        setConfirmation({
          ...res.data,
          title: confirmationDetails.title,
          surname: confirmationDetails.surname,
        });

        setProgressBar(3);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    /*
            Modify load personal details
        */
    if (saveQuote.modRenterTitle !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["title"]: saveQuote.modRenterTitle,
      }));
    }
    if (saveQuote.renterFirstNames !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["firstNames"]: [
          { firstName: saveQuote.renterFirstNames.renterFirstNamesLine[0] },
        ],
      }));
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["contactName"]: saveQuote.renterFirstNames.renterFirstNamesLine[0],
      }));
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["initials"]: saveQuote.renterFirstNames.renterFirstNamesLine[0],
      }));
    }
    if (saveQuote.modRenterSurname !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["surname"]: saveQuote.modRenterSurname,
      }));
    }
    if (saveQuote.modRenterIDType !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["iD_Type"]: saveQuote.modRenterIDType,
      }));
    }
    if (saveQuote.modRenterIDNumber !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["renterID"]: saveQuote.modRenterIDNumber,
      }));
    }
    if (saveQuote.modRenterTelNo !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["contactPhoneNo"]: saveQuote.modRenterTelNo,
      }));
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["renterPhoneNo"]: saveQuote.modRenterTelNo,
      }));
    }
    if (saveQuote.modRenterEmail !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["renterEmail"]: saveQuote.modRenterEmail,
      }));
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["email"]: saveQuote.modRenterEmail,
      }));
    }

    /*
            Modify additional details
        */
    if (saveQuote.modRenterFlatNo !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["renterFlatNo"]: saveQuote.modRenterFlatNo,
      }));
    }
    if (saveQuote.modRenterBuildName !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["renterBuildingName"]: saveQuote.modRenterBuildName,
      }));
    }
    if (saveQuote.modRenterStreetNo !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["renterStreetNo"]: saveQuote.modRenterStreetNo,
      }));
    }
    if (saveQuote.modRenterStreetName !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["renterStreetName"]: saveQuote.modRenterStreetName,
      }));
    }
    if (saveQuote.modRenterPostCode !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["renterPostCode"]: saveQuote.modRenterPostCode,
      }));
    }
    if (saveQuote.modRenterSuburb !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["renterSuburb"]: saveQuote.modRenterSuburb,
      }));
    }
    if (saveQuote.modRenterCity !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["renterCity"]: saveQuote.modRenterCity,
      }));
    }
    if (saveQuote.modRenterProvince !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["renterProvince"]: saveQuote.modRenterProvince,
      }));
    }
    if (saveQuote.modRenterCountry !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["renterCountry"]: saveQuote.modRenterCountry,
      }));
    }
  }, []);

  useEffect(() => {
    if (!details.modify) {
      //Dont overwrite on modify
      if (
        !details.isD2D &&
        sessionStorage.getItem("sessionUser") !== null &&
        sessionStorage.getItem("sessionUser") !== undefined
      ) {
        let userData = JSON.parse(sessionStorage.getItem("sessionUser"));
        if (
          userData.accountEmail !== null &&
          userData.accountEmail !== undefined &&
          userData.accountEmail !== ""
        ) {
          setProfileLoading(true);
          ApiConsumer.post(
            "https://weblogs.bluevinegroup.co.za/user/get-external-profile/ajax/",
            JSON.stringify({ userData })
          )
            .then((res) => {
              if (res.data.user_id_type !== null) {
                setConfirmationDetails((prevState) => ({
                  ...prevState,
                  ["iD_Type"]: res.data.user_id_type,
                }));
              }
              if (res.data.user_id_number !== null) {
                setConfirmationDetails((prevState) => ({
                  ...prevState,
                  ["renterID"]: res.data.user_id_number,
                }));
              }
              if (res.data.user_title !== null) {
                setConfirmationDetails((prevState) => ({
                  ...prevState,
                  ["title"]: res.data.user_title,
                }));
              }
              if (res.data.user_firstname !== null) {
                //Uppercase first character of each word
                setConfirmationDetails((prevState) => ({
                  ...prevState,
                  ["firstNames"]: [
                    {
                      firstName: res.data.user_firstname
                        .split(" ")
                        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(" "),
                    },
                  ],
                }));
                setConfirmationDetails((prevState) => ({
                  ...prevState,
                  ["contactName"]: res.data.user_firstname
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" "),
                }));
                setConfirmationDetails((prevState) => ({
                  ...prevState,
                  ["initials"]: res.data.user_firstname
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" "),
                }));
              }
              if (res.data.user_lastname !== null) {
                //Uppercase first character of each word
                setConfirmationDetails((prevState) => ({
                  ...prevState,
                  ["surname"]: res.data.user_lastname
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" "),
                }));
              }
              if (res.data.user_email_address !== null) {
                setConfirmationDetails((prevState) => ({
                  ...prevState,
                  ["renterEmail"]: res.data.user_email_address,
                }));
                setConfirmationDetails((prevState) => ({
                  ...prevState,
                  ["email"]: res.data.user_email_address,
                }));
              }
              if (res.data.user_contact_number !== null) {
                setConfirmationDetails((prevState) => ({
                  ...prevState,
                  ["contactPhoneNo"]: res.data.user_contact_number,
                }));
                setConfirmationDetails((prevState) => ({
                  ...prevState,
                  ["renterPhoneNo"]: res.data.user_contact_number,
                }));
              }
              setProfileLoading(false);
            })
            .catch((error) => Sentry.captureException(error))
            .finally(() => {
              setProfileLoading(false);
            });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (details.isD2D) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ["renterDrivingLicenseCode"]: "B",
      }));
    }
  }, []);

  return (
    <>
      <Modals
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        loginAccountType={loginAccountType}
        setLoginAccountType={setLoginAccountType}
        pagePostModalSlug={pagePostModalSlug}
        setPagePostModalSlug={setPagePostModalSlug}
      />

      <div className="w-full py-5 lg:w-9/12 ">
        {progressBar !== 3 && (
          <Navigation
            progressBar={progressBar}
            setProgressBar={setProgressBar}
            validateCustomerDetails={validateCustomerDetails}
            setError={setError}
          />
        )}
        <div className="grid grid-cols-12 gap-4">
          {progressBar === 1 && (
            <>
              <div className="col-span-12 pt-8">
                <h1 className="text-3xl font-medium">Customer Details</h1>
                {details.isD2D && (
                  <div className="text-blue-900 uppercase">
                    DOOR2DOOR:{" "}
                    {details.accountName
                      ? details.accountName
                      : details.accountNo}
                  </div>
                )}
              </div>

              <div className="col-span-12">
                Bluu Car and Van Rental takes data security seriously and we use
                VeriSign Secure Socket Layer (SSL) technology encryption
                software within our booking process. Your data will now be sent
                encrypted with SSL.
              </div>

              <div className="col-span-12">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="text-blue-400"
                />{" "}
                To convert this quotation into a reservation, please complete
                the form below. Please fill in your name as it appears in your
                passport or ID.{" "}
                <span
                  className=" text-blue-400 hover:underline cursor-pointer"
                  onClick={() => {
                    setVisibleModal(CONSTANTS.MODAL_TYPE.PAGEPOST);
                    details.isD2D
                      ? setPagePostModalSlug(
                          "door2door-rental-terms-and-conditions"
                        )
                      : setPagePostModalSlug("rental-terms-and-conditions");
                  }}
                >
                  Read our Terms &amp; Conditions.
                </span>
              </div>

              <div className="col-span-12 rounded-lg border border-gray-200 pt-4">
                {!profileLoading ? (
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      return validateCustomerDetails();
                    }}
                    method="POST"
                  >
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="grid gap-4 lg:grid-cols-12">
                        <div className="col-span-4">
                          <label
                            htmlFor="Title"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Title:
                            <span className="text-red-600">*</span>
                          </label>
                          <select
                            name="Title"
                            id="Title"
                            defaultValue="selected"
                            autocomplete="title"
                            className={`style-select ${
                              validCustomerDetails.title
                                ? `border-red-700`
                                : `border-gray-300`
                            }`}
                            onChange={(event) => {
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["title"]: event.target.value,
                              }));
                            }}
                            placeholder="Select option"
                            value={confirmationDetails.title}
                          >
                            <option value="selected">Select option</option>
                            <option value="MR">Mr</option>
                            <option value="MRS">Mrs</option>
                            <option value="MX">Mx</option>
                            <option value="MISS">Miss</option>
                            <option value="MS">Ms</option>
                            <option value="PROF">Prof</option>
                            <option value="DR">Dr</option>
                            <option value="SIR">Sir</option>
                          </select>
                        </div>

                        <div className="col-span-4">
                          <label
                            htmlFor="FirstNames1"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First name: <span className="text-red-600">*</span>
                          </label>
                          <input
                            id="FirstNames1"
                            name="FirstNames[]"
                            type="text"
                            className={`style-input ${
                              validCustomerDetails.contactName
                                ? `border-red-700`
                                : `border-gray-300`
                            }`}
                            onChange={(event) => {
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["firstNames"]: [
                                  { firstName: event.target.value },
                                ],
                              }));
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["contactName"]: event.target.value,
                              }));
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["initials"]:
                                  event.target.value.length > 0
                                    ? event.target.value[0]
                                    : "",
                              }));
                            }}
                            value={
                              confirmationDetails?.firstNames?.length > 0
                                ? confirmationDetails.firstNames[0].firstName
                                : ""
                            }
                          />
                        </div>

                        <div className="col-span-4">
                          <label
                            htmlFor="Surname"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last name: <span className="text-red-600">*</span>
                          </label>
                          <input
                            id="Surname"
                            name="Surname"
                            type="text"
                            className={`style-input ${
                              validCustomerDetails.surname
                                ? `border-red-700`
                                : `border-gray-300`
                            }`}
                            onChange={(event) => {
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["surname"]: event.target.value,
                              }));
                            }}
                            value={confirmationDetails.surname}
                          />
                        </div>
                        {!details.isD2D && (
                          <div className="col-span-4">
                            <label
                              htmlFor="title"
                              className="block text-sm font-medium text-gray-700"
                            >
                              ID type: <span className="text-red-600">*</span>
                            </label>
                            <select
                              name="ID_Type"
                              id="ID_Type"
                              defaultValue="selected"
                              autocomplete="title"
                              className={`style-select ${
                                validCustomerDetails.iD_Type
                                  ? `border-red-700`
                                  : `border-gray-300`
                              }`}
                              onChange={(event) => {
                                setConfirmationDetails((prevState) => ({
                                  ...prevState,
                                  ["iD_Type"]: event.target.value,
                                }));
                              }}
                              value={confirmationDetails.iD_Type}
                            >
                              <option value="selected">Select option</option>
                              <option value="SAID">SA ID</option>
                              <option value="PASS">Passport</option>
                              <option value="NAID">Namibian ID</option>
                              <option value="BWID">Botswana ID</option>
                            </select>
                          </div>
                        )}
                        <div className="col-span-4">
                          <label
                            htmlFor="RenterID"
                            className="block text-sm font-medium text-gray-700"
                          >
                            ID/Passport number:{" "}
                            {!details.isD2D && (
                              <>
                                {confirmationDetails["iD_Type"] !== "PASS" && (
                                  <span className="text-red-600">*</span>
                                )}{" "}
                                <span className="text-gray-300">
                                  (no spaces)
                                </span>
                              </>
                            )}
                          </label>
                          <input
                            id="RenterID"
                            name="RenterID"
                            type="text"
                            className={`style-input ${
                              validCustomerDetails.renterID
                                ? `border-red-700`
                                : `border-gray-300`
                            }`}
                            onChange={(event) => {
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["renterID"]: event.target.value,
                              }));
                            }}
                            value={confirmationDetails.renterID}
                          />
                        </div>

                        <div className="col-span-4">
                          <label
                            htmlFor="RenterPhoneNo"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Contact number:{" "}
                            <span className="text-red-600">*</span>{" "}
                            <span className="text-gray-300">(no spaces)</span>
                          </label>
                          <input
                            id="RenterPhoneNo"
                            name="RenterPhoneNo"
                            type="text"
                            className={`style-input ${
                              validCustomerDetails.contactPhoneNo
                                ? `border-red-700`
                                : `border-gray-300`
                            }`}
                            onChange={(event) => {
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["contactPhoneNo"]: event.target.value,
                              }));
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["renterPhoneNo"]: event.target.value,
                              }));
                            }}
                            value={confirmationDetails.contactPhoneNo}
                          />
                        </div>

                        <div className="col-span-4">
                          <label
                            htmlFor="RenterID"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address:{" "}
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            id="RenterEmail"
                            name="RenterEmail"
                            type="email"
                            className={`style-input ${
                              validCustomerDetails.renterEmail
                                ? `border-red-700`
                                : `border-gray-300`
                            }`}
                            onChange={(event) => {
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["renterEmail"]: event.target.value,
                              }));
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["email"]: event.target.value,
                              }));
                            }}
                            value={confirmationDetails.renterEmail}
                          />
                        </div>
                        {!details.isD2D && (
                          <div className="col-span-4">
                            <label
                              htmlFor="RenterDrivingLicenseCode"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Driving License Code:{" "}
                              <span className="text-red-600">*</span>
                            </label>
                            <select
                              name="RenterDrivingLicenseCode"
                              id="RenterDrivingLicenseCode"
                              defaultValue="selected"
                              autocomplete="title"
                              className={`style-select ${
                                validCustomerDetails.renterDrivingLicenseCode
                                  ? `border-red-700`
                                  : `border-gray-300`
                              }`}
                              onChange={(event) => {
                                setConfirmationDetails((prevState) => ({
                                  ...prevState,
                                  ["renterDrivingLicenseCode"]:
                                    event.target.value,
                                }));
                              }}
                              value={
                                confirmationDetails.renterDrivingLicenseCode
                              }
                            >
                              <option value="selected">Select option</option>

                              <option value="B">B</option>
                              <option value="C1">C1</option>
                              <option value="C">C</option>
                              <option value="EB">EB</option>
                              <option value="EC1">EC1</option>
                              <option value="EC">EC</option>
                              <option value="INT">INT (International)</option>
                            </select>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="px-4 py-3 bg-gray-100 text-right sm:px-6 rounded-bl-lg rounded-br-lg">
                      {error.length !== 0 && (
                        <div className=" float-right col-span-12 text-red-500 text-right lg:py-2">
                          {error}
                        </div>
                      )}

                      <div className="clear-both" />

                      <label className=" float-left rounded-full text-sm cursor-pointer focus:outline-none py-2 lg:mx-2">
                        <input
                          type="checkbox"
                          name="consent"
                          value={!termsChecked ? "Terms &amp; Conditions" : ""}
                          className="h-4 w-4 mt-0.5 cursor-pointer"
                          aria-labelledby="terms-conditions"
                          onChange={(event) => {
                            setConfirmationDetails((prevState) => ({
                              ...prevState,
                              ["consent"]: event.target.value,
                            }));
                          }}
                          onClick={() =>
                            setTermsChecked(termsChecked ? false : true)
                          }
                          checked={termsChecked}
                        />

                        <span className="ml-1">
                          I have read and accept the{" "}
                          <span
                            onClick={() => {
                              setVisibleModal(CONSTANTS.MODAL_TYPE.PAGEPOST);
                              details.isD2D
                                ? setPagePostModalSlug(
                                    "door2door-rental-terms-and-conditions"
                                  )
                                : setPagePostModalSlug(
                                    "rental-terms-and-conditions"
                                  );
                            }}
                            className="text-blue-400 hover:underline cursor-pointer"
                          >
                            Terms &amp; Conditions of Rental
                          </span>
                        </span>
                      </label>

                      <button
                        type="submit"
                        className="float-right px-4 py-1 border border-transparent rounded-full shadow-sm text-lg text-white bg-blue-900 hover:bg-blue-900 focus:outline-none"
                      >
                        Continue
                      </button>
                      {!details.isD2D && (
                        <button
                          type="button"
                          onClick={() => executeAdditionalInfoScroll()}
                          className="float-right px-4 py-1 mr-0 mt-4 border border-transparent rounded-full shadow-sm text-base text-white bg-blue-400 hover:bg-blue-400 focus:outline-none lg:text-lg lg:mt-0 lg:mr-2"
                        >
                          Complete additional Information
                        </button>
                      )}
                      <div className="clear-both" />
                    </div>
                  </form>
                ) : (
                  <div className="mt-2 text-center p-5 lg:p-10 ">
                    <Loader />
                    <br />
                    <br />
                    Loading User Profile
                  </div>
                )}
              </div>

              {!details.isD2D && (
                <>
                  <div ref={additionalInfoRef} className="col-span-12 pt-8">
                    <h1 className="text-3xl font-medium">
                      Additional Information (optional)
                    </h1>
                    <span>
                      The information below needs to be completed. You may
                      complete it here or on collection of the vehicle.
                    </span>
                  </div>
                  <div className="col-span-12 rounded-lg border border-gray-200 pt-4">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="grid gap-4 lg:grid-cols-12">
                        <div className="col-span-12 lg:col-span-2">
                          <label
                            for="RenterFlatNo"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Flat number:
                          </label>
                          <input
                            id="RenterFlatNo"
                            name="RenterFlatNo"
                            type="text"
                            className="style-input"
                            onChange={(event) => {
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["renterFlatNo"]: event.target.value,
                              }));
                            }}
                            value={confirmationDetails.renterFlatNo}
                          />
                        </div>

                        <div className="col-span-12 lg:col-span-4">
                          <label
                            for="RenterBuildingName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Building/Complex:{" "}
                          </label>
                          <input
                            id="RenterBuildingName"
                            name="RenterBuildingName"
                            type="text"
                            className="style-input"
                            onChange={(event) => {
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["renterBuildingName"]: event.target.value,
                              }));
                            }}
                            value={confirmationDetails.renterBuildingName}
                          />
                        </div>

                        <div className="col-span-12 lg:col-span-2">
                          <label
                            for="RenterStreetNo"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Street number:
                          </label>
                          <input
                            id="RenterStreetNo"
                            name="RenterStreetNo"
                            type="text"
                            className="style-input"
                            onChange={(event) => {
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["renterStreetNo"]: event.target.value,
                              }));
                            }}
                            value={confirmationDetails.renterStreetNo}
                          />
                        </div>

                        <div className="col-span-12 lg:col-span-4">
                          <label
                            for="RenterStreetName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Street name:
                          </label>
                          <input
                            id="RenterStreetName"
                            name="RenterStreetName"
                            type="text"
                            className="style-input"
                            onChange={(event) => {
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["renterStreetName"]: event.target.value,
                              }));
                            }}
                            value={confirmationDetails.renterStreetName}
                          />
                        </div>

                        <div className="col-span-12 lg:col-span-4">
                          <label
                            for="RenterPostCode"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Postal code:
                          </label>
                          <input
                            id="RenterPostCode"
                            name="RenterPostCode"
                            type="text"
                            className="style-input"
                            onChange={(event) => {
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["renterPostCode"]: event.target.value,
                              }));
                            }}
                            value={confirmationDetails.renterPostCode}
                          />
                        </div>

                        <div className="col-span-12 lg:col-span-4">
                          <label
                            for="RenterSuburb"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Suburb:
                          </label>
                          <input
                            id="RenterSuburb"
                            name="RenterSuburb"
                            type="text"
                            className="style-input"
                            onChange={(event) => {
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["renterSuburb"]: event.target.value,
                              }));
                            }}
                            value={confirmationDetails.renterSuburb}
                          />
                        </div>

                        <div className="col-span-12 lg:col-span-4">
                          <label
                            for="RenterCity"
                            className="block text-sm font-medium text-gray-700"
                          >
                            City:
                          </label>
                          <input
                            id="RenterCity"
                            name="RenterCity"
                            type="text"
                            className="style-input"
                            onChange={(event) => {
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["renterCity"]: event.target.value,
                              }));
                            }}
                            value={confirmationDetails.renterCity}
                          />
                        </div>

                        <div className="col-span-12 lg:col-span-4">
                          <label
                            for="RenterProvince"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Province:
                          </label>
                          <select
                            name="RenterProvince"
                            id="RenterProvince"
                            defaultValue="selected"
                            autocomplete="title"
                            className="style-select"
                            onChange={(event) => {
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["renterProvince"]: event.target.value,
                              }));
                            }}
                            value={confirmationDetails.renterProvince}
                          >
                            <option value="selected">Select option</option>
                            <option value="EC">Eastern Cape</option>
                            <option value="FN">Limpopo</option>
                            <option value="FS">Free State</option>
                            <option value="GP">Gauteng</option>
                            <option value="KN">KwaZulu Natal</option>
                            <option value="MP">Mpumalanga</option>
                            <option value="NC">Northern Cape</option>
                            <option value="NW">North West</option>
                            <option value="WC">Western Cape</option>
                          </select>
                        </div>

                        <div className="col-span-12 lg:col-span-4">
                          <label
                            for="RenterCountry"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Country:
                          </label>
                          <select
                            name="RenterCountry"
                            id="RenterCountry"
                            defaultValue="selected"
                            autocomplete="title"
                            className="style-select"
                            onChange={(event) => {
                              setConfirmationDetails((prevState) => ({
                                ...prevState,
                                ["renterCountry"]: event.target.value,
                              }));
                            }}
                            value={confirmationDetails.renterCountry}
                          >
                            <option value="selected">Please select one</option>
                            <option value="AF">Afghanistan</option>
                            <option value="AL">Albania</option>
                            <option value="DZ">Algeria</option>
                            <option value="AS">American Samoa</option>
                            <option value="AD">Andora</option>
                            <option value="AN">Angola</option>
                            <option value="AI">Anguilla</option>
                            <option value="AQ">Antarctica</option>
                            <option value="AG">Antigua and Barbuda</option>
                            <option value="AR">Argentina</option>
                            <option value="AM">Armenia</option>
                            <option value="AW">Aruba</option>
                            <option value="AU">Australia</option>
                            <option value="AT">Austria</option>
                            <option value="AZ">Azerbaijan</option>
                            <option value="BS">Bahamas</option>
                            <option value="BH">Bahrain</option>
                            <option value="BD">Bangladesh</option>
                            <option value="BB">Barbados</option>
                            <option value="BY">Belarus</option>
                            <option value="BE">Belgium</option>
                            <option value="BL">Belize</option>
                            <option value="BJ">Benin</option>
                            <option value="BM">Bermuda</option>
                            <option value="BU">Bhutan</option>
                            <option value="BO">Bolivia</option>
                            <option value="BQ">
                              Bonaire, Sint Eustatius and Saba
                            </option>
                            <option value="BA">Bosnia and Herzegovina</option>
                            <option value="BT">Botswana</option>
                            <option value="BV">Bouvet Island</option>
                            <option value="BZ">Brazil</option>
                            <option value="BC">British Columbia</option>
                            <option value="BN">Brunei Darussalam</option>
                            <option value="BG">Bulgaria</option>
                            <option value="BF">Burkina Faso</option>
                            <option value="BI">Burundi</option>
                            <option value="KH">Cambodia</option>
                            <option value="CM">Cameroon</option>
                            <option value="CA">Canada</option>
                            <option value="CV">Cape Verde</option>
                            <option value="KY">Cayman Islands</option>
                            <option value="CF">Central African Republic</option>
                            <option value="TD">Chad</option>
                            <option value="CH">Chile</option>
                            <option value="CN">China</option>
                            <option value="CO">Colombia</option>
                            <option value="KM">Comoros</option>
                            <option value="CG">Congo</option>
                            <option value="CK">Cook Islands</option>
                            <option value="CR">Costa Rica</option>
                            <option value="CI">Cote D'Ivoire</option>
                            <option value="HR">Croatia</option>
                            <option value="CU">Cuba</option>
                            <option value="CW">Curacao</option>
                            <option value="CY">Cyprus</option>
                            <option value="CZ">Czech Republic</option>
                            <option value="CD">
                              Democratic Republic of Congo
                            </option>
                            <option value="DK">Denmark</option>
                            <option value="DJ">Djibouti</option>
                            <option value="DO">Dominican Republic</option>
                            <option value="DU">Dubai</option>
                            <option value="EC">Ecuador</option>
                            <option value="EG">Egypt</option>
                            <option value="GQ">Equatorial Guinea</option>
                            <option value="ER">Eritrea</option>
                            <option value="EE">Estonia</option>
                            <option value="ET">Ethopia</option>
                            <option value="SV">EL Salvador</option>
                            <option value="FJ">Fiji</option>
                            <option value="FI">Finland</option>
                            <option value="FR">France</option>
                            <option value="GA">Gabon</option>
                            <option value="GM">Gambia</option>
                            <option value="GE">Germany</option>
                            <option value="GH">Ghana</option>
                            <option value="GI">Gibraltar</option>
                            <option value="GR">Greece</option>
                            <option value="GL">Greenland</option>
                            <option value="GD">Grenada</option>
                            <option value="GU">Guam</option>
                            <option value="GT">Guatemala</option>
                            <option value="GG">Guernsey</option>
                            <option value="GN">Guinea</option>
                            <option value="GW">Guinea - Bissau</option>
                            <option value="GY">Guyana</option>
                            <option value="HT">Haiti</option>
                            <option value="HL">Holland</option>
                            <option value="VA">
                              Holy See - Vatican City State
                            </option>
                            <option value="HN">Honduras</option>
                            <option value="HK">Hong Kong</option>
                            <option value="HU">Hungary</option>
                            <option value="IN">India</option>
                            <option value="IQ">Iraq</option>
                            <option value="IR">Ireland</option>
                            <option value="IM">Isle Of Man</option>
                            <option value="IS">Israel</option>
                            <option value="IT">Italy</option>
                            <option value="JA">Jamaica</option>
                            <option value="JP">Japan</option>
                            <option value="JE">Jersey</option>
                            <option value="JO">Jordan</option>
                            <option value="KZ">Kazakhstan</option>
                            <option value="KN">Kenya</option>
                            <option value="KW">Kuwait</option>
                            <option value="LV">Latvia</option>
                            <option value="LB">Lebanon</option>
                            <option value="LS">Lesotho</option>
                            <option value="LR">Liberia</option>
                            <option value="LY">Libya</option>
                            <option value="LI">Liechtenstein</option>
                            <option value="LT">Lithuania</option>
                            <option value="LU">Luxembourg</option>
                            <option value="MG">Madagascar</option>
                            <option value="MA">Malawi</option>
                            <option value="MY">Malaysia</option>
                            <option value="MV">Maldives</option>
                            <option value="ML">Mali</option>
                            <option value="MT">Malta</option>
                            <option value="MH">Marshall Islands</option>
                            <option value="MQ">Martinique</option>
                            <option value="MR">Mauritania</option>
                            <option value="MS">Mauritius</option>
                            <option value="MX">Mexico</option>
                            <option value="MZ">Mocambique</option>
                            <option value="MD">Moldova</option>
                            <option value="MC">Monaco</option>
                            <option value="MN">Mongolia</option>
                            <option value="ME">Montenegro</option>
                            <option value="MO">Morocco</option>
                            <option value="MM">Myanmar</option>
                            <option value="NA">Namibia</option>
                            <option value="NP">Nepal</option>
                            <option value="NT">Netherlands</option>
                            <option value="NZ">New Zealand</option>
                            <option value="NI">Nicaragua</option>
                            <option value="NE">Niger</option>
                            <option value="NG">Nigeria</option>
                            <option value="NK">North Korea</option>
                            <option value="NR">Norway</option>
                            <option value="OM">Oman</option>
                            <option value="PK">Pakistan</option>
                            <option value="PW">Palau</option>
                            <option value="PM">Panama</option>
                            <option value="PG">Papa New Guinea</option>
                            <option value="PA">Paraguay</option>
                            <option value="PE">Peru</option>
                            <option value="PH">Philipines</option>
                            <option value="PL">Poland</option>
                            <option value="PO">Portugal</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="QA">Qatar</option>
                            <option value="RE">Reunion</option>
                            <option value="RO">Romania</option>
                            <option value="RS">Russia</option>
                            <option value="RW">Rwanda</option>
                            <option value="WS">Samoa</option>
                            <option value="SM">San Marino</option>
                            <option value="SD">Saudi Arabia</option>
                            <option value="SN">Senegal</option>
                            <option value="SB">Serbia</option>
                            <option value="SC">Seychelles</option>
                            <option value="SR">Sierra Leone</option>
                            <option value="SG">Singapore</option>
                            <option value="SL">Slovakia</option>
                            <option value="SO">Slovenia</option>
                            <option value="SA">South Africa</option>
                            <option value="SK">South Korea</option>
                            <option value="SS">South Sudan</option>
                            <option value="SP">Spain</option>
                            <option value="LK">Sri Lanka</option>
                            <option value="SF">Sudan</option>
                            <option value="SW">Swaziland</option>
                            <option value="SE">Sweden</option>
                            <option value="SI">Switzerland</option>
                            <option value="TW">Taiwan</option>
                            <option value="TZ">Tanzania</option>
                            <option value="TH">Thailand</option>
                            <option value="TG">Togo</option>
                            <option value="TO">Tonga</option>
                            <option value="TT">Trinidad And Tobago</option>
                            <option value="TN">Tunisia</option>
                            <option value="TR">Turkey</option>
                            <option value="TU">Turkey</option>
                            <option value="UG">Uganda</option>
                            <option value="UA">United Arab Emirates</option>
                            <option value="UK">United Kingdom</option>
                            <option value="US">United States of America</option>
                            <option value="UR">Uruguay</option>
                            <option value="UZ">Uzbekistan</option>
                            <option value="VN">Venezuela</option>
                            <option value="VI">Viet Nam</option>
                            <option value="WH">Wales</option>
                            <option value="ZA">Zambia</option>
                            <option value="ZM">Zimbabwe</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="p-2 rounded-bl-lg rounded-br-lg">
                      <div className="clear-both" />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {progressBar === 2 && (
            <PaymentDetails
              confirmationDetails={confirmationDetails}
              setConfirmationDetails={setConfirmationDetails}
              validatePaymentDetails={validatePaymentDetails}
              validPaymentDetails={validPaymentDetails}
              quote={quote}
              error={error}
              loading={loading}
            />
          )}
          {progressBar === 3 && (
            <BookingConfirmation
              confirmation={confirmation}
              vehicleGrp={vehicle.vehicleGrp}
              confirmationDetails={confirmationDetails}
            />
          )}
        </div>
      </div>
      <VehicleCostsSidebar
        bookingErrorMessage={""}
        showContinue={false}
        loading={true}
      />
    </>
  );
};
export default ConfirmationDetails;
