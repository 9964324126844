import { useContext } from 'react'
import { DetailsContext } from 'contexts/details'

const VehicleWaiver = ({ vehicleItem, waiverType }) => {
  let namibiaBranches = ['WH', 'WB', 'KM', 'WT']
  let { details } = useContext(DetailsContext)
  const numbFormatter = new Intl.NumberFormat()

  return (
    <>
      {waiverType === 'CDW' ? (
        <>
          {vehicleItem.standardCover !== '0' && (
            <>
              <span className="text-sm text-blue-400">Standard Waiver</span>
              <h3 className="text-sm">
                <span className="text-lg font-semibold">
                  {namibiaBranches.includes(details?.PICKUP_BRANCH)
                    ? 'N$'
                    : 'R'}
                  {numbFormatter.format(vehicleItem.standardCover)}
                </span>{' '}
                p/day incl.
              </h3>
              <span className="text-sm text-gray-400 ">
                (Liability:{' '}
                {namibiaBranches.includes(details?.PICKUP_BRANCH) ? 'N$' : 'R'}
                {numbFormatter.format(vehicleItem.standardCoverExcessCDW)})
              </span>
            </>
          )}
        </>
      ) : (
        <>
          {vehicleItem.superCover !== '0' && (
            <>
              <span className="text-sm text-blue-400">Super Waiver</span>
              <h3 className="text-sm">
                <span className="text-lg font-semibold">
                  {namibiaBranches.includes(details?.PICKUP_BRANCH)
                    ? 'N$'
                    : 'R'}
                  {numbFormatter.format(vehicleItem.superCover)}
                </span>{' '}
                p/day incl.
              </h3>
              <span className="text-sm text-gray-400 ">
                (Liability:{' '}
                {namibiaBranches.includes(details?.PICKUP_BRANCH) ? 'N$' : 'R'}
                {numbFormatter.format(vehicleItem.superCoverExcessCDW)})
              </span>
            </>
          )}
        </>
      )}
    </>
  )
}
export default VehicleWaiver
