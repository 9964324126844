import {NavLink} from "react-router-dom";

const BookNowBanner = () => {

    return <>
    <div className="relative hidden lg:block">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="auto" viewBox="0 0 1922 212.264">
            <defs>
                <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#0e0a4a"/>
                <stop offset="0.005" stop-color="#0e094a"/>
                <stop offset="0.304" stop-color="#06117b"/>
                <stop offset="0.564" stop-color="#0018a2"/>
                <stop offset="1" stop-color="#00aeef"/>
                </linearGradient>
            </defs>
            <rect id="Rectangle_175" data-name="Rectangle 175" width="1922" height="212.264" fill="url(#linear-gradient)"/>
        </svg>
        <div className="absolute w-7/12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-12 text-white text-center">
            <span className="text-4xl text-gradient">MAKE YOUR BOOKING TODAY</span><NavLink to="/get-quote/" className="btn-transparent text-xl px-8 py-4 ml-10" title="Book Now">Book Now</NavLink>
        </div>
    </div>

    </>
}
export default BookNowBanner;
