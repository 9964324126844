import { useState, useEffect, useContext } from 'react'
import { add, isBefore } from 'date-fns'
import Loader from 'components/loader'
import SectionSelectBranch from 'components/get-quote/section-select-branch'
import { API_ROUTES } from 'routes/api'
import { ApiConsumer } from 'api/ApiConsumer'
import { useHistory, useLocation } from 'react-router-dom'
import { CONSTANTS } from 'contants/constants'
import { WEBSITE_ROUTES } from 'routes/website'
import { QuoteContext, SaveQuoteContext } from 'contexts/quote'
import { VehicleContext } from 'contexts/vehicle'
import { BranchContext } from 'contexts/branch'
import { baseDetailsObj, DetailsContext } from 'contexts/details'
import { useUtils } from 'hooks/utils'
import * as Sentry from '@sentry/react'

const GetQuoteDetails = () => {
  const [isVanRental, setIsVanRental] = useState(false)

  const [showLoader, setShowLoader] = useState(true) //Default true until branches have loaded
  const [errorMessage, setErrorMessage] = useState('')

  const { details, setDetails } = useContext(DetailsContext)

  let history = useHistory()
  let { setQuote } = useContext(QuoteContext)
  let { setSaveQuote } = useContext(SaveQuoteContext)
  let { setVehicle } = useContext(VehicleContext)
  let { branches, setBranches } = useContext(BranchContext)
  let { getDate, getTime } = useUtils()

  useEffect(() => {
    //Restore default
    const dateCoeff = 1000 * 60 * 15
    const defaultDate = new Date()
    const defaultTime = new Date()
    const pickupDate = defaultTime.setHours(
      defaultTime.getHours() + 2,
      defaultTime.getMinutes() + 30,
      0,
      0,
    )

    setDetails({
      [`${CONSTANTS.PICKUP.toUpperCase().replace('-', '')}_DATE`]: defaultDate,
      [`${CONSTANTS.PICKUP.toUpperCase().replace('-', '')}_TIME`]: new Date(
        Math.ceil(pickupDate / dateCoeff) * dateCoeff,
      ),
      [`${CONSTANTS.PICKUP.toUpperCase().replace('-', '')}_BRANCH`]: 'JS',
      [`${CONSTANTS.DROPOFF.toUpperCase().replace('-', '')}_BRANCH`]: 'JS',
      [`${CONSTANTS.DROPOFF.toUpperCase().replace(
        '-',
        '',
      )}_DATE`]: add(defaultDate, { days: 2 }),
      [`${CONSTANTS.DROPOFF.toUpperCase().replace(
        '-',
        '',
      )}_TIME`]: defaultDate.setHours(9, 0, 0, 0),
      accountType: details.isD2D ? 'I' : details.accountType,
      accountNo: details.isD2D ? '' : details.accountNo,
      accountName: details.isD2D ? '' : details.accountName,
      RESIDENCE: details.isD2D ? 'L' : details.RESIDENCE,
      isD2D: false,
    })
  }, [])

  const checkAvailability = () => {
    setErrorMessage('')
    setVehicle({})

    //Check if booking is in no less than 2h15m time
    const pickupDateTime = new Date(
      `${getDate(details.PICKUP_DATE)} ${getTime(details.PICKUP_TIME)}`,
    )
    const dropoffDateTime = new Date(
      `${getDate(details.DROPOFF_DATE)} ${getTime(details.DROPOFF_TIME)}`,
    )
    const validBookingDateTime = add(new Date(), { hours: 2, minutes: 25 }) //Give 5 mins grace time

    if (isBefore(pickupDateTime, validBookingDateTime)) {
      setErrorMessage('Pick-up time must be 2 hours 30 minutes from now.')
      setShowLoader(false)
    } else if (isBefore(dropoffDateTime, pickupDateTime)) {
      setErrorMessage('Drop-off date cannot be before pick-up.')
      setShowLoader(false)
    } else if (isBefore(dropoffDateTime, add(pickupDateTime, { hours: 2 }))) {
      setErrorMessage('Drop-off time cannot be within 2 hours of pick-up.')
      setShowLoader(false)
    } else {
      const payload = {
        pickUpBranch: details.PICKUP_BRANCH,
        pickUpDateTime: `${getDate(details.PICKUP_DATE)} ${getTime(
          details.PICKUP_TIME,
        )}`,
        dropOffBranch: details.DROPOFF_BRANCH,
        dropOffDateTime: `${getDate(details.DROPOFF_DATE)} ${getTime(
          details.DROPOFF_TIME,
        )}`,
        accountType: details.accountType,
        accountNo: details.accountNo,
        foreignLocal: details.RESIDENCE,
        rateCode: details.rateCode,
      }

      setShowLoader(true)

      ApiConsumer.post(API_ROUTES.BOOKING.AVAILABILITY, payload)
        .then((res) => {
          if (
            Array.isArray(res.data?.errors?.error) &&
            res.data?.errors?.error.length > 0
          ) {
            if (
              !res.data?.errors?.error[0].errorMessage
                .toLowerCase()
                .includes('success')
            ) {
              setErrorMessage(
                res.data?.errors?.error[0].errorMessage
                  .replace('Bidvest', 'Bluu')
                  .replace('bidvestcarrental.co.za', 'bcr.co.za'),
              )
              return
            }
            if (res.data.rates.rate[0].charges.length === 0) {
              setErrorMessage(
                'No vehicles available for selected date/time. Contact us on 086 101 7722 or reservations@bcr.co.za',
              )
              return
            }

            setQuote({})
            setSaveQuote({})
            setVehicle({})

            //Reset modify if making new booking
            setDetails((prevState) => ({
              ...prevState,
              [`modify`]: false,
            }))

            if (res.data.rates.rate.length > 0)
              history.push({
                pathname: WEBSITE_ROUTES.AVAILABILITY.link,
                state: {
                  rates: res.data.rates.rate,
                  otherRates: res.data.otherRates,
                  payload: payload,
                },
              })
            else {
              setErrorMessage(
                'Pick Up not in the Branch hours. Phone Bluu 086 101 7722 or refer to Location details',
              )
            }
          }
        })
        .catch((err) => {
          Sentry.captureException(err)
        })
        .finally(() => setShowLoader(false))
    }
  }

  const { search } = useLocation()
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(search)
    if (Array.from(urlSearchParams.entries()).length) {
      setDetails(baseDetailsObj)

      let urlSearchParamsEntries = {}
      urlSearchParams.forEach(function (value, key) {
        urlSearchParamsEntries[key] = value
      })

      if (urlSearchParamsEntries.pickUpBranch)
        setDetails((prevState) => ({
          ...prevState,
          [`${CONSTANTS.PICKUP.toUpperCase().replace(
            '-',
            '',
          )}_BRANCH`]: urlSearchParamsEntries.pickUpBranch,
        }))

      if (urlSearchParamsEntries.pickUpDate)
        setDetails((prevState) => ({
          ...prevState,
          [`${CONSTANTS.PICKUP.toUpperCase().replace(
            '-',
            '',
          )}_DATE`]: urlSearchParamsEntries.pickUpDate,
        }))
      if (urlSearchParamsEntries.pickUpTime)
        setDetails((prevState) => ({
          ...prevState,
          [`${CONSTANTS.PICKUP.toUpperCase().replace(
            '-',
            '',
          )}_TIME`]: urlSearchParamsEntries.pickUpTime,
        }))
      if (urlSearchParamsEntries.dropOffBranch)
        setDetails((prevState) => ({
          ...prevState,
          [`${CONSTANTS.DROPOFF.toUpperCase().replace(
            '-',
            '',
          )}_BRANCH`]: urlSearchParamsEntries.dropOffBranch,
        }))
      if (urlSearchParamsEntries.dropOffDate)
        setDetails((prevState) => ({
          ...prevState,
          [`${CONSTANTS.DROPOFF.toUpperCase().replace(
            '-',
            '',
          )}_DATE`]: urlSearchParamsEntries.dropOffDate,
        }))
      if (urlSearchParamsEntries.dropOffTime)
        setDetails((prevState) => ({
          ...prevState,
          [`${CONSTANTS.DROPOFF.toUpperCase().replace(
            '-',
            '',
          )}_TIME`]: urlSearchParamsEntries.dropOffTime,
        }))
      if (urlSearchParamsEntries.accountType)
        setDetails((prevState) => ({
          ...prevState,
          [`accountType`]: urlSearchParamsEntries.accountType,
        }))
      if (urlSearchParamsEntries.rateCode)
        setDetails((prevState) => ({
          ...prevState,
          [`rateCode`]: urlSearchParamsEntries.rateCode,
        }))
      if (urlSearchParamsEntries.foreignLocal)
        setDetails((prevState) => ({
          ...prevState,
          [`RESIDENCE`]: urlSearchParamsEntries.foreignLocal,
        }))
    }
  }, [search])

  useEffect(() => {
    ApiConsumer.get(API_ROUTES.BRANCHES.ALL)
      .then((res) => {
        setBranches(res.data)
        let vanSelected = [] //Check if selected is van or car
        vanSelected = branches.filter(
          (branch) =>
            details[
              `${CONSTANTS.PICKUP.toUpperCase().replace('-', '')}_BRANCH`
            ] === branch.branchCode && branch.isVanRental === true,
        )
        setIsVanRental(vanSelected.length ? true : false)
        setShowLoader(false)
      })
      .catch((err) => console.error(err))
  }, [])

  return (
    <>
      <div className="grid gap-3 px-5 py-5 border-t-2 border-gray-200 lg:grid-cols-12 lg:px-8 lg:py-3">
        <div className="col-span-12">
          <label
            className={`radio-checkbox-label ${
              !isVanRental ? `bg-gray-200` : `bg-transparent`
            } `}
          >
            <input
              checked={!isVanRental ? true : false}
              type="radio"
              name="CarVanRental"
              value="CarRental"
              className="h-4 w-4 mt-0.5 cursor-pointer"
              aria-labelledby="car-rental"
              onClick={() => {
                setIsVanRental(false)
                setDetails((prevState) => ({
                  ...prevState,
                  [`${CONSTANTS.PICKUP.toUpperCase().replace(
                    '-',
                    '',
                  )}_BRANCH`]: 'JS',
                }))
                setDetails((prevState) => ({
                  ...prevState,
                  [`${CONSTANTS.DROPOFF.toUpperCase().replace(
                    '-',
                    '',
                  )}_BRANCH`]: 'JS',
                }))
              }}
            />
            <span id="car-rental" className="ml-1">
              Car Rental
            </span>
          </label>

          <label
            className={`radio-checkbox-label ${
              isVanRental ? `bg-gray-200` : `bg-transparent`
            } `}
          >
            <input
              checked={isVanRental ? true : false}
              type="radio"
              name="CarVanRental"
              value="VanRental"
              className="h-4 w-4 mt-0.5 cursor-pointer"
              aria-labelledby="van-rental"
              onClick={() => {
                setIsVanRental(true)
                setDetails((prevState) => ({
                  ...prevState,
                  [`${CONSTANTS.PICKUP.toUpperCase().replace(
                    '-',
                    '',
                  )}_BRANCH`]: '1W',
                }))
                setDetails((prevState) => ({
                  ...prevState,
                  [`${CONSTANTS.DROPOFF.toUpperCase().replace(
                    '-',
                    '',
                  )}_BRANCH`]: '1W',
                }))
              }}
            />
            <span id="van-rental" className="ml-1">
              Van Rental
            </span>
          </label>
        </div>
      </div>

      <div
        className={`grid gap-2 px-5 py-5 border-t-2 border-gray-200 lg:grid-cols-12 lg:px-8`}
      >
        {!isVanRental ? (
          <SectionSelectBranch
            branches={branches}
            type={CONSTANTS.PICKUP}
            isVanRental={isVanRental}
          />
        ) : (
          <div className="col-span-12 bg-gray-100 text-center">
            <h2 className="text-xl mb-2 lg:text-2xl">Van Rental</h2>
            Please contact reservations on 086 101 7722 /{' '}
            <a href="mailto:reservations@bcr.co.za" className="underline">
              reservations@bcr.co.za
            </a>{' '}
            for a quote.
          </div>
        )}
      </div>
      {!isVanRental && (
        <div
          className={`grid gap-2 px-5 py-5 border-t-2 border-gray-200 lg:grid-cols-12 lg:px-8`}
        >
          <SectionSelectBranch
            branches={branches}
            type={CONSTANTS.DROPOFF}
            isVanRental={isVanRental}
          />
        </div>
      )}
      {!isVanRental && (
        <div className="grid gap-3 px-5 py-5 border-t-2 border-gray-200 lg:grid-cols-12 lg:px-8 lg:py-3">
          <div className="col-span-12 flex flex-wrap content-center lg:col-span-9">
            <div className="w-full float-left p-1 mr-1 lg:w-auto">
              Country of residence:
            </div>
            <label
              className={`radio-checkbox-label ${
                details.RESIDENCE === CONSTANTS.LOCAL && `bg-gray-200`
              } `}
            >
              <input
                defaultChecked
                type="radio"
                name="ForeignLocal"
                value="L"
                className="radio-checkbox"
                aria-labelledby="sa-renter"
                onClick={() =>
                  setDetails((prevState) => ({
                    ...prevState,
                    [`RESIDENCE`]: CONSTANTS.LOCAL,
                  }))
                }
              />
              <span id="sa-renter" className="ml-1">
                South Africa
              </span>
            </label>

            <label
              className={`radio-checkbox-label ${
                details.RESIDENCE === CONSTANTS.INTERNATIONAL && `bg-gray-200`
              }`}
            >
              <input
                type="radio"
                name="ForeignLocal"
                value="I"
                className="radio-checkbox"
                aria-labelledby="int-renter"
                onClick={() =>
                  setDetails((prevState) => ({
                    ...prevState,
                    [`RESIDENCE`]: CONSTANTS.INTERNATIONAL,
                  }))
                }
              />
              <span id="int-renter" className="ml-1">
                International
              </span>
            </label>
          </div>
          <div className="col-span-12 lg:col-span-3">
            <button
              type="button"
              disabled={showLoader}
              onClick={() => checkAvailability()}
              className={`float-right btn-navy ${
                showLoader
                  ? `bg-gray-300 hover:bg-gray-300`
                  : `bg-blue-900 hover:bg-blue-900`
              } `}
            >
              Search
            </button>
            {showLoader && (
              <div className="mt-2 float-right">
                <Loader />
              </div>
            )}
          </div>
          {errorMessage && (
            <div className="col-span-12 text-red-500 text-right">
              {errorMessage}
            </div>
          )}
        </div>
      )}
    </>
  )
}
export default GetQuoteDetails
