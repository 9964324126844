import { useContext } from 'react'
import { DetailsContext } from 'contexts/details'

const VehicleWaiverDaily = ({ vehicleItem }) => {
  let namibiaBranches = ['WH', 'WB', 'KM', 'WT']
  let { details } = useContext(DetailsContext)
  const numbFormatter = new Intl.NumberFormat()
  return (
    <>
      {vehicleItem.dailyChargeAmount !== '0' && (
        <>
          <span className="text-sm text-blue-400">Daily Charge</span>
          <h3 className="text-sm">
            <span className="text-lg font-semibold">
              {namibiaBranches.includes(details?.PICKUP_BRANCH) ? 'N$' : 'R'}
              {numbFormatter.format(vehicleItem.dailyChargeAmount)}
            </span>{' '}
            p/day incl.
          </h3>
        </>
      )}
    </>
  )
}
export default VehicleWaiverDaily
