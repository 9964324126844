import {useState,useEffect} from "react";
import {ApiConsumer} from "api/ApiConsumer";
import {CMS_ROUTES} from "routes/cms";
import * as Sentry from "@sentry/react";
import Header from "components/header";
import Footer from "components/footer";
import BookNowBanner from "components/footer/booknow-banner";
import Loader from "components/loader";
import {Helmet} from "react-helmet";

const NotFound = () => {

    const pageUrl = CMS_ROUTES.POST_PAGE('pages', 'sitemap');

    const [content,setContent] = useState('');
    const [loaded,setLoaded] = useState(false);
 
    useEffect(() => {
        window.scrollTo(0, 0);
        ApiConsumer.get(pageUrl)
            .then(res => {
                setContent(res.data[0].content.rendered)
            })
            .catch(err => {
                Sentry.captureException(err)
            })
            .finally(() => {
                setLoaded(true)
            })
    }, [pageUrl])

    return <>

    <Helmet>
        <title>Page Not Found | Bluu Car Rental</title>
    </Helmet>

    <Header />

    {
        loaded ? <>

            <div className="w-full bg-gray-200 ">
                <div className="container m-auto py-10 max-w-screen-xl">
                    <div className="col-span-12 ">
                    <h1 className="text-3xl lg:text-5xl px-4 lg:p-6 text-blue-900">404 Page not found</h1>
                    </div>
                </div>
            </div>

            <div id="is404" className="container m-auto py-10 max-w-screen-xl page-content">
                <div className="flex flex-wrap">
                    <div className="w-full py-5 px-6">
                    <p>The page your are looking for could not be found. Follow any of the below links or try searching.</p>
                    <div dangerouslySetInnerHTML={{__html: content}} />
                    </div>
                </div>
            </div>
           
        </>
       :
       <div className="mt-2 text-center "><Loader/></div>
    }

    <BookNowBanner />
    <Footer />
    
    </>
}
export default NotFound;
