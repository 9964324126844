import {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretRight,faLock} from '@fortawesome/free-solid-svg-icons';
import {CONSTANTS} from "contants/constants";
import Loader from "components/loader";
import {ApiConsumer} from "api/ApiConsumer";
import * as Sentry from "@sentry/react";
import {API_ROUTES} from "routes/api";
import {UserContext} from "contexts/user";
import {useHistory} from "react-router-dom";
import {DetailsContext} from "contexts/details";
import {useUtils} from "hooks/utils";

export default function RegisterModal({setVisibleModal,setPagePostModalSlug}) {

    const [loading, setLoading] = useState(false)
    const [registerError, setRegisterError] = useState('')
    const [validRegisterDetails, setValidRegisterDetails] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [registerDetails, setRegisterDetails] = useState({})
    const [termsChecked, setTermsChecked] = useState(false);
    const [marketingChecked, setMarketingChecked] = useState(false);
    let {validEmail,validAlphanumeric,validNumeric} = useUtils()

    let history = useHistory()

    useEffect(() => {
        setRegisterDetails(prevState => ({
            ...prevState,
            [`SITE_HOST`]: 'https://www.bluucarrental.com'
        }))
    }, [])

    const validateRegisterDetails = () => {
    
        let invalidFieldFound = false;
        setRegisterError("");

        const formFields = [
            {name: 'registerIDType', displayName: 'ID type'},
            {name: 'registerID', displayName : 'ID/Passport number' },
            {name: 'registerFirstname', displayName : 'First name' },
            {name: 'registerLastname', displayName : 'Last name' },
            {name: 'registerEmail', displayName : 'Email address' },
            {name: 'registerPassword', displayName : 'Password' },
            {name: 'registerRePassword', displayName : 'Re-enter Password' },
            {name: 'consent', displayName : 'you have read and accept the Website Terms and Conditions' }
        ]
        
        formFields.map((field, i) => {

            if (!invalidFieldFound && (
                registerDetails[field.name]===undefined || 
                registerDetails[field.name].length===0 || 
                (typeof registerDetails[field.name]==='string' && registerDetails[field.name].trim()==="") ||
                registerDetails[field.name]==="selected"
            )){
                setValidRegisterDetails( prevState => ({
                    ...prevState,
                    [field.name]:true
                }));
                
                if (field.name === 'registerIDType' || registerDetails[field.name] === "selected")
                    setRegisterError("Please select " + field.displayName + ".");
                else if (field.name==='consent' && !termsChecked)
                    setRegisterError("Please confirm "+field.displayName+".");
                else 
                    setRegisterError("Please enter your "+field.displayName+".");
                
                invalidFieldFound = true;
            }
            else if (!invalidFieldFound && (
                (field.name === 'registerID' && registerDetails[field.name].length && !validAlphanumeric(registerDetails[field.name])) ||
                (field.name === 'registerID' && registerDetails['registerIDType']==='SAID' && (registerDetails['registerID'].length!==13||!validNumeric(registerDetails['registerID'])) ) ||
                (field.name==='registerEmail' && registerDetails[field.name].length && !validEmail(registerDetails[field.name])) 
            )){
                setValidRegisterDetails( prevState => ({
                    ...prevState,
                    [field.name]:true
                }));
                setRegisterError("Please enter a valid  "+field.displayName+".");
                invalidFieldFound = true;
            }
            else if (field.name==='registerRePassword' && registerDetails['registerRePassword']!==registerDetails['registerPassword']){
                setValidRegisterDetails( prevState => ({
                    ...prevState,
                    [field.name]:true
                }));
                setRegisterError("Passwords do not match.");
                invalidFieldFound = true;
            }
            else{
                setValidRegisterDetails( prevState => ({
                    ...prevState,
                    [field.name]:false
                }));
            } 
        })

        if (invalidFieldFound)
            return false
        else{
            submit()
        }
    }

    function submit() {
        setLoading(true)
        ApiConsumer.post("https://weblogs.bluevinegroup.co.za/user/register-external-user/ajax/", JSON.stringify({registerDetails}) )
        .then(res => {
            //console.log(res.data);
            if (res.data.error!==undefined)
                setRegisterError(res.data.error);
            else
                setFormSubmitted(true)
            setLoading(false)
        })
        .catch(error => Sentry.captureException(error))
        .finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        setRegisterError('')
        setValidRegisterDetails({})
        setFormSubmitted(false)
    }, [registerDetails])


    return <>

                   
        <div className="mb-4">
            <h3 className="text-xl leading-6 font-medium text-gray-900" id="modal-title">
            Create an online profile
            </h3>
            {!formSubmitted &&
            <div className="mt-2 text-sm ">
                <p className="text-gray-500">
                Fill in the form below to create a Bluu Car Rental online profile. This profile allows you to manage your quotes and reservations. 
                </p>
                <div 
                    className="float-left my-1 text-blue-400 hover:underline cursor-pointer focus:outline-none" 
                    onClick={()=>setVisibleModal(CONSTANTS.MODAL_TYPE.USER)}
                ><p className="float-left">I already have an online profile. Sign in</p> <FontAwesomeIcon icon={faCaretRight} className="text-lg float-left ml-1" />
                <div className="clear-both"/>
                </div>
                <div className="clear-both"/>
            </div>
            }
        </div>


        {formSubmitted?
        <div className="col-span-12">

            <div className="bg-green-100 border-t-4 border-green-300 rounded-b-lg p-6 shadow-sm mb-5" role="alert">
                <div className="flex">
                    <div>
                        <p><strong>Congratulations.</strong> You successfully created your Bluu online profile.</p>
                    </div>
                </div>
            </div>

        </div>

        :
    
    <form onSubmit={event => {
        event.preventDefault()
        return validateRegisterDetails()
    }}
        method="POST">



<div className="mt-2">
            
            <label htmlFor="registerIDType" className="block text-sm font-medium text-gray-700">ID
                type: <span
                    className="text-red-600">*</span></label>
            <select name="registerIDType" id="registerIDType"
                    defaultValue="selected"
                    autocomplete="registerIDType"
                    className={`style-select ${(validRegisterDetails.registerIDType) ? `border-red-700` : `border-gray-300`}`}

                    onChange={event => {
                        setRegisterDetails(prevState => ({
                            ...prevState,
                            ['registerIDType']: event.target.value
                        }))
                    }}
                    value={registerDetails.registerIDType}
            >
                <option value="selected">Select option</option>
                <option value="SAID">SA ID</option>
                <option value="PASS">Passport</option>
                <option value="NAID">Namibian ID</option>
                <option value="BWID">Botswana ID</option>
            </select>
        </div>

        <div className="mt-2">
            <label htmlFor="registerID"
                    className="block text-sm font-medium text-gray-700">ID/Passport number: <span
                className="text-red-600">*</span></label>
            <input id="registerID"
                    name="registerID"
                    type="text"
                    className={`style-input ${(validRegisterDetails.registerID) ? `border-red-700` : `border-gray-300` }`}
        
                    onChange={event => {
                        setRegisterDetails(prevState => ({
                            ...prevState,
                            [`registerID`]: event.target.value
                        }))
                    }}
                    value={registerDetails.registerID} 
            />
        </div>



        



        <div className="mt-2">
            <label htmlFor="registerFirstname"
                    className="block text-sm font-medium text-gray-700">First name: <span
                className="text-red-600">*</span></label>
            <input id="registerFirstname"
                    name="registerFirstname"
                    type="text"
                    className={`style-input ${(validRegisterDetails.registerFirstname) ? `border-red-700` : `border-gray-300` }`}
        
                    onChange={event => {
                        setRegisterDetails(prevState => ({
                            ...prevState,
                            [`registerFirstname`]: event.target.value
                        }))
                    }}
                    value={registerDetails.registerFirstname} 
            />
        </div>


        <div className="mt-2">
            <label htmlFor="registerLastname"
                    className="block text-sm font-medium text-gray-700">Last name: <span
                className="text-red-600">*</span></label>
            <input id="registerLastname"
                    name="registerLastname"
                    type="text"
                    className={`style-input ${(validRegisterDetails.registerLastname) ? `border-red-700` : `border-gray-300` }`}
                
                    onChange={event => {
                        setRegisterDetails(prevState => ({
                            ...prevState,
                            [`registerLastname`]: event.target.value
                        }))
                    }}
                    value={registerDetails.registerLastname} 
            />
        </div>



        <div className="mt-2">
            <label htmlFor="registerEmail"
                    className="block text-sm font-medium text-gray-700">Email address: <span
                className="text-red-600">*</span></label>
            <input id="registerEmail"
                    name="registerEmail"
                    type="text"
                    className={`style-input ${(validRegisterDetails.registerEmail) ? `border-red-700` : `border-gray-300` }`}
                
                    onChange={event => {
                        setRegisterDetails(prevState => ({
                            ...prevState,
                            [`registerEmail`]: event.target.value
                        }))
                    }}
                    value={registerDetails.registerEmail} 
            />
        </div>
        <div className="mt-2">
            <label htmlFor="registerPassword"
                    className="block text-sm font-medium text-gray-700">Password: <span
                className="text-red-600">*</span></label>
            <input id="registerPassword"
                    name="registerPassword"
                    type="password"
                    className={`style-input ${(validRegisterDetails.registerPassword) ? `border-red-700` : `border-gray-300` }`}
                
                    onChange={event => {
                        setRegisterDetails(prevState => ({
                            ...prevState,
                            [`registerPassword`]: event.target.value
                        }))
                    }}
                    value={registerDetails.registerPassword} 
            />
        </div>



        <div className="mt-2">
            <label htmlFor="registerRePassword"
                    className="block text-sm font-medium text-gray-700">Re-enter password: <span
                className="text-red-600">*</span></label>
            <input id="registerRePassword"
                    name="registerRePassword"
                    type="password"
                    className={`style-input ${(validRegisterDetails.registerRePassword) ? `border-red-700` : `border-gray-300` }`}
            
                    onChange={event => {
                        setRegisterDetails(prevState => ({
                            ...prevState,
                            [`registerRePassword`]: event.target.value
                        }))
                    }}
                    value={registerDetails.registerRePassword} 
            />
        </div>



        <div className="my-2">

            <label
                className=" rounded-full text-sm cursor-pointer focus:outline-none py-2 lg:mx-2">
                <input type="checkbox"
                        name="registerMarketing"
                        className="h-4 w-4 mt-0.5 cursor-pointer"
                        aria-labelledby="registerMarketing"
                        value={marketingChecked}
                        onChange={event => {
                            setRegisterDetails(prevState => ({
                                ...prevState,
                                ['registerMarketing']: event.target.value
                            }))
                        }}
                        onClick={()=>setMarketingChecked(marketingChecked?false:true)}
                        checked={marketingChecked}
                        />
                <span id="registerMarketings"
                        className="ml-1">Receive marketing and promotional emails from Bluu Car Rental</span>
            </label>

            
        </div>


        <div className="my-2">
            

            <label
                className=" rounded-full text-sm cursor-pointer focus:outline-none py-2 lg:mx-2">
                <input type="checkbox"
                        name="consent"
                        value={!termsChecked? "Terms &amp; Conditions" : ""}
                        className="h-4 w-4 mt-0.5 cursor-pointer"
                        aria-labelledby="terms-conditions"
                        onChange={event => {
                            setRegisterDetails(prevState => ({
                                ...prevState,
                                ['consent']: event.target.value
                            }))
                        }}
                        onClick={()=>setTermsChecked(termsChecked?false:true)}
                        checked={termsChecked}
                        />
                <span 
                        className="ml-1">I have read and accept the <span
                            onClick={()=>{
                                setVisibleModal(CONSTANTS.MODAL_TYPE.PAGEPOST)
                                setPagePostModalSlug('terms-and-conditions')
                            }} 
                            className="text-blue-400 hover:underline cursor-pointer"
                        >Website Terms and Conditions</span></span>
            </label>


            <div className="col-span-12 text-red-500 text-right my-1">{registerError}</div>
            <div className="clear-both"/>

            <button 
                type="submit" 
                disabled={loading}
                className={`float-right inline-flex justify-center btn-light ml-2 ${loading ? `bg-gray-300 hover:bg-gray-300` : `bg-blue-400 hover:bg-blue-400`}`}>
                Register
            </button>
            {loading && <div className="mt-2 float-right"><Loader/></div>}
            
        </div>
        <div className="clear-both"/>

        <hr className="border-b border-gray-300 my-4"/>

        <div className="my-2 text-gray-500 text-sm"><FontAwesomeIcon icon={faLock} className="mr-1" />Your email address is 100% safe with us.<br />
        <span
        onClick={()=>{
            setVisibleModal(CONSTANTS.MODAL_TYPE.PAGEPOST)
            setPagePostModalSlug('privacy-policy')
        }}  
        className="text-blue-400 hover:underline cursor-pointer">Read Privacy Policy</span>.</div>

        </form>
        }

    </>
}
