import { useContext, useEffect } from 'react'
import { SaveQuoteContext } from 'contexts/quote'
import Loader from 'components/loader'
import { ApiConsumer } from 'api/ApiConsumer'
import { API_ROUTES } from 'routes/api'
import * as Sentry from '@sentry/react'
import { DetailsContext } from 'contexts/details'

const PaymentDetails = ({
  confirmationDetails,
  setConfirmationDetails,
  validatePaymentDetails,
  validPaymentDetails,
  quote,
  error,
  loading,
}) => {
  let { saveQuote } = useContext(SaveQuoteContext)
  let { details } = useContext(DetailsContext)

  useEffect(() => {
    if (
      quote.requestedAccountType === 'C' ||
      quote.requestedAccountType === 'T' ||
      details.isD2D
    ) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['moP_Type']: 'AC',
      }))

      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['moP_No']: quote.requestedAccountNo,
      }))

      if (details.isD2D) {
        setConfirmationDetails((prevState) => ({
          ...prevState,
          ['moP_Type']: details.moP_Type,
        }))
      }

      /* GetCustomerInfo */

      ApiConsumer.post(API_ROUTES.CUSTOMER.INFORMATION, {
        accountNo: quote.requestedAccountNo,
      })
        .then((res) => {
          setConfirmationDetails((prevState) => ({
            ...prevState,
            ['invoiceAddress1']:
              res.data.addresses?.customerAddress[0]?.address,
          }))
          setConfirmationDetails((prevState) => ({
            ...prevState,
            ['invoiceAddress2']:
              res.data.addresses?.customerAddress[1]?.address,
          }))
          setConfirmationDetails((prevState) => ({
            ...prevState,
            ['invoiceAddress3']:
              res.data.addresses?.customerAddress[2]?.address,
          }))

          setConfirmationDetails((prevState) => ({
            ...prevState,
            ['invoicePostCode']: res.data.area,
          }))

          /* GetVouchers */
          if (saveQuote.modVoucherNumber !== undefined) {
            setConfirmationDetails((prevState) => ({
              ...prevState,
              ['voucherNo']: saveQuote.modVoucherNumber,
            }))
          } else {
            ApiConsumer.get(
              API_ROUTES.CUSTOMER.VOUCHER(quote.requestedAccountNo),
            )
              .then((res) => {
                setConfirmationDetails((prevState) => ({
                  ...prevState,
                  ['voucherNo']: res.data.voucherNumber,
                }))
              })
              .catch((error) => Sentry.captureException(error))
          }
        })
        .catch((error) => Sentry.captureException(error))
    }

    /*
            Modify payment details
        */
    /*if (saveQuote.modPayMethod!==undefined){
            setConfirmationDetails(prevState => ({
                ...prevState,
                ['moP_Type']: saveQuote.modPayMethod
            }))
        }*/
    if (
      saveQuote.modCardAccNumber !== undefined &&
      saveQuote.modCardAccNumber !== 0
    ) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['mOP_No']: saveQuote.modCardAccNumber,
      }))
    }
    if (
      saveQuote.modCardExpiryDate !== undefined &&
      saveQuote.modCardExpiryDate !== 0
    ) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['mOP_Expiry']: saveQuote.modCardExpiryDate,
      }))
    }
    if (saveQuote.invoiceAddress !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['invoiceAddress1']: saveQuote.invoiceAddress?.invoiceAddressLine[0],
      }))
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['invoiceAddress2']: saveQuote.invoiceAddress?.invoiceAddressLine[1],
      }))
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['invoiceAddress3']: saveQuote.invoiceAddress?.invoiceAddressLine[2],
      }))
    }

    if (saveQuote.collectionAddress !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['collectionAddress1']:
          saveQuote.collectionAddress?.collectionAddressLine[0],
      }))
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['collectionAddress2']:
          saveQuote.collectionAddress?.collectionAddressLine[1],
      }))
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['collectionAddress3']:
          saveQuote.collectionAddress?.collectionAddressLine[2],
      }))
    }
    if (saveQuote.deliveryAddress !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['deliveryAddress1']: saveQuote.deliveryAddress?.deliveryAddressLine[0],
      }))
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['deliveryAddress2']: saveQuote.deliveryAddress?.deliveryAddressLine[1],
      }))
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['deliveryAddress3']: saveQuote.deliveryAddress?.deliveryAddressLine[2],
      }))
    }

    if (saveQuote.modPostalCode !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['invoicePostCode']: saveQuote.modPostalCode,
      }))
    }
    if (saveQuote.modRemarks !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['remarks']: saveQuote.modRemarks,
      }))
    }
    if (saveQuote.modOrderNumber !== undefined) {
      setConfirmationDetails((prevState) => ({
        ...prevState,
        ['orderNo']: saveQuote.modOrderNumber,
      }))
    }
  }, [])

  return (
    <>
      <div className="col-span-12 pt-8">
        <h1 className="text-3xl font-medium">Payment Details</h1>
        {details.isD2D && (
          <div className="text-blue-900 uppercase">
            DOOR2DOOR:{' '}
            {details.accountName ? details.accountName : details.accountNo}
          </div>
        )}
        <span>Payment information required for booking confirmation.</span>
      </div>

      <div className="col-span-12 rounded-lg border border-gray-200 pt-4">
        <form
          onSubmit={(event) => {
            event.preventDefault()
            return validatePaymentDetails()
          }}
          method="POST"
        >
          <div className="px-4 py-5 bg-white sm:p-6">
            {quote.requestedAccountType === 'C' ||
            quote.requestedAccountType === 'T' ||
            (details.isD2D && confirmationDetails.moP_Type === 'AC') ? (
              <div className="grid gap-4 border-b border-gray-200 pb-8 mb-8 lg:grid-cols-12">
                <div className="col-span-6">
                  <label
                    htmlFor="VoucherNo"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Voucher number: <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="VoucherNo"
                    name="VoucherNo"
                    type="text"
                    className={`style-input ${
                      validPaymentDetails.voucherNo
                        ? `border-red-700`
                        : `border-gray-300`
                    }`}
                    onChange={(event) => {
                      setConfirmationDetails((prevState) => ({
                        ...prevState,
                        ['voucherNo']: event.target.value,
                      }))
                    }}
                    value={confirmationDetails.voucherNo}
                  />
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="OrderNo"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Order number:{' '}
                  </label>
                  <input
                    id="OrderNo"
                    name="OrderNo"
                    type="text"
                    className="style-input"
                    onChange={(event) => {
                      setConfirmationDetails((prevState) => ({
                        ...prevState,
                        ['orderNo']: event.target.value,
                      }))
                    }}
                    value={confirmationDetails.orderNo}
                  />
                </div>
              </div>
            ) : null}

            <div className="grid gap-4 lg:grid-cols-12">
              {!details.isD2D && (
                <div className="col-span-12 lg:col-span-4">
                  <label
                    htmlFor="MOP_Type"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Method of payment: <span className="text-red-600">*</span>
                  </label>
                  <select
                    name="MOP_Type"
                    id="MOP_Type"
                    defaultValue="selected"
                    autoComplete="title"
                    className={`style-select ${
                      validPaymentDetails.moP_Type
                        ? `border-red-700`
                        : `border-gray-300`
                    }`}
                    onChange={(event) => {
                      setConfirmationDetails((prevState) => ({
                        ...prevState,
                        ['moP_Type']: event.target.value,
                      }))
                    }}
                    value={confirmationDetails.moP_Type}
                  >
                    {quote.requestedAccountType === 'C' ||
                    quote.requestedAccountType === 'T' ? (
                      <option value="AC">Billback to the Account</option>
                    ) : (
                      <>
                        <option value="selected">Select option</option>
                        <option value="AX">American Express Card</option>
                        <option value="DC">Diners Club</option>
                        <option value="MC">Master Card</option>
                        <option value="VS">Visa Card</option>
                      </>
                    )}
                  </select>
                </div>
              )}

              {(details.isD2D && confirmationDetails.moP_Type !== 'AC') ||
              !details.isD2D ? (
                <>
                  <div className="col-span-12 lg:col-span-4">
                    <label
                      htmlFor="MOP_No"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Card number:{' '}
                      <span className="text-gray-300">(no spaces)</span>
                    </label>
                    <input
                      id="MOP_No"
                      name="MOP_No"
                      type="text"
                      className={`style-input ${
                        validPaymentDetails.moP_No
                          ? `border-red-700`
                          : `border-gray-300`
                      }`}
                      onChange={(event) => {
                        setConfirmationDetails((prevState) => ({
                          ...prevState,
                          ['moP_No']: event.target.value,
                        }))
                      }}
                      value={confirmationDetails.moP_No}
                    />
                  </div>

                  <div className="col-span-12 lg:col-span-4">
                    <label
                      htmlFor="MOP_Expiry"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Card expiry date:{' '}
                      <span className="text-gray-300">(YYYYMM)</span>
                    </label>
                    <input
                      id="MOP_Expiry"
                      name="MOP_Expiry"
                      type="text"
                      maxLength={6}
                      className={`style-input ${
                        validPaymentDetails.moP_Expiry
                          ? `border-red-700`
                          : `border-gray-300`
                      }`}
                      onChange={(event) => {
                        setConfirmationDetails((prevState) => ({
                          ...prevState,
                          ['moP_Expiry']: event.target.value,
                        }))
                      }}
                      value={confirmationDetails.moP_Expiry}
                      placeholder="YYYYMM"
                    />
                  </div>

                  <div className="col-span-12 lg:col-span-4">
                    <label
                      htmlFor="InvoiceAddress1"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Billing address line 1:{' '}
                      {details.isD2D ? (
                        ''
                      ) : (
                        <span className="text-red-600">*</span>
                      )}
                    </label>
                    <input
                      id="InvoiceAddress1"
                      name="InvoiceAddress[]"
                      type="text"
                      onChange={(event) => {
                        setConfirmationDetails((prevState) => ({
                          ...prevState,
                          ['invoiceAddress1']: event.target.value,
                        }))
                      }}
                      className={`style-input ${
                        validPaymentDetails.invoiceAddress1
                          ? `border-red-700`
                          : `border-gray-300`
                      }`}
                      value={confirmationDetails.invoiceAddress1}
                    />
                  </div>

                  <div className="col-span-12 lg:col-span-4">
                    <label
                      htmlFor="InvoiceAddress2"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Billing address line 2:{' '}
                      {details.isD2D ? (
                        ''
                      ) : (
                        <span className="text-red-600">*</span>
                      )}
                    </label>
                    <input
                      id="InvoiceAddress2"
                      name="InvoiceAddress[]"
                      type="text"
                      onChange={(event) => {
                        setConfirmationDetails((prevState) => ({
                          ...prevState,
                          ['invoiceAddress2']: event.target.value,
                        }))
                      }}
                      className={`style-input ${
                        validPaymentDetails.invoiceAddress2
                          ? `border-red-700`
                          : `border-gray-300`
                      }`}
                      value={confirmationDetails.invoiceAddress2}
                    />
                  </div>

                  <div className="col-span-12 lg:col-span-4">
                    <label
                      htmlFor="InvoiceAddress3"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Billing address line 3:
                    </label>
                    <input
                      id="InvoiceAddress3"
                      name="InvoiceAddress[]"
                      type="text"
                      onChange={(event) => {
                        setConfirmationDetails((prevState) => ({
                          ...prevState,
                          ['invoiceAddress3']: event.target.value,
                        }))
                      }}
                      className="style-input"
                      value={confirmationDetails.invoiceAddress3}
                    />
                  </div>

                  <div className="col-span-12 lg:col-span-4">
                    <label
                      htmlFor="InvoicePostCode"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Postal code:{' '}
                      {details.isD2D ? (
                        ''
                      ) : (
                        <span className="text-red-600">*</span>
                      )}{' '}
                    </label>
                    <input
                      id="InvoicePostCode"
                      name="InvoicePostCode"
                      type="text"
                      onChange={(event) => {
                        setConfirmationDetails((prevState) => ({
                          ...prevState,
                          ['invoicePostCode']: event.target.value,
                        }))
                      }}
                      className={`style-input ${
                        validPaymentDetails.invoicePostCode
                          ? `border-red-700`
                          : `border-gray-300`
                      }`}
                      value={confirmationDetails.invoicePostCode}
                    />
                  </div>
                </>
              ) : null}

              <div className="col-span-12 lg:col-span-8">
                <label
                  htmlFor="InvoicePostCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Remarks/Comments:{' '}
                </label>
                <textarea
                  id="about"
                  name="about"
                  rows="3"
                  className="mt-1 p-3 focus:outline-none border border-gray-300 block w-full sm:text-sm rounded-xl"
                  onChange={(event) => {
                    setConfirmationDetails((prevState) => ({
                      ...prevState,
                      ['remarks']: event.target.value,
                    }))
                  }}
                  value={confirmationDetails.remarks}
                />
              </div>
            </div>

            {saveQuote.products.filter((x) => x.productCode === 'COL')
              .length !== 0 && (
              <div className="grid gap-4 border-t border-gray-200 pt-8 mt-8 lg:grid-cols-12">
                <div className="col-span-12 lg:col-span-4">
                  <label
                    htmlFor="CollectionAddress1"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Collection address line 1:{' '}
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="CollectionAddress1"
                    name="CollectionAddress[]"
                    type="text"
                    onChange={(event) => {
                      setConfirmationDetails((prevState) => ({
                        ...prevState,
                        ['collectionAddress1']: event.target.value,
                      }))
                    }}
                    className={`style-input ${
                      validPaymentDetails.collectionAddress1
                        ? `border-red-700`
                        : `border-gray-300`
                    }`}
                    value={confirmationDetails.collectionAddress1}
                  />
                </div>

                <div className="col-span-12 lg:col-span-4">
                  <label
                    htmlFor="CollectionAddress2"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Collection address line 2:{' '}
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="CollectionAddress2"
                    name="CollectionAddress[]"
                    type="text"
                    onChange={(event) => {
                      setConfirmationDetails((prevState) => ({
                        ...prevState,
                        ['collectionAddress2']: event.target.value,
                      }))
                    }}
                    className={`style-input ${
                      validPaymentDetails.collectionAddress2
                        ? `border-red-700`
                        : `border-gray-300`
                    }`}
                    value={confirmationDetails.collectionAddress2}
                  />
                </div>

                <div className="col-span-12 lg:col-span-4">
                  <label
                    htmlFor="CollectPhoneNo"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Collection phone number:{' '}
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="CollectPhoneNo"
                    name="CollectPhoneNo"
                    type="text"
                    onChange={(event) => {
                      setConfirmationDetails((prevState) => ({
                        ...prevState,
                        ['collectPhoneNo']: event.target.value,
                      }))
                    }}
                    className={`style-input ${
                      validPaymentDetails.collectPhoneNo
                        ? `border-red-700`
                        : `border-gray-300`
                    }`}
                    value={confirmationDetails.collectPhoneNo}
                  />
                </div>
              </div>
            )}

            {saveQuote.products.filter((x) => x.productCode === 'DEL')
              .length !== 0 && (
              <div className="grid gap-4 border-t border-gray-200 pt-8 mt-8 lg:grid-cols-12">
                <div className="col-span-12 lg:col-span-4">
                  <label
                    htmlFor="DeliveryAddress1"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Delivery address line 1:{' '}
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="DeliveryAddress1"
                    name="DeliveryAddress[]"
                    type="text"
                    onChange={(event) => {
                      setConfirmationDetails((prevState) => ({
                        ...prevState,
                        ['deliveryAddress1']: event.target.value,
                      }))
                    }}
                    className={`style-input ${
                      validPaymentDetails.deliveryAddress1
                        ? `border-red-700`
                        : `border-gray-300`
                    }`}
                    value={confirmationDetails.deliveryAddress1}
                  />
                </div>

                <div className="col-span-12 lg:col-span-4">
                  <label
                    htmlFor="DeliveryAddress2"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Delivery address line 2:{' '}
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="DeliveryAddress2"
                    name="DeliveryAddress[]"
                    type="text"
                    onChange={(event) => {
                      setConfirmationDetails((prevState) => ({
                        ...prevState,
                        ['deliveryAddress2']: event.target.value,
                      }))
                    }}
                    className={`style-input ${
                      validPaymentDetails.deliveryAddress2
                        ? `border-red-700`
                        : `border-gray-300`
                    }`}
                    value={confirmationDetails.deliveryAddress2}
                  />
                </div>

                <div className="col-span-12 lg:col-span-4">
                  <label
                    htmlFor="DeliverPhoneNo"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Delivery phone number:{' '}
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="DeliverPhoneNo"
                    name="DeliverPhoneNo"
                    type="text"
                    onChange={(event) => {
                      setConfirmationDetails((prevState) => ({
                        ...prevState,
                        ['deliverPhoneNo']: event.target.value,
                      }))
                    }}
                    className={`style-input ${
                      validPaymentDetails.deliverPhoneNo
                        ? `border-red-700`
                        : `border-gray-300`
                    }`}
                    value={confirmationDetails.deliverPhoneNo}
                  />
                </div>
              </div>
            )}
            {!details.isD2D && (
              <div className="grid gap-4 lg:grid-cols-12">
                <div className="col-span-12">
                  <div
                    className="bg-green-100 border-t-4 border-green-300 rounded-b-lg p-6 mt-8 shadow-sm"
                    role="alert"
                  >
                    <div className="flex">
                      <div>
                        <p>
                          <strong>
                            DEBIT CARDS WILL NOT BE ACCEPTED AS A METHOD OF
                            PAYMENT
                          </strong>
                        </p>
                        <p>
                          Supplying your credit card number is optional. Please
                          note, however, your credit card must be produced at
                          time of rental.{' '}
                          <strong>
                            On collection of your vehicle, an authorisation
                            amount of the estimated cost of rental plus the
                            applicable liability will be placed on hold on your
                            credit card.
                          </strong>
                        </p>
                        <p>
                          <strong>Please note</strong> that the actual credit
                          card holder must be present when collecting the car to
                          sign the rental agreement.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="px-4 py-3 bg-gray-100 text-right sm:px-6 rounded-bl-lg rounded-br-lg">
            {error.length !== 0 && (
              <div className="col-span-12 text-red-500 text-right py-2">
                {error}
              </div>
            )}

            <div className="clear-both" />

            <button
              type="submit"
              disabled={loading}
              className={`float-right btn-navy ${
                loading
                  ? `bg-gray-300 hover:bg-gray-300`
                  : `bg-blue-900 hover:bg-blue-900`
              } focus:outline-none`}
            >
              Submit
            </button>
            {loading && (
              <div className="mt-2 float-right">
                <Loader />
              </div>
            )}
            <div className="clear-both" />
          </div>
        </form>
      </div>
    </>
  )
}
export default PaymentDetails
