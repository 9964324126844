import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import App from 'App';
import reportWebVitals from './reportWebVitals';
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

const tagManagerArgs = {
    gtmId: "GTM-N66KRGK"
}
TagManager.initialize(tagManagerArgs)

Sentry.init({
    dsn: "https://603248a29b954a4195423449607de1ad@o1075897.ingest.sentry.io/6077213",
    integrations: [new BrowserTracing()],
    environment: "prod",
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
reportWebVitals();
