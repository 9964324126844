import {useEffect} from "react";
import GetQuote from "components/get-quote/";
import Header from "components/header";
import Footer from "components/footer";
import {Helmet} from "react-helmet";

const ManageBookingPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    return <>

    <Helmet>
        <title>Manage Booking | Bluu Car Rental</title>
    </Helmet>

    <Header />

    <div className="w-full bg-gray-200 text-center">
        <div className="container m-auto py-10 max-w-screen-xl">
            <div className="col-span-12 ">
            <h1 className="text-3xl lg:text-5xl px-4 lg:p-6 text-blue-900">Manage Booking</h1>
            </div>
        </div>
    </div>

    <div className="relative bg-gray-200 pb-20 px-4 ">
            <div className="mx-auto lg:w-6/12">
                <div className="grid lg:grid-cols-12 gap-4">
                  <div className="col-span-12 ">
                      <GetQuote activeTab={2} />
                  </div>
                </div>
            </div>
    </div>

    <Footer />
    </>
}
export default ManageBookingPage;
