import { useEffect, useContext } from "react";
import Header from "components/header";
import Footer from "components/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import VehicleImage from "components/availability/vehicle-image";
import { NavLink } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { DetailsContext } from "contexts/details";

const BookingConfirmationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let history = useHistory();
  const location = useLocation();
  const { details } = useContext(DetailsContext);
  const confirmation = location.state.confirmation;
  const vehicleGrp = location.state.vehicleGrp;
  const confirmationDetails = location.state.confirmationDetails;

  if (
    confirmation === undefined ||
    confirmationDetails === null ||
    Object.keys(confirmation).length === 0 ||
    Object.keys(confirmationDetails).length === 0
  ) {
    history.push("/get-quote");
    return <></>;
  }

  let userReservationData = {
    reservationNumber: confirmation.reservationNumber,
    reservationEmail: confirmationDetails.renterEmail,
  };
  sessionStorage.setItem(
    "sessionUserReservation",
    JSON.stringify(userReservationData)
  );

  return (
    <>
      <Helmet>
        <title>Booking Confirmation | Bluu Car Rental</title>
      </Helmet>
      <Header />
      <div className="container m-auto py-8 px-4">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-9/12 ">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12">
                <h1 className="text-3xl font-medium text-center">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-green-400"
                  />{" "}
                  Booking Confirmation
                </h1>
              </div>

              <div className="col-span-12 rounded-lg border border-gray-200 p-4">
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12 flex flex-wrap content-center lg:col-span-6">
                    <VehicleImage vehicleGrp={vehicleGrp} />
                  </div>

                  <div className="col-span-12 flex flex-wrap content-center lg:col-span-6">
                    <h2 className="text-3xl text-blue-900 font-semibold">
                      Hi, {confirmation.title} {confirmation.surname}
                    </h2>
                    <div className="text-xl my-5">
                      Thank you for booking with us. Your booking number is{" "}
                      <span className="font-bold">
                        {confirmation.reservationNumber}
                      </span>
                      . Check your email we have sent a confirmation email.
                    </div>
                    {!details.isD2D && (
                      <NavLink
                        to="/manage-booking/"
                        className="btn-navy mb-5 ml-0 text-lg text-white bg-blue-900 hover:bg-blue-900 focus:outline-none"
                      >
                        Manage My Booking
                      </NavLink>
                    )}
                  </div>
                </div>

                <div
                  className="bg-green-100 border-t-4 border-green-300 rounded-b-lg p-6 shadow-sm"
                  role="alert"
                >
                  <div className="flex">
                    <div>
                      <p>
                        <strong>PLEASE NOTE</strong>
                      </p>
                      <ul className="pl-5 list-disc">
                        <li>
                          The provided costs were calculated using the
                          information that you provided to us. Changes in
                          pick-up or drop-off location, car type, dates or times
                          may change this quotation.
                        </li>

                        {!details.isD2D && (
                          <>
                            <li>
                              This rate is for renters 21 years of age or older,
                              who are in possession of a valid drivers licence
                              and a major credit card. Both are required to be
                              produced at time of rental.
                            </li>
                            <li>
                              On commencement of your rental the following will
                              be requested: Valid Driving Licence, South African
                              Identity document OR valid passport, Renter’s
                              Credit Card.
                            </li>
                            <li>
                              All rates and charges quoted are inclusive of VAT.
                            </li>
                            <li>
                              PLEASE NOTE: Foreign Driving licences are
                              acceptable provided the language used (printed
                              thereon) is English and there is a photograph
                              present. Should a licence be in any other
                              language, other than English, we require an
                              International Driving Licence to be produced at
                              commencement of the rental.
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default BookingConfirmationPage;
