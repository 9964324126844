const BranchItem = ({allBranches}) => {

    return <>
    <div className="w-full px-4 lg:py-5 m-auto">
        <div className="grid lg:grid-cols-12 gap-4">
        { 
        allBranches.map((category,i)=>(
                <>
                <div key={i} className="col-span-12 bg-blue-900 text-white text-2xl py-3 px-5 rounded-md"><span dangerouslySetInnerHTML={{__html: category.name}} /></div>
                {
                    category.provinces?.sort().map((province,x)=>(
                    <div key={x} className="col-span-12 lg:col-span-4">
                        <h3 className="block py-3 px-5 bg-gray-200 rounded-md uppercase text-blue-900 font-semibold"><span dangerouslySetInnerHTML={{__html: province}} /></h3>
                        { category.branches[province]?.sort((a, b) => (a.branch.title.rendered > b.branch.title.rendered) ? 1 : -1).map( (provinceBranch,y) => (
                            <a key={y} href={'/branches/'+provinceBranch.branch.slug+'/'} className="block py-2 px-5 hover:underline"><div dangerouslySetInnerHTML={{__html: provinceBranch.branch.title.rendered+' ('+provinceBranch.branch.acf.branch_code+')'}} /></a>
                        )) }
                    </div>
                    ))
                }
                </>
            ))
        }
        </div>
    </div>
    </>
}
export default BranchItem;
