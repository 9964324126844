
import Header from "components/header";
import Footer from "components/footer";
import { useParams } from 'react-router-dom';
import {useEffect, useState,useContext} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretRight,faLock,faCheckCircle} from '@fortawesome/free-solid-svg-icons';

import {CONSTANTS} from "contants/constants";
import Loader from "components/loader";
import {ApiConsumer} from "api/ApiConsumer";
import * as Sentry from "@sentry/react";
import {API_ROUTES} from "routes/api";
import {UserContext} from "contexts/user";
import {useHistory} from "react-router-dom";
import {DetailsContext} from "contexts/details";
import {useUtils} from "hooks/utils";
import {Helmet} from "react-helmet";

const LoginPage = () => {

    let history = useHistory()

    const params = useParams()
    const [userActivationValid, setUserActivationValid] = useState(false)
    const [userActivationExpired, setUserActivationExpired] = useState(false)

    useEffect(() => {

        if (sessionStorage.getItem('sessionUser')!==null && sessionStorage.getItem('sessionUser')!==undefined){
            let userData = JSON.parse(sessionStorage.getItem('sessionUser'))
            if (userData.accountEmail!==null && userData.accountEmail!==undefined && userData.accountEmail!=="")
                history.push('/profile')
        }

        if (params.validHash!==undefined && params.validCode!==undefined){
            //Check user registration activation
            ApiConsumer.post("https://weblogs.bluevinegroup.co.za/user/activate-external-user-account/ajax/", JSON.stringify({params}) )
            .then(res => {
               
                if (res.data.valid!==""){
                  setUserActivationValid(true)
                  setUserActivationExpired(false)
                }
                else{
                  setUserActivationExpired(true)
                  setUserActivationValid(false)
                }
            })
            .catch(error => Sentry.captureException(error))
            .finally(() => {
              
            })
        }
    }, [])

    const [loading, setLoading] = useState(false)
    const [loginDetails, setLoginDetails] = useState({})
    const [loginError, setLoginError] = useState('')
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [showActivationLink, setShowActivationLink] = useState(false)
    const [validLoginDetails, setValidLoginDetails] = useState({});
    let {user, setUser} = useContext(UserContext)
    let {validEmail} = useUtils()


    const validateUserLogin = () => {
    
        let invalidFieldFound = false;
        setLoginError("");
        setShowActivationLink(false);

        const formFields = [
            {name: 'loginEmail', displayName : 'Email address' },
            {name: 'loginPassword', displayName : 'Password' },
        ]
        
        formFields.map((field, i) => {

            if (!invalidFieldFound && (
                loginDetails[field.name]===undefined || 
                loginDetails[field.name].length===0 || 
                (typeof loginDetails[field.name]==='string' && loginDetails[field.name].trim()==="") ||
                loginDetails[field.name]==="selected"
            )){
                setValidLoginDetails( prevState => ({
                    ...prevState,
                    [field.name]:true
                }));
                
                setLoginError("Please enter your "+field.displayName+".");
                
                invalidFieldFound = true;
            }
            else if (!invalidFieldFound && (
                (field.name==='loginEmail' && loginDetails[field.name].length && !validEmail(loginDetails[field.name])) 
            )){
                setValidLoginDetails( prevState => ({
                    ...prevState,
                    [field.name]:true
                }));
                setLoginError("Please enter a valid  "+field.displayName+".");
                invalidFieldFound = true;
            }
            else{
                setValidLoginDetails( prevState => ({
                    ...prevState,
                    [field.name]:false
                }));
            } 
        })

        if (invalidFieldFound)
            return false
        else{
            submit()
        }
    }

    function submit() {

        setLoading(true)
        setUser({})
        sessionStorage.clear()

        ApiConsumer.post("https://weblogs.bluevinegroup.co.za/user/send-external-login/ajax/", JSON.stringify({loginDetails}) )
        .then(res => {
            
            if (res.data.error!==undefined){
                if (res.data.error.includes("not been activated"))
                    setShowActivationLink(true);
              
                setLoginError(res.data.error);
            }
            else{
               
                let userData = { 
                    accountName: res.data.sessionUserFirstname.charAt(0) + ". " + res.data.sessionUserLastname,
                    accountNo: null,
                    accountType: "I",
                    accountUser: res.data.sessionUserId,
                    accountEmail: res.data.sessionUserEmail
                }
                setUser(userData)
                sessionStorage.setItem('sessionUser', JSON.stringify(userData));
                history.push('/profile')
                window.scrollTo(0, 0)
            }
            setLoading(false)
        })
        .catch(error => Sentry.captureException(error))
        .finally(() => {
            setLoading(false)
        })
    }

    function resendActivationEmail() {

        setLoading(true)

        ApiConsumer.post("https://weblogs.bluevinegroup.co.za/user/resend-activation-email/ajax/", JSON.stringify({loginDetails}) )
        .then(res => {
            if (res.data.error!==undefined)
                setLoginError(res.data.error);
            else
                setLoginError("Your activation email has been sent to "+res.data.valid);

            setShowActivationLink(false)
            setLoading(false)
        })
        .catch(error => Sentry.captureException(error))
        .finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoginError('')
        setValidLoginDetails({})
        setFormSubmitted(false)
        setShowActivationLink(false)
    }, [loginDetails])

    return <>

    <Helmet>
        <title>Login | Bluu Car Rental</title>
    </Helmet>

    <Header />

    <div className="w-full bg-gray-200 text-center">
            <div className="container m-auto py-10 max-w-screen-xl">
                <div className="col-span-12 ">
                    <h1 className="text-3xl lg:text-5xl px-4 lg:p-6 text-blue-900">Login</h1>
                </div>
            </div>
        </div>
  
    <div className="relative bg-gray-200 pb-20 px-4 ">
            <div className="mx-auto lg:w-6/12">

                    <div className="col-span-12 lg:px-4">

                    {userActivationValid &&
                    <div className="bg-green-100 border-t-4 border-green-300 rounded-b-lg p-6 shadow-sm mb-5" role="alert">
                        <div className="flex">
                            <div>
                            <FontAwesomeIcon icon={faCheckCircle} className="text-green-400"/> Your account has been successfully activated!
                            </div>
                        </div>
                    </div>
                    }
                    {userActivationExpired &&
                    <div className="bg-red-100 border-t-4 border-red-300 rounded-b-lg p-6 shadow-sm mb-5" role="alert">
                        <div className="flex">
                            <div>
                            Activation link has expired. Please create a new online profile.
                            </div>
                        </div>
                    </div>
                    }

                    </div>


                

                    <form onSubmit={event => {
            event.preventDefault()
            return validateUserLogin()
        }}>

<div className="grid lg:grid-cols-12 gap-4 p-5 lg:p-10 rounded-md bg-gray-100">

<div className="col-span-12 lg:col-span-6 mt-2">
            <label htmlFor="LoginEmail"
                    className="block text-sm font-medium text-gray-700">Email address: <span
                className="text-red-600">*</span></label>
            <input id="LoginEmail"
                    name="LoginEmail"
                    type="text"
                    className={`style-input ${(validLoginDetails.loginEmail) ? `border-red-700` : `border-gray-300` }`}
        
                    onChange={event => {
                        setLoginDetails(prevState => ({
                            ...prevState,
                            [`loginEmail`]: event.target.value
                        }))
                    }}
                    value={loginDetails.loginEmail} 
            />
        </div>
        <div className="col-span-12 lg:col-span-6 mt-2">
            <label htmlFor="LoginPassword"
                    className="block text-sm font-medium text-gray-700">Password: <span
                className="text-red-600">*</span></label>
            <input id="LoginPassword"
                    name="LoginPassword"
                    type="password"
                    className={`style-input ${(validLoginDetails.loginPassword) ? `border-red-700` : `border-gray-300` }`}
                
                    onChange={event => {
                        setLoginDetails(prevState => ({
                            ...prevState,
                            [`loginPassword`]: event.target.value
                        }))
                    }}
                    value={loginDetails.loginPassword} 
            />
        </div>

        <div className="col-span-12  my-2 text-gray-500 text-sm">All passwords are case-sensitive. Please check CAPS LOCK is turned off.</div>
        <div className="col-span-12  mt-2">
            <div className="text-red-500 text-right">{loginError} {showActivationLink && <div onClick={()=>resendActivationEmail()} className="text-blue-400 hover:underline cursor-pointer focus:outline-none">Resend Activation Email</div>}</div>
            <div className="clear-both"/>
            <button 
                type="submit" 
                disabled={loading}
                className={`float-right inline-flex justify-center btn-light ml-2 ${loading ? `bg-gray-300 hover:bg-gray-300` : `bg-blue-400 hover:bg-blue-400`}`}
                >
                Sign In
            </button>
            {loading && <div className="mt-2 float-right"><Loader/></div>}
            <div className="clear-both"/>
        </div>
        <div className="clear-both"/>

</div>
        </form>


            
            </div>
    </div>

    <Footer />
    </>
}
export default LoginPage;
