import {useEffect} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import {CONSTANTS} from "contants/constants";

const ExtendBooking = ({quoteNo, setPage}) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return <>
        <div className="grid gap-4 p-5 lg:p-10 lg:grid-cols-12">
            <div className="col-span-12 bg-gray-100">
                <h2 className="text-2xl mb-2">Extend Your Booking ({quoteNo})</h2>
                <p>To extend your current rental, kindly contact our Central Reservations Team at 0861017722 or the rental
                branch at the number printed on your rental agreement.</p>
            </div>
            <div className="col-span-12">

                <button
                    type="button"
                    onClick={() => setPage(CONSTANTS.BOOKING.STATUS.CONFIRMED)}
                    className="inline-block float-left px-4 py-2 border border-transparent rounded-full shadow-sm text-lg text-white focus:outline-none bg-blue-400 hover:bg-blue-400 cursor-pointer"
                ><FontAwesomeIcon icon={faAngleLeft} className="text-white"/> Back
                </button>
            </div>
        </div>
    </>
}
export default ExtendBooking;
