import {useState, useEffect} from "react";
import {ApiConsumer} from "api/ApiConsumer";
import BookNowBanner from "components/footer/booknow-banner";
import Header from "components/header";
import Footer from "components/footer";
import * as Sentry from "@sentry/react";
import {useParams} from 'react-router-dom';
import BranchItem from "pages/branch-item";
import BranchSingle from "pages/branch-single";
import {Helmet} from "react-helmet";

const Branches = () => {

    const params = useParams()

    const [branchCategoriesData,setBranchCategoriesData] = useState({});
    const [branchCategoriesLoaded,setBranchCategoriesLoaded] = useState(false);

    const [branchesData,setBranchesData] = useState({});
    const [branchesLoaded,setBranchesLoaded] = useState(false);

    const [allBranches,setAllBranches] = useState([]);

    const [branchPage,setBranchPage] = useState([]);
    
    useEffect(() => {

        window.scrollTo(0, 0);
       
        //Get branch categories
        const branchCategoriesUrl = "https://cms.bluucarrental.com/wp-json/wp/v2/branches_categories";
        //Reset
        setBranchCategoriesData({})
        setBranchCategoriesLoaded(false)
        
        ApiConsumer.get(branchCategoriesUrl)
        .then(res => {
            
            let categories = 
                (params.slug!==undefined)? 
                    res.data.filter(category => category.slug===params.slug && category.count!==0)
                    : res.data.filter(category => category.count!==0);
            
            setBranchCategoriesData(categories);

            //Reset
            setBranchesData({});
            setBranchesLoaded(false)
            setBranchPage([]);
           
            categories.map((category,i)=>{

                const categoryUrl = "https://cms.bluucarrental.com/wp-json/wp/v2/branches?branches_categories="+category.id+"&per_page=100" 
                
                ApiConsumer.get(categoryUrl)
                    .then(res => {
                        let branches = res.data.filter(branch => branch.status === "publish");
                        setBranchesData((prevState) => ({ ...prevState, [category.slug]:branches }))
                    })
                    .catch(err => {
                        Sentry.captureException(err)
                    })
                    .finally(() => {
                        setBranchesLoaded(true)
                    })
    
            })

            if (categories.length===0){ //If no categories then check page otherwise 404

                const pageUrl = "https://cms.bluucarrental.com/wp-json/wp/v2/branches?slug="+params.slug; 
                
                ApiConsumer.get(pageUrl)
                    .then(res => {
                        setBranchPage(res.data)
                    })
                    .catch(err => {
                        Sentry.captureException(err)
                    })
                    .finally(() => {
                        setBranchesLoaded(true)
                    })
            }

        })
        .catch(err => {
            Sentry.captureException(err)
        })
        .finally(() => {
            setBranchCategoriesLoaded(true)
        })

    }, [params])

    useEffect(() => {
        if (branchCategoriesLoaded && branchesLoaded){

            let branches = [];
           
            branchCategoriesData.map((category,i)=>{

                let branchesByProvince = []
                let branchProvinces = []
               
                branchesData[category.slug]?.map((branch,x)=>{
                    let province = branch.acf.province;
                    if (branchesByProvince[province]===undefined){
                        branchesByProvince[province] = [{branch}]; 
                        branchProvinces[x] = province;
                    }
                    else{
                        branchesByProvince[province] = [...branchesByProvince[province],{branch}];
                    }
                })

                branches[i] = {
                    name: category.name, 
                    provinces: branchProvinces, 
                    branches: branchesByProvince
                }
            })
           setAllBranches(branches)
        }
    }, [branchCategoriesLoaded,branchCategoriesData,branchesLoaded,branchesData])

    return <>
    
        {(branchCategoriesLoaded && branchesLoaded)? <>
            <Helmet>
                <title>
                    {
                        branchPage.length===0?
                            branchCategoriesData.filter(x=>x.slug===params.slug).length ? branchCategoriesData[0].name : "Branches"
                            : branchPage[0].title.rendered+' ('+branchPage[0].acf.branch_code+')'
                    } | Bluu Car Rental
                </title>
            </Helmet>
            </> 
            : null 
        }
        
        <Header />
        
        {
        (branchCategoriesLoaded && branchesLoaded)? <>

        <div className="w-full bg-gray-200 ">
            <div className="container m-auto py-10 max-w-screen-xl">
                <div className="col-span-12 ">
                <h1 className="text-3xl lg:text-5xl px-4 lg:p-6 text-blue-900">{
                    branchPage.length===0?
                        branchCategoriesData.filter(x=>x.slug===params.slug).length ? <span dangerouslySetInnerHTML={{__html: branchCategoriesData[0].name}} /> : "Branches"
                        : <span dangerouslySetInnerHTML={{__html: branchPage[0].title.rendered+' ('+branchPage[0].acf.branch_code+')'}} />
                }</h1>
                </div>
            </div>
        </div>


        <div className="container m-auto py-8">
            <div className="flex flex-wrap">
                {branchPage.length===0? <BranchItem allBranches={allBranches} /> : <BranchSingle branchPage={branchPage[0]} />  }
            </div>
        </div>

        </>

        : null 

        }
        <BookNowBanner />
        <Footer />
    </>
}
export default Branches;
