import { useState, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { CONSTANTS } from "contants/constants";
import Modals from "components/modals/index";
import { UserContext } from "contexts/user";
import { DetailsContext } from "contexts/details";

const Footer = () => {
  const [visibleModal, setVisibleModal] = useState("");
  const [pagePostModalSlug, setPagePostModalSlug] = useState("");
  const [loginAccountType, setLoginAccountType] = useState(
    CONSTANTS.LOGIN_ACCOUNT_TYPE.CORPORATE
  );

  const currentYear = new Date().getFullYear();

  let { user, setUser } = useContext(UserContext);
  let { setDetails } = useContext(DetailsContext);
  let history = useHistory();

  return (
    <>
      <Modals
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        loginAccountType={loginAccountType}
        setLoginAccountType={setLoginAccountType}
        pagePostModalSlug={pagePostModalSlug}
        setPagePostModalSlug={setPagePostModalSlug}
      />

      <footer className="bg-header-footer" aria-labelledby="footer-heading">
        <div className="max-w-7xl mx-auto py-12 px-4">
          <div className="grid gap-8">
            <div className="grid col-span-12 lg:grid-cols-2 gap-8 lg:col-span-2">
              <div className="grid lg:grid-cols-2 gap-8">
                <div>
                  <h3 className="footer-header">Rentals</h3>
                  <ul role="navigation" className="footer-list">
                    <li>
                      <NavLink to="/manage-booking/" className="footer-link">
                        Manage Existing Booking
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/get-quote/" className="footer-link">
                        Individual Renters
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/rentals/corporate-customers/"
                        className="footer-link"
                      >
                        Corporate Customers
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/rentals/tour-operators/"
                        className="footer-link"
                      >
                        Tour Operators
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/transfer-services/chauffeur-drive/"
                        className="footer-link"
                      >
                        Chauffeur Drive
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/transfer-services/door2door-transfers/"
                        className="footer-link"
                      >
                        Door2Door
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="mt-0">
                  <h3 className="footer-header">Bluu Products</h3>
                  <ul role="navigation" className="footer-list">
                    <li>
                      <NavLink
                        to="/products/child-seats/"
                        className="footer-link"
                      >
                        Child Seats
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/products/gps-rentals/"
                        className="footer-link"
                      >
                        GPS Rentals
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/products/bicycle-carrier-rental/"
                        className="footer-link"
                      >
                        Bicycle Carrier Rental
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="grid lg:grid-cols-2 gap-8">
                <div className="mt-0">
                  <h3 className="footer-header">Partners</h3>
                  <ul role="navigation" className="footer-list">
                    <li>
                      <NavLink to="/bluu-partners/" className="footer-link">
                        BLUU Partners
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/partners/international-travel/"
                        className="footer-link"
                      >
                        International Travel
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div>
                  <h3 className="footer-header">Useful Links</h3>
                  <ul role="navigation" className="footer-list">
                    <li>
                      <NavLink
                        to="/about-bluu-car-rental/"
                        className="footer-link"
                      >
                        About Bluu Car Rental
                      </NavLink>
                    </li>
                    {Object.keys(user).length === 0 ? (
                      <>
                        <li className="text-gray-300">
                          <span
                            onClick={() => {
                              setVisibleModal(CONSTANTS.MODAL_TYPE.REGISTER);
                            }}
                            className="footer-link hover:underline cursor-pointer"
                          >
                            Register
                          </span>{" "}
                          /{" "}
                          <span
                            onClick={() => {
                              setVisibleModal(CONSTANTS.MODAL_TYPE.USER);
                            }}
                            className="footer-link hover:underline cursor-pointer"
                          >
                            Sign In
                          </span>
                        </li>
                      </>
                    ) : (
                      <li>
                        <div
                          onClick={() => {
                            setUser({});
                            sessionStorage.clear();
                            history.push("/");
                            setDetails((prevState) => ({
                              ...prevState,
                              [`accountType`]: "I",
                            }));
                            setDetails((prevState) => ({
                              ...prevState,
                              [`accountNo`]: null,
                            }));
                            setDetails((prevState) => ({
                              ...prevState,
                              [`accountName`]: null,
                            }));
                          }}
                          className="footer-link cursor-pointer"
                        >
                          Logout
                        </div>
                      </li>
                    )}

                    <li>
                      <NavLink to="/customer-care/" className="footer-link">
                        Customer Care
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/transfer-services/" className="footer-link">
                        Transfer Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/access-to-information/"
                        className="footer-link"
                      >
                        Access to Information
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/visitor-processing-notice/"
                        className="footer-link"
                      >
                        Visitor Processing Notice "POPIA"
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 lg:p-6 border-t border-blue-900">
          <div className="lg:max-w-7xl mx-auto">
            <div className="text-blue-400 lg:flex items-center lg:justify-between">
              <div className="lg:flex">
                Copyright {currentYear} Bluu Car Rental. All Rights
                Reserved&nbsp;&nbsp;|&nbsp;&nbsp;
                <NavLink
                  to="/terms-and-conditions/"
                  className="hover:underline"
                  title="Website Terms and Conditions"
                >
                  Website Terms and Conditions
                </NavLink>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <NavLink
                  to="/privacy-policy/"
                  className="hover:underline"
                  title="Privacy Policy"
                >
                  Privacy Policy
                </NavLink>
              </div>
              <div className="lg:flex">
                <a
                  href="mailto:reservations@bcr.co.za"
                  className="hover:underline"
                  rel="noopener noreferrer"
                  title="Reservation email"
                >
                  reservations@bcr.co.za
                </a>
                &nbsp;&nbsp;|&nbsp;&nbsp;Call:&nbsp;
                <a
                  href="tel:+27861017722"
                  className="hover:underline"
                  title="Reservation number"
                  rel="noopener noreferrer"
                >
                  086 101 7722
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
