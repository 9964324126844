export const CONSTANTS = {
  PICKUP: "Pick-Up",
  DROPOFF: "Drop-Off",
  D2D_COLLECTION_ADDRESS1: "Collection-Address1",
  D2D_COLLECTION_ADDRESS2: "Collection-Address2",
  D2D_DELIVERY_ADDRESS1: "Delivery-Address1",
  D2D_DELIVERY_ADDRESS2: "Delivery-Address2",
  LOCAL: "L",
  INTERNATIONAL: "I",
  DEFAULT_LOCATION: "JH",
  GOOGLE_MAPS_API: "AIzaSyBhryg7u-htmdxyepdhm9nq6zA3_hOmByY",
  GOOGLE_MAPS_LIBRARIES: ["places"],
  D2D_VEHICLES: [
    {
      code: "3C",
      name: "Cape Town",
      address:
        "Borcherds Quarry Road & Bahrain Drive, Cape Town International Airport (CPT), Matroosfontein, Cape Town, South Africa",
      type: "Airport",
      status: "active",
    },
    {
      code: "3D",
      name: "KwaZulu-Natal",
      address:
        "King Shaka International Airport, King Shaka Dr, La Mercy, South Africa",
      type: "Airport",
      status: "active",
    },
    {
      code: "3I",
      name: "Gauteng",
      address: "15 Anvil Road, Isando, Kempton Park, South Africa",
      type: "Address",
      status: "active",
    },
    {
      code: "3N",
      name: "Randburg",
      address: "126 Bram Fischer Drive, Ferndale, Randburg, South Africa",
      type: "Address",
      status: "inactive",
    },
  ],
  VEHICLE_TYPES: [
    {
      name: "Small",
      description: "Hatchbacks",
      code: "A",
    },
    {
      name: "Medium",
      description: "Sedan",
      code: "B",
    },
    {
      name: "Large",
      description: "SUV",
      code: "C",
    },
    {
      name: "Passenger",
      description: "Kombi",
      code: "D",
    },
    {
      name: "Loader",
      description: "Van",
      code: "E",
    },
  ],
  QUOTE: {
    SEND: {
      WHATSAPP: "WHATSAPP",
      EMAIL: "EMAIL",
    },
  },
  BOOKING: {
    MANAGE_BOOKING: {
      QUOTE: "QUOTE",
      BOOKING: "BOOKING",
      CANCEL: "CANCEL",
      EXTEND: "EXTEND",
      MODIFY: "MODIFY",
    },
    STATUS: {
      CANCELLED: "CANCELLED",
      CONFIRMED: "CONFIRMED",
      OPEN: "OPEN",
      QUOTE: "QUOTE",
    },
  },
  MODAL_TYPE: {
    CORPTOUR: "CORPTOUR",
    REGISTER: "REGISTER",
    USER: "USER",
    FORGOT: "FORGOT",
    SENDQUOTE: "SENDQUOTE",
    PAGEPOST: "PAGEPOST",
  },
  LOGIN_ACCOUNT_TYPE: {
    CORPORATE: "CORPORATE",
    TOUR: "TOUR",
    CUSTOMER: "CUSTOMER",
    INDIVIDUAL: "INDIVIDUAL",
  },
};
