import {useState, useEffect } from "react";
import {ApiConsumer} from "api/ApiConsumer";

const VehicleName = ({vehicleGrp}) => {
    
    const [fleetData, setFleetData] = useState({});
    const page_url = "https://cms.bluucarrental.com/wp-json/wp/v2/fleet?per_page=100";

    useEffect(() => {
        ApiConsumer.get(page_url)
            .then(res => {
                setFleetData(res.data);
            })
            .catch(err => {
                console.error(err)
            })
    }, [page_url])

    const vehicle = (Object.keys(fleetData).length!==0) ? fleetData.filter(x => x.title.rendered === "Group " + vehicleGrp) : []

    return <>
        <span>{vehicle[0]?.acf.vehicle_make_model}</span>
    </>

}
export default VehicleName;
