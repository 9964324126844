import {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretRight,faLock} from '@fortawesome/free-solid-svg-icons';
import {CONSTANTS} from "contants/constants";
import Loader from "components/loader";
import {ApiConsumer} from "api/ApiConsumer";
import * as Sentry from "@sentry/react";
import {API_ROUTES} from "routes/api";
import {UserContext} from "contexts/user";
import {useHistory} from "react-router-dom";
import {DetailsContext} from "contexts/details";
import {useUtils} from "hooks/utils";


const UserProfileDetails = () => {


    const [loading, setLoading] = useState(false)
    const [profileLoading, setProfileLoading] = useState(false)
    const [profileError, setProfileError] = useState('')
    const [validProfileDetails, setValidProfileDetails] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [profileDetails, setProfileDetails] = useState({})
    const [termsChecked, setTermsChecked] = useState(false);
    const [marketingChecked, setMarketingChecked] = useState(false);
    const [changePasswordChecked, setChangePasswordChecked] = useState(false);
    let {validEmail,validAlphanumeric,validNumeric,validContact} = useUtils()

    let history = useHistory()

    const validateProfileDetails = () => {
    
        let invalidFieldFound = false;
        setProfileError("");
        setFormSubmitted(false);

        const formFields = [
            {name: 'profileIDType', displayName: 'ID type'},
            {name: 'profileID', displayName : 'ID/Passport number' },
            {name: 'profileTitle', displayName : 'Title' },
            {name: 'profileInitials', displayName : 'Initials' },
            {name: 'profileFirstname', displayName : 'First name' },
            {name: 'profileLastname', displayName : 'Last name' },
            {name: 'profileEmail', displayName : 'Email address' },
            {name: 'profileContactNo', displayName : 'Contact number' },
            {name: 'profileChangePass', displayName : 'Current password and New password' }
        ]

        formFields.map((field, i) => {
            
            if (field.name!=="profileChangePass" && !invalidFieldFound && (
                profileDetails[field.name]===undefined || 
                profileDetails[field.name].length===0 || 
                (typeof profileDetails[field.name]==='string' && profileDetails[field.name].trim()==="") ||
                profileDetails[field.name]==="selected"
            )){

                setValidProfileDetails( prevState => ({
                    ...prevState,
                    [field.name]:true
                }));
                
                if (field.name === 'profileIDType' || field.name === 'profileTitle' || profileDetails[field.name] === "selected")
                    setProfileError("Please select " + field.displayName + ".");
                else 
                    setProfileError("Please enter your "+field.displayName+".");
                
                invalidFieldFound = true;
            }
            else if (!invalidFieldFound && (
                (field.name === 'profileID' && profileDetails[field.name].length && !validAlphanumeric(profileDetails[field.name])) ||
                (field.name === 'profileID' && profileDetails['profileIDType']==='SAID' && (profileDetails['profileID'].length!==13||!validNumeric(profileDetails['profileID'])) ) ||
                (field.name === 'profileContactNo' && profileDetails[field.name].length && !validContact(profileDetails[field.name])) ||
                (field.name==='profileEmail' && profileDetails[field.name].length && !validEmail(profileDetails[field.name])) 
            )){
                setValidProfileDetails( prevState => ({
                    ...prevState,
                    [field.name]:true
                }));
                setProfileError("Please enter a valid  "+field.displayName+".");
                invalidFieldFound = true;
            }
            else if (field.name === "profileChangePass" && changePasswordChecked && 
                (
                    profileDetails['profileOldPassword']===undefined || 
                    profileDetails['profileOldPassword'].length===0 || 
                    (typeof profileDetails['profileOldPassword']==='string' && profileDetails['profileOldPassword'].trim()==="")  ||
                    profileDetails['profilePassword']===undefined || 
                    profileDetails['profilePassword'].length===0 || 
                    (typeof profileDetails['profilePassword']==='string' && profileDetails['profilePassword'].trim()==="") 
                )
           
            ){
                setValidProfileDetails( prevState => ({
                    ...prevState,
                    ["profileOldPassword"]:true
                }));
                setValidProfileDetails( prevState => ({
                    ...prevState,
                    ["profilePassword"]:true
                }));
                setProfileError("Please enter "+field.displayName+".");
                invalidFieldFound = true;
            }
            else{
                setValidProfileDetails( prevState => ({
                    ...prevState,
                    [field.name]:false
                }));
            } 
        })

        if (invalidFieldFound)
            return false
        else{
            submit()
        }
    }

    function submit() {
        setLoading(true)
        let userData = JSON.parse(sessionStorage.getItem('sessionUser'))
        ApiConsumer.post("https://weblogs.bluevinegroup.co.za/user/update-external-profile/ajax/", JSON.stringify({userData,profileDetails}) )
        .then(res => {
           
            if (res.data.error!==undefined)
                setProfileError(res.data.error);
            else
                setFormSubmitted(true)
            setLoading(false)
        })
        .catch(error => Sentry.captureException(error))
        .finally(() => {
            setLoading(false)
        })
    }



    useEffect(() => {
        setProfileLoading(true)
        let userData = JSON.parse(sessionStorage.getItem('sessionUser'))
        ApiConsumer.post("https://weblogs.bluevinegroup.co.za/user/get-external-profile/ajax/", JSON.stringify({userData}) )
        .then(res => {
          
            setMarketingChecked(Boolean(parseInt(res.data.user_receive_marketing)))

            if (res.data.user_id_type!==null){
                setProfileDetails(prevState => ({
                    ...prevState,
                    ['profileIDType']: res.data.user_id_type
                }))
            }
            if (res.data.user_id_number!==null){
                setProfileDetails(prevState => ({
                    ...prevState,
                    ['profileID']: res.data.user_id_number
                }))
            }
            if (res.data.user_title!==null){
                setProfileDetails(prevState => ({
                    ...prevState,
                    ['profileTitle']: res.data.user_title
                }))
            }
            if (res.data.user_initials!==null){
                setProfileDetails(prevState => ({
                    ...prevState,
                    ['profileInitials']: res.data.user_initials
                }))
            }
            if (res.data.user_firstname!==null){
                setProfileDetails(prevState => ({//Uppercase first character of each word
                    ...prevState,
                    ['profileFirstname']: res.data.user_firstname.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
                }))
            }
            if (res.data.user_lastname!==null){
                setProfileDetails(prevState => ({//Uppercase first character of each word
                    ...prevState,
                    ['profileLastname']: res.data.user_lastname.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
                }))
            }
            if (res.data.user_email_address!==null){
                setProfileDetails(prevState => ({
                    ...prevState,
                    ['profileEmail']: res.data.user_email_address
                }))
            }
            if (res.data.user_contact_number!==null){
                setProfileDetails(prevState => ({
                    ...prevState,
                    ['profileContactNo']: res.data.user_contact_number
                }))
            }



            setProfileLoading(false)
        })
        .catch(error => Sentry.captureException(error))
        .finally(() => {
            setProfileLoading(false)
        })

    }, [])




    return <>
       {!profileLoading ? 

       <form onSubmit={event => {
        event.preventDefault()
        return validateProfileDetails()
    }}
        method="POST">


<div className="grid gap-4 p-5 lg:grid-cols-12 lg:p-10">


        
            
        <div className="col-span-12 lg:col-span-6 mt-2">
            <label htmlFor="profileIDType" className="block text-sm font-medium text-gray-700">ID
                type: <span
                    className="text-red-600">*</span></label>
            <select name="profileIDType" id="profileIDType"
                    defaultValue="selected"
                    autocomplete="profileIDType"
                    className={`style-select ${(validProfileDetails.profileIDType) ? `border-red-700` : `border-gray-300`}`}

                    onChange={event => {
                        setProfileDetails(prevState => ({
                            ...prevState,
                            ['profileIDType']: event.target.value
                        }))
                    }}
                    value={profileDetails.profileIDType}
            >
                <option value="selected">Select option</option>
                <option value="SAID">SA ID</option>
                <option value="PASS">Passport</option>
                <option value="NAID">Namibian ID</option>
                <option value="BWID">Botswana ID</option>
            </select>
        </div>

        <div className="col-span-12 lg:col-span-6 mt-2">
            <label htmlFor="profileID"
                    className="block text-sm font-medium text-gray-700">ID/Passport number: <span
                className="text-red-600">*</span> <span
                className="text-gray-300">(no spaces)</span></label>
            <input id="profileID"
                    name="profileID"
                    type="text"
                    className={`style-input ${(validProfileDetails.profileID) ? `border-red-700` : `border-gray-300` }`}
        
                    onChange={event => {
                        setProfileDetails(prevState => ({
                            ...prevState,
                            [`profileID`]: event.target.value
                        }))
                    }}
                    value={profileDetails.profileID} 
            />
        </div>


        <div className="col-span-12 lg:col-span-6 mt-2">
            <label htmlFor="profileTitle" className="block text-sm font-medium text-gray-700">Title: <span
                    className="text-red-600">*</span></label>
            <select name="profileTitle" id="profileTitle"
                    defaultValue="selected"
                    autocomplete="profileTitle"
                    className={`style-select ${(validProfileDetails.profileTitle) ? `border-red-700` : `border-gray-300`}`}

                    onChange={event => {
                        setProfileDetails(prevState => ({
                            ...prevState,
                            ['profileTitle']: event.target.value
                        }))
                    }}
                    value={profileDetails.profileTitle}
            >
                <option value="selected">Select option</option>
                    <option value="MR">Mr</option>
                    <option value="MRS">Mrs</option>
                    <option value="MX">Mx</option>
                    <option value="MISS">Miss</option>
                    <option value="MS">Ms</option>
                    <option value="PROF">Prof</option>
                    <option value="DR">Dr</option>
                    <option value="SIR">Sir</option>
            </select>
        </div>



        <div className="col-span-12 lg:col-span-6 mt-2">
            <label htmlFor="profileInitials"
                    className="block text-sm font-medium text-gray-700">Initials: <span
                className="text-red-600">*</span></label>
            <input id="profileInitials"
                    name="profileInitials"
                    type="text"
                    className={`style-input ${(validProfileDetails.profileInitials) ? `border-red-700` : `border-gray-300` }`}
        
                    onChange={event => {
                        setProfileDetails(prevState => ({
                            ...prevState,
                            [`profileInitials`]: event.target.value
                        }))
                    }}
                    value={profileDetails.profileInitials} 
            />
        </div>

        



        <div className="col-span-12 lg:col-span-6 mt-2">
            <label htmlFor="profileFirstname"
                    className="block text-sm font-medium text-gray-700">First name: <span
                className="text-red-600">*</span></label>
            <input id="profileFirstname"
                    name="profileFirstname"
                    type="text"
                    className={`style-input ${(validProfileDetails.profileFirstname) ? `border-red-700` : `border-gray-300` }`}
        
                    onChange={event => {
                        setProfileDetails(prevState => ({
                            ...prevState,
                            [`profileFirstname`]: event.target.value
                        }))
                    }}
                    value={profileDetails.profileFirstname} 
            />
        </div>


        <div className="col-span-12 lg:col-span-6 mt-2">
            <label htmlFor="profileLastname"
                    className="block text-sm font-medium text-gray-700">Last name: <span
                className="text-red-600">*</span></label>
            <input id="profileLastname"
                    name="profileLastname"
                    type="text"
                    className={`style-input ${(validProfileDetails.profileLastname) ? `border-red-700` : `border-gray-300` }`}
                
                    onChange={event => {
                        setProfileDetails(prevState => ({
                            ...prevState,
                            [`profileLastname`]: event.target.value
                        }))
                    }}
                    value={profileDetails.profileLastname} 
            />
        </div>



        <div className="col-span-12 lg:col-span-6 mt-2">
            <label htmlFor="profileEmail"
                    className="block text-sm font-medium text-gray-700">Email address: <span
                className="text-red-600">*</span></label>
            <input id="profileEmail"
                    name="profileEmail"
                    type="text"
                    className={`style-input ${(validProfileDetails.profileEmail) ? `border-red-700` : `border-gray-300` }`}
                
                    onChange={event => {
                        setProfileDetails(prevState => ({
                            ...prevState,
                            [`profileEmail`]: event.target.value
                        }))
                    }}
                    value={profileDetails.profileEmail} 
            />
        </div>


        <div className="col-span-12 lg:col-span-6 mt-2">
            <label htmlFor="profileContactNo"
                    className="block text-sm font-medium text-gray-700">Contact number: <span
                className="text-red-600">*</span> <span
                className="text-gray-300">(no spaces)</span></label>
            <input id="profileContactNo"
                    name="profileContactNo"
                    type="text"
                    className={`style-input ${(validProfileDetails.profileContactNo) ? `border-red-700` : `border-gray-300` }`}
                
                    onChange={event => {
                        setProfileDetails(prevState => ({
                            ...prevState,
                            [`profileContactNo`]: event.target.value
                        }))
                    }}
                    value={profileDetails.profileContactNo} 
            />
        </div>

        <div className="col-span-12 lg:col-span-12 mt-2">

            <label
                className=" rounded-full text-sm cursor-pointer focus:outline-none py-2 lg:mx-2">
                <input type="checkbox"
                        name="profileChangePass"
                        className="h-4 w-4 mt-0.5 cursor-pointer"
                        aria-labelledby="profileChangePass"
                        value={changePasswordChecked}
                        onChange={event => {
                            setProfileDetails(prevState => ({
                                ...prevState,
                                ['profileChangePass']: event.target.value
                            }))
                        }}
                        onClick={()=>setChangePasswordChecked(changePasswordChecked?false:true)}
                        checked={changePasswordChecked}
                        />
                <span className="ml-1">Change Password</span>
            </label>

            
        </div>

        {changePasswordChecked && <>
        <div className="col-span-12 lg:col-span-6 mt-2">
            <label htmlFor="profileOldPassword"
                    className="block text-sm font-medium text-gray-700">Current password: <span
                className="text-red-600">*</span></label>
            <input id="profileOldPassword"
                    name="profileOldPassword"
                    type="password"
                    className={`style-input ${(validProfileDetails.profileOldPassword) ? `border-red-700` : `border-gray-300` }`}
                
                    onChange={event => {
                        setProfileDetails(prevState => ({
                            ...prevState,
                            [`profileOldPassword`]: event.target.value
                        }))
                    }}
                    value={profileDetails.profileOldPassword} 
            />
        </div>



        <div className="col-span-12 lg:col-span-6 mt-2">
            <label htmlFor="profilePassword"
                    className="block text-sm font-medium text-gray-700">New password: <span
                className="text-red-600">*</span></label>
            <input id="profilePassword"
                    name="profilePassword"
                    type="password"
                    className={`style-input ${(validProfileDetails.profilePassword) ? `border-red-700` : `border-gray-300` }`}
            
                    onChange={event => {
                        setProfileDetails(prevState => ({
                            ...prevState,
                            [`profilePassword`]: event.target.value
                        }))
                    }}
                    value={profileDetails.profilePassword} 
            />
        </div>

        </>}



        <div className="col-span-12 lg:col-span-12 mt-2">

            <label
                className=" rounded-full text-sm cursor-pointer focus:outline-none py-2 lg:mx-2">
                <input type="checkbox"
                        name="profileMarketing"
                        className="h-4 w-4 mt-0.5 cursor-pointer"
                        aria-labelledby="profileMarketing"
                        value={marketingChecked}
                        onChange={event => {
                            setProfileDetails(prevState => ({
                                ...prevState,
                                ['profileMarketing']: event.target.value
                            }))
                        }}
                        onClick={()=>setMarketingChecked(marketingChecked?false:true)}
                        checked={marketingChecked}
                        />
                <span className="ml-1">Receive marketing and promotional emails from Bluu Car Rental</span>
            </label>

            
        </div>


        <div className="col-span-12 lg:col-span-12 mt-2">

            {formSubmitted &&
            <div className="col-span-12">

                <div className="bg-green-100 border-t-4 border-green-300 rounded-b-lg p-6 shadow-sm mb-5" role="alert">
                    <div className="flex">
                        <div>
                            <p>Your profile has been successfully updated.</p>
                        </div>
                    </div>
                </div>

            </div>}

            <div className="col-span-12 text-red-500 text-right my-1">{profileError}</div>
            <div className="clear-both"/>

            <button 
                type="submit" 
                disabled={loading}
                className={`float-right inline-flex justify-center btn-light ml-2 ${loading ? `bg-gray-300 hover:bg-gray-300` : `bg-blue-400 hover:bg-blue-400`}`}>
                Update Profile
            </button>
            {loading && <div className="mt-2 float-right"><Loader/></div>}
            
        </div>
      
        </div>
        </form>

            : 
            <div className="mt-2 text-center p-5 lg:p-10 "><Loader/><br /><br />Loading User Profile</div>
            }
            
        
    </>
}
export default UserProfileDetails;
