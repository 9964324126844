import { useEffect, useState } from 'react'

import GetQuote from 'components/get-quote/'
import Header from 'components/header'
import Footer from 'components/footer'
import { NavLink } from 'react-router-dom'
import { ApiConsumer } from 'api/ApiConsumer'
import { useParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import BookNowBanner from 'components/footer/booknow-banner'
import { Helmet } from 'react-helmet'

const HomePage = () => {
  const [specialsData, setSpecialsData] = useState([])
  const [specialsLoaded, setSpecialsLoaded] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)

    //Get specials
    const specialsUrl = 'https://cms.bluucarrental.com/wp-json/wp/v2/specials'

    ApiConsumer.get(specialsUrl)
      .then((res) => {
        let specials = res.data.filter(
          (special) => special.status === 'publish',
        )
        setSpecialsData(specials)
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
      .finally(() => {
        setSpecialsLoaded(true)
      })
  }, [])

  return (
    <>
      <Helmet>
        <title>Car Hire in South Africa | Bluu Car Rental</title>
      </Helmet>

      <Header />

      <div className="relative py-10 lg:py-20 bg-gray-200 ">
        <div className="container mx-auto px-4">
          <div className="w-full md:w-5/6 lg:w-3/5 mx-auto">
            <GetQuote />
          </div>
        </div>
      </div>

      <div className="relative pt-8 pb-20 mt-12 bg-white">
        <div className="container mx-auto px-4">
          <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {specialsLoaded
              ? specialsData
                  .sort((a, b) => (a.menu_order > b.menu_order ? 1 : -1))
                  .map((special, x) => (
                    <div
                      key={x}
                      className="flex flex-col shadow-md rounded-md overflow-hidden"
                    >
                      <div
                        className={`bg-blue-400 h-48 text-center ${
                          !special.fimg_url && `p-6`
                        }`}
                      >
                        {special.fimg_url ? (
                          <img
                            className="h-48 w-full object-cover"
                            src={special.fimg_url}
                            alt={special.title.rendered}
                          />
                        ) : (
                          <div className="text-gradient ">
                            <span className="text-5xl uppercase">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: special.title.rendered,
                                }}
                              />
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="bg-white p-6 text-center">
                        <a
                          href={special.acf.special_promo_link}
                          className="promo-title"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: special.title.rendered,
                            }}
                          />
                        </a>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: special.content.rendered,
                          }}
                        />
                        <a
                          href={special.acf.special_promo_link}
                          className="promo-readmore"
                        >
                          {special.acf.special_promo_link_text}
                        </a>
                      </div>
                    </div>
                  ))
              : null}
          </div>
        </div>
      </div>

      <BookNowBanner />

      <Footer />
    </>
  )
}
export default HomePage
