import {useEffect} from "react";
import ConfirmationDetails from "components/confirmation-details/";
import Header from "components/header";
import Footer from "components/footer";
import {Helmet} from "react-helmet";

const ConfirmationDetailsPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return <>
    <Helmet>
        <title>Confirmation Details | Bluu Car Rental</title>
    </Helmet>
    <Header />
    <div className="container m-auto py-8 px-4">
        <div className="flex flex-wrap">
            <ConfirmationDetails />
        </div>
    </div>
    <Footer />
    </>
  
}
export default ConfirmationDetailsPage;
