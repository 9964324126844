import { useState, useEffect, useContext } from "react";
import { API_ROUTES } from "routes/api";
import { ApiConsumer } from "api/ApiConsumer";
import Loader from "components/loader";
import Modals from "components/modals/index";
import vehiclePlaceholder from "assets/images/BLUU-car-booking-01.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPrint,
  faEnvelope,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import VehiclePreview from "components/availability/vehicle-preview";
import VehicleCostsSection from "components/availability/vehicle-costs-section";
import { CONSTANTS } from "contants/constants";
import { error } from "autoprefixer/lib/utils";
import { QuoteContext, SaveQuoteContext } from "contexts/quote";
import { VehicleContext } from "contexts/vehicle";
import { WEBSITE_ROUTES } from "routes/website";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useUtils } from "hooks/utils";
import { DetailsContext } from "contexts/details";
import DownloadAsPdf from "components/share/download-pdf";
import { UserContext } from "contexts/user";

const VehicleCostsSidebar = ({
  bookingErrorMessage = "",
  showContinue = true,
  loading = false,
  addOnClicked = false,
  setSelectedVehicle,
  isRecalcError = false,
}) => {
  const [visibleModal, setVisibleModal] = useState("");
  const [pagePostModalSlug, setPagePostModalSlug] = useState("");
  const [loginAccountType, setLoginAccountType] = useState(
    CONSTANTS.LOGIN_ACCOUNT_TYPE.CORPORATE
  );
  const [showMobileCosts, setShowMobileCosts] = useState(false);

  let namibiaBranches = ["WH", "WB", "KM", "WT"];

  var numbFormatter = new Intl.NumberFormat("en-US");
  const [totalCosts, setTotalCosts] = useState(0);
  const { details, setDetails } = useContext(DetailsContext);
  const [error, setError] = useState("");
  const [quoteSent, setQuoteSent] = useState(false);
  const [isSendingQuote, setIsSendingQuote] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [validSendQuoteDetails, setValidSendQuoteDetails] = useState({});
  let { validEmail } = useUtils();
  let { user } = useContext(UserContext);
  /*const [isQuoteLoaded, setIsQuoteLoaded] = useState(false)*/
  const [bookingCosts, setBookingCosts] = useState({
    vehicleCosts: [],
    otherCosts: [],
    liabilityCosts: [],
    addonCosts: [],
  });
  const [showSendToEmail, setShowSendToEmail] = useState(false);
  let history = useHistory();
  let { quote } = useContext(QuoteContext);
  let { vehicle } = useContext(VehicleContext);
  let { saveQuote } = useContext(SaveQuoteContext);
  let tmpTotalCosts = 0;

  function SendQuote(method) {
    setIsSendingQuote(true);

    ApiConsumer.post(
      "https://weblogs.bluevinegroup.co.za/booking/send-external-email/ajax/",
      JSON.stringify({
        page: "get-quote",
        isQuote: true,
        ...details,
        ...saveQuote,
        quote,
      })
    )
      .then((res) => {
        setShowSendToEmail(false);
      })
      .catch((error) => Sentry.captureException(error))
      .finally(() => {
        setIsSendingQuote(false);
        setQuoteSent(true);
      });

    ApiConsumer.post(API_ROUTES.QUOTE.SAVE, {
      ...details,
      ...saveQuote,
      method: method,
      requestReference: quote?.quoteNumber,
    })
      .then((res) => {
        setShowSendToEmail(false);
      })
      .catch((error) => Sentry.captureException(error))
      .finally(() => {
        setIsSendingQuote(false);
        setQuoteSent(true);
      });
  }

  useEffect(() => {
    let vCosts = [];
    let oCosts = [];
    let lCosts = [];
    let aCosts = [];

    quote?.quoteLines?.quoteLine?.map((quoteLine, index) => {
      //Vehicle costs
      if (
        quoteLine.quoteLineCode === "R" ||
        (quoteLine.quoteLineCode === "W" &&
          (quoteLine.quoteLineDesc1.includes("CDW") ||
            quoteLine.quoteLineDesc1.includes("SCDW") ||
            quoteLine.quoteLineDesc1.includes("TLW") ||
            quoteLine.quoteLineDesc1.includes("STLW")))
      ) {
        vCosts = [...vCosts, quoteLine];
        tmpTotalCosts += parseFloat(quoteLine.quoteLineChrg);
      }

      //Other costs
      if (
        quoteLine.quoteLineCode === "S" ||
        (quoteLine.quoteLineCode === "A" &&
          (quoteLine.quoteLineDesc1.includes("One Way Fee") ||
            quoteLine.quoteLineDesc1.includes("Contract fee")))
      ) {
        oCosts = [...oCosts, quoteLine];
        tmpTotalCosts += parseFloat(quoteLine.quoteLineChrg);
      }

      //Liabilities
      if (quoteLine.quoteLineCode === "C") lCosts = [...lCosts, quoteLine];

      //Add-ons
      if (
        quoteLine.quoteLineCode !== "R" &&
        quoteLine.quoteLineCode !== "S" &&
        quoteLine.quoteLineCode !== "C" &&
        ((quoteLine.quoteLineCode === "W" &&
          !quoteLine.quoteLineDesc1.includes("CDW") &&
          !quoteLine.quoteLineDesc1.includes("SCDW") &&
          !quoteLine.quoteLineDesc1.includes("TLW") &&
          !quoteLine.quoteLineDesc1.includes("STLW")) ||
          (quoteLine.quoteLineCode === "A" &&
            !quoteLine.quoteLineDesc1.includes("One Way Fee") &&
            !quoteLine.quoteLineDesc1.includes("Contract fee")))
      ) {
        aCosts = [...aCosts, quoteLine];
        tmpTotalCosts += parseFloat(quoteLine.quoteLineChrg);
      }
    });

    let costsObj = {
      vehicleCosts: vCosts,
      otherCosts: oCosts,
      liabilityCosts: lCosts,
      addonCosts: aCosts,
    };

    let fee =
      details.fee !== undefined &&
      details.fee !== null &&
      !isNaN(details.fee) &&
      details.fee !== ""
        ? details.fee
        : 0;
    fee = parseFloat(tmpTotalCosts) + parseFloat(fee);

    setTotalCosts(fee);
    setBookingCosts(costsObj);
  }, [quote, details]);

  useEffect(() => {
    setDetails((prevState) => ({
      ...prevState,
      ["fee"]:
        details.fee !== undefined &&
        details.fee !== null &&
        !isNaN(details.fee) &&
        details.fee !== ""
          ? details.fee
          : 0,
    }));
  }, []);

  return (
    <>
      <Modals
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        loginAccountType={loginAccountType}
        setLoginAccountType={setLoginAccountType}
        pagePostModalSlug={pagePostModalSlug}
        setPagePostModalSlug={setPagePostModalSlug}
      />

      <div className="hidden lg:block md:block w-full p-0 bg-white bottom-0 left-0 lg:relative lg:p-5 lg:pr-0 lg:w-3/12">
        <div className="grid border border-gray-200 rounded-lg top-4 overscroll-auto-y overflow-auto lg:grid-cols-12">
          {vehicle.selectedWaiver ? ( //If vehicle is selected
            <div className="col-span-12 bg-blue-400 p-3 text-white text-xl font-bold text-center">
              Total Cost:{" "}
              {namibiaBranches.includes(details?.PICKUP_BRANCH) ? "N$" : "R"}
              {!loading ? " 0 " : numbFormatter.format(totalCosts)}
            </div>
          ) : (
            <div className="col-span-12 text-center p-3">
              <img
                alt="Vehicle Placeholder"
                src={vehiclePlaceholder}
                className="w-full h-auto m-auto"
              />
              <div className="w-full">
                Please select your preferred vehicle.
              </div>
            </div>
          )}

          <VehicleCostsSection
            headerTitle={`${details.isD2D ? "Transfer costs" : "Rental costs"}`}
            bookingCosts={bookingCosts.vehicleCosts}
            loading={loading}
            rentalDuration={quote.rentalDuration}
          />
          <VehicleCostsSection
            headerTitle={"Add-ons"}
            bookingCosts={bookingCosts.addonCosts}
            loading={loading}
            rentalDuration={quote.rentalDuration}
          />
          <VehicleCostsSection
            headerTitle={`${
              details.isD2D ? "Other transfer costs" : "Other rental costs"
            }`}
            bookingCosts={bookingCosts.otherCosts}
            loading={loading}
            rentalDuration={quote.rentalDuration}
          />
          {details.isD2D &&
            (Object.keys(user).length === 0 || user.accountNo === null) && (
              <div className="col-span-12 bg-gray-100 p-3 cursor-pointer focus:outline-none border-b border-gray-200">
                <label
                  htmlFor="fee"
                  className="block text-sm font-medium text-gray-700"
                >
                  Enter total amount for additional D2D fees: (e.g. D2D Toll
                  Gate) <span className="text-red-600">*</span>
                </label>
                <input
                  id="fee"
                  name="fee"
                  type="text"
                  disabled={
                    details.isD2D
                      ? details?.email === undefined
                        ? false
                        : true
                      : false
                  }
                  onChange={(event) => {
                    setDetails((prevState) => ({
                      ...prevState,
                      ["fee"]: isNaN(event.target.value)
                        ? 0
                        : event.target.value,
                    }));
                  }}
                  className={`style-input`}
                  value={details.fee}
                />
              </div>
            )}

          {loading && showContinue ? (
            <>
              <div className="col-span-12 m-3">
                <p className="float-left text-blue-400 text-xl font-bold">
                  Total Cost:
                </p>
                <p className="float-right text-blue-400 text-xl font-bold">
                  {namibiaBranches.includes(details?.PICKUP_BRANCH)
                    ? "N$"
                    : "R"}
                  {!loading ? " - " : numbFormatter.format(totalCosts)}
                </p>

                <div className="clear-both" />
                <span className="text-red-500 text-sm">
                  {bookingErrorMessage}
                </span>
              </div>

              {showContinue && vehicle.selectedWaiver && totalCosts > 0 && (
                <div className="col-span-12 bg-blue-900 text-white p-3 text-center">
                  {addOnClicked ? (
                    <button
                      type="button"
                      className=" ml-2 px-4 py-2 border border-transparent rounded-full shadow-sm text-sm bg-blue-400 focus:outline-none text-white"
                      onClick={() =>
                        setSelectedVehicle(vehicle, vehicle.selectedWaiver)
                      }
                    >
                      Update Costs
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        disabled={
                          vehicle.selectedWaiver &&
                          totalCosts > 0 &&
                          !isRecalcError
                            ? false
                            : true
                        }
                        className={` px-4 py-1 float-right border border-transparent rounded-full shadow-sm text-lg text-white ${
                          vehicle.selectedWaiver &&
                          totalCosts > 0 &&
                          !isRecalcError
                            ? `bg-blue-400`
                            : `bg-gray-300`
                        }  focus:outline-none`}
                        onClick={() => {
                          history.push({
                            pathname: WEBSITE_ROUTES.CONFIRMATION_DETAILS.link,
                            state: details,
                          });
                        }}
                      >
                        Book
                      </button>
                      {/* details.accountType==="C" && <DownloadAsPdf
                tableTitle={`Quote Download`}
                tableHeaders={tableHeaders}
                tableData={[row]}
                tableDataResult={row.name}
                filename={`Programme: ${row.name}`}
              /> */}
                    </>
                  )}

                  <div className="clear-both" />
                  {details.isD2D && (
                    <>
                      <div className="mt-1">
                        {quoteSent ? (
                          <>
                            <FontAwesomeIcon icon={faCheck} /> Quote
                            successfully sent
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              className=" bg-blue-400 text-white text-3xl mr-2 p-2 rounded-full cursor-pointer focus:outline-none"
                              onClick={() => {
                                //setShowSendToEmail(showSendToEmail ? false : true)
                                setVisibleModal(CONSTANTS.MODAL_TYPE.SENDQUOTE);
                                setQuoteSent(false);
                              }}
                            />

                            <span
                              className="hover:underline cursor-pointer"
                              onClick={() => {
                                //setShowSendToEmail(showSendToEmail ? false : true)
                                setVisibleModal(CONSTANTS.MODAL_TYPE.SENDQUOTE);
                                setQuoteSent(false);
                              }}
                            >
                              Send your quotation
                            </span>
                          </>
                        )}
                      </div>
                      <div className="clear-both" />{" "}
                    </>
                  )}
                </div>
              )}
            </>
          ) : null}
        </div>
        <span
          onClick={() => {
            setVisibleModal(CONSTANTS.MODAL_TYPE.PAGEPOST);
            details.isD2D
              ? setPagePostModalSlug("door2door-rental-terms-and-conditions")
              : setPagePostModalSlug("rental-terms-and-conditions");
          }}
          className="float-right text-base font-normal text-blue-400 hover:underline cursor-pointer"
        >
          Read Terms &amp; Conditions
        </span>
      </div>

      <div className="fixed lg:hidden md:hidded w-full p-1 bottom-0 left-0 z-40">
        <div className="grid border border-gray-200 bg-white rounded-lg top-4 overscroll-auto-y overflow-auto lg:grid-cols-12">
          {vehicle.selectedWaiver ? ( //If vehicle is selected
            <div
              className="col-span-12 bg-blue-400 p-3 text-white text-xl font-bold text-center"
              onClick={() => {
                setShowMobileCosts(!showMobileCosts);
              }}
            >
              {showMobileCosts ? `Hide Costs` : `View Costs`}:{" "}
              {namibiaBranches.includes(details?.PICKUP_BRANCH) ? "N$" : "R"}
              {!loading ? " 0 " : numbFormatter.format(totalCosts)}
              {!loading && <Loader />}
            </div>
          ) : (
            <div className="col-span-12 text-center pt-2">
              <div className="w-full">
                Please select your preferred vehicle.
              </div>
            </div>
          )}

          {showMobileCosts && (
            <>
              <VehicleCostsSection
                headerTitle={`${
                  details.isD2D ? "Transfer costs" : "Rental costs"
                }`}
                bookingCosts={bookingCosts.vehicleCosts}
                loading={loading}
                rentalDuration={quote.rentalDuration}
              />
              <VehicleCostsSection
                headerTitle={"Add-ons"}
                bookingCosts={bookingCosts.addonCosts}
                loading={loading}
                rentalDuration={quote.rentalDuration}
              />
              <VehicleCostsSection
                headerTitle={`${
                  details.isD2D ? "Other transfer costs" : "Other rental costs"
                }`}
                bookingCosts={bookingCosts.otherCosts}
                loading={loading}
                rentalDuration={quote.rentalDuration}
              />
              {details.isD2D &&
                (Object.keys(user).length === 0 || user.accountNo === null) && (
                  <div className="col-span-12 bg-gray-100 p-3 cursor-pointer focus:outline-none border-b border-gray-200">
                    <label
                      htmlFor="fee"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Enter total amount for additional D2D fees: (e.g. D2D Toll
                      Gate) <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="fee"
                      name="fee"
                      type="text"
                      disabled={
                        details.isD2D
                          ? details?.email === undefined
                            ? false
                            : true
                          : false
                      }
                      onChange={(event) => {
                        setDetails((prevState) => ({
                          ...prevState,
                          ["fee"]: event.target.value,
                        }));
                      }}
                      className={`style-input`}
                      value={details.fee}
                    />
                  </div>
                )}
            </>
          )}

          {loading && showContinue ? (
            <>
              <div className="col-span-12 mx-3 mt-3 mb-2">
                <p className="float-left text-blue-400 text-xl font-bold">
                  Total Cost:
                </p>
                <p className="float-right text-blue-400 text-xl font-bold">
                  {namibiaBranches.includes(details?.PICKUP_BRANCH)
                    ? "N$"
                    : "R"}
                  {!loading ? " - " : numbFormatter.format(totalCosts)}
                </p>

                <div className="clear-both" />
                <span className="text-red-500 text-sm">
                  {bookingErrorMessage}
                </span>
                <div className="clear-both" />
              </div>

              {showContinue && vehicle.selectedWaiver && totalCosts > 0 && (
                <div className="col-span-12 bg-blue-900 text-white p-3 text-center">
                  {addOnClicked ? (
                    <button
                      type="button"
                      className="ml-2 px-4 py-2 border border-transparent rounded-full shadow-sm text-sm bg-blue-400 focus:outline-none text-white"
                      onClick={() =>
                        setSelectedVehicle(vehicle, vehicle.selectedWaiver)
                      }
                    >
                      Update Costs
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled={
                        vehicle.selectedWaiver &&
                        totalCosts > 0 &&
                        !isRecalcError
                          ? false
                          : true
                      }
                      className={`px-4 py-1 border border-transparent rounded-full shadow-sm text-lg text-white ${
                        vehicle.selectedWaiver &&
                        totalCosts > 0 &&
                        !isRecalcError
                          ? `bg-blue-400`
                          : `bg-gray-300`
                      }  focus:outline-none`}
                      onClick={() => {
                        history.push({
                          pathname: WEBSITE_ROUTES.CONFIRMATION_DETAILS.link,
                          state: details,
                        });
                      }}
                    >
                      Book
                    </button>
                  )}

                  <div className="clear-both" />
                  {details.isD2D && (
                    <>
                      <div className="mt-1">
                        {quoteSent ? (
                          <>
                            <FontAwesomeIcon icon={faCheck} /> Quote
                            successfully sent
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              className=" bg-blue-400 text-white text-3xl mr-2 p-2 rounded-full cursor-pointer focus:outline-none"
                              onClick={() => {
                                //setShowSendToEmail(showSendToEmail ? false : true)
                                setVisibleModal(CONSTANTS.MODAL_TYPE.SENDQUOTE);
                                setQuoteSent(false);
                              }}
                            />
                            <span
                              className="hover:underline cursor-pointer"
                              onClick={() => {
                                //setShowSendToEmail(showSendToEmail ? false : true)
                                setVisibleModal(CONSTANTS.MODAL_TYPE.SENDQUOTE);
                                setQuoteSent(false);
                              }}
                            >
                              Send your quotation
                            </span>
                          </>
                        )}
                      </div>

                      <div className="clear-both" />
                    </>
                  )}
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default VehicleCostsSidebar;
