import VehicleDetails from "components/availability/vehicle-details";

const FleetItem = ({allFleet}) => {

    return <>
    <div className="w-full px-4 lg:py-5 m-auto">
        <div className="grid lg:grid-cols-12 gap-4">
        { 
        allFleet.map((category,i)=>(
                <>
                <div key={i} className="col-span-12 bg-blue-900 text-white text-2xl py-3 px-5 rounded-md"><span dangerouslySetInnerHTML={{__html: category.name}} /></div>
                {
                    category.fleet?.sort((a, b) => (a.menu_order > b.menu_order) ? 1 : -1).map((vehicle,x)=>(

                        <div key={x} className="col-span-12 lg:col-span-6">

                            <div className="grid grid-cols-12 relative rounded-lg border border-gray-100">

                                <div className={`col-span-12 p-3 pb-0 z-10`}>
                                    <h2 className={`text-gray-900 text-2xl font-semibold leading-none w-full mt-2`}>{ vehicle.acf.vehicle_make_model }</h2>
                                    <span className="text-md text-blue-400">or similar</span>
                                    <div className="clear-both" />
                                </div>
                                <div className={`col-span-12 lg:col-span-6 relative`}>
                                    <img className="rounded-lg" alt="" src={vehicle?.fimg_url} />
                                    <div className="absolute rounded-full w-20 h-20 bottom-4 right-4 border border-blue-400 text-blue-400 bg-white text-center px-2 pt-2.5 pb-2 leading-none"><div className="text-md">Group</div><div className="text-4xl leading-none">{vehicle.title.rendered.replace("Group ","")}</div></div>
                                </div>

                                <div className={`col-span-12 lg:col-span-6 bg-white p-3 text-gray-900 text-sm rounded-lg`}>
                                    <div className="leading-5">

                                        <div className="font-bold uppercase">Vehicle Details</div>

                                        <VehicleDetails vehicleData={[vehicle]} />
                                    </div>
                                </div>

                            </div>

                        </div>
                    ))
                }
                </>
            ))
        }
        
        
        </div>
    </div>
    </>
}
export default FleetItem;
