import UserProfile from "components/user-profile/";
import Header from "components/header";
import Footer from "components/footer";
import {useContext, useEffect} from "react";
import {UserContext} from "contexts/user";
import {useHistory} from "react-router-dom";
import {Helmet} from "react-helmet";

const UserProfilePage = () => {

    let {user} = useContext(UserContext)
    let history = useHistory()

    useEffect(() => {
        if (sessionStorage.getItem('sessionUser')!==null && sessionStorage.getItem('sessionUser')!==undefined){
          let userData = JSON.parse(sessionStorage.getItem('sessionUser'))
          if (userData.accountEmail===null || userData.accountEmail===undefined || userData.accountEmail==="")
            history.push('/')
        }
    }, [])
    
    return <>

    <Helmet>
        <title>User Profile | Bluu Car Rental</title>
    </Helmet>

    <Header />

    <div className="w-full bg-gray-200 text-center">
            <div className="container m-auto py-10 max-w-screen-xl">
                <div className="col-span-12 px-4 lg:p-6">
                    <h1 className="text-3xl lg:text-5xl text-blue-900 pb-5">Hello {user?.accountName}</h1>
                    <p>Your online profile allows you to efficiently manage your quotes and reservations.</p>
                </div>
            </div>
        </div>
  
    <div className="relative bg-gray-200 pb-20 px-4 ">
            <div className="mx-auto w-full lg:w-6/12">
                <div className="grid lg:grid-cols-12 gap-4">
                  <div className="col-span-12 ">
                      <UserProfile activeTab={1} />
                  </div>
                </div>
            </div>
    </div>

    <Footer />
    </>
}
export default UserProfilePage;
