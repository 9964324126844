import {createContext} from "react";

export const QuoteContext = createContext({
    quote: {},
    setQuote: () => {
    }
});

export const SaveQuoteContext = createContext({
    saveQuote: {},
    setSaveQuote: () => {
    }
});
