import { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCar,
  faClock,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { add, isAfter, isEqual } from "date-fns";
import Select, { createFilter } from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { DetailsContext } from "contexts/details";
import { CONSTANTS } from "contants/constants";
import { BranchContext } from "contexts/branch";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Autocomplete } from "@react-google-maps/api";
import { useUtils } from "hooks/utils";

const D2DSectionSelectBranch = ({
  type,
  setMapLocations,
  setValidDistance,
}) => {
  const { details, setDetails } = useContext(DetailsContext);
  const { branches } = useContext(BranchContext);
  let { getDate, getTime } = useUtils();

  const openDateRef = useRef(null);
  const openTimeRef = useRef(null);
  const openCalendarDate = () => {
    openDateRef.current.setOpen(true);
  };
  const openCalendarTime = () => {
    openTimeRef.current.setOpen(true);
  };
  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      height: "3rem",
      zIndex: 100000,
      paddingLeft: 25,
      borderRadius: "9999px",
      backgroundColor: "transparent",
      boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    }),
  };

  useEffect(() => {
    setDetails((prevState) => ({
      ...prevState,
      [`${type.toUpperCase().replace("-", "")}_BRANCH_ADDRESS`]: "",
    }));
  }, []);

  const [autocompleteObj, setAutocompleteObj] = useState({});

  const onLoad = (autocomplete) => {
    setAutocompleteObj(autocomplete);
  };
  const onPlaceChanged = () => {
    if (autocompleteObj !== null) {
      setDetails((prevState) => ({
        ...prevState,
        [`${type.toUpperCase().replace("-", "")}_BRANCH_ADDRESS`]:
          autocompleteObj.getPlace().name +
          ` (` +
          autocompleteObj.getPlace().formatted_address +
          `)`,
      }));

      if (
        type.toUpperCase().replace("-", "").toUpperCase() ===
        CONSTANTS.PICKUP.replace("-", "").toUpperCase()
      )
        setMapLocations((prevState) => ({
          ...prevState,
          [`origin`]: autocompleteObj,
        }));
      if (
        type.toUpperCase().replace("-", "").toUpperCase() ===
        CONSTANTS.DROPOFF.replace("-", "").toUpperCase()
      )
        setMapLocations((prevState) => ({
          ...prevState,
          [`destination`]: autocompleteObj,
        }));
      if (
        type.toUpperCase().replace("-", "").toUpperCase() ===
        CONSTANTS.D2D_COLLECTION_ADDRESS1.replace("-", "").toUpperCase()
      )
        setMapLocations((prevState) => ({
          ...prevState,
          [`waypoint1`]: autocompleteObj,
        }));

      if (
        type.toUpperCase().replace("-", "").toUpperCase() ===
        CONSTANTS.D2D_COLLECTION_ADDRESS2.replace("-", "").toUpperCase()
      )
        setMapLocations((prevState) => ({
          ...prevState,
          [`waypoint2`]: autocompleteObj,
        }));
      if (
        type.toUpperCase().replace("-", "").toUpperCase() ===
        CONSTANTS.D2D_DELIVERY_ADDRESS1.replace("-", "").toUpperCase()
      )
        setMapLocations((prevState) => ({
          ...prevState,
          [`waypoint3`]: autocompleteObj,
        }));

      if (
        type.toUpperCase().replace("-", "").toUpperCase() ===
        CONSTANTS.D2D_DELIVERY_ADDRESS2.replace("-", "").toUpperCase()
      )
        setMapLocations((prevState) => ({
          ...prevState,
          [`waypoint4`]: autocompleteObj,
        }));
    } else {
      console.log("Autocomplete is not loaded.");
    }
  };

  return (
    <>
      <div className="col-span-12 text-left font-bold text-blue-900">
        {type.toUpperCase().replace("-", "").toUpperCase() ===
          CONSTANTS.D2D_COLLECTION_ADDRESS1.replace("-", "").toUpperCase() &&
          `ADDITIONAL PICK-UP ADDRESS 1`}

        {type.toUpperCase().replace("-", "").toUpperCase() ===
          CONSTANTS.D2D_COLLECTION_ADDRESS2.replace("-", "").toUpperCase() &&
          `ADDITIONAL PICK-UP ADDRESS 2`}

        {type.toUpperCase().replace("-", "").toUpperCase() ===
          CONSTANTS.D2D_DELIVERY_ADDRESS1.replace("-", "").toUpperCase() &&
          `ADDITIONAL DROPOFF ADDRESS 1`}

        {type.toUpperCase().replace("-", "").toUpperCase() ===
          CONSTANTS.D2D_DELIVERY_ADDRESS2.replace("-", "").toUpperCase() &&
          `ADDITIONAL DROPOFF ADDRESS 2`}

        {type === CONSTANTS.PICKUP && `PICK-UP`}
        {type === CONSTANTS.DROPOFF && `DROP-OFF`}
      </div>
      <div className="col-span-12 relative text-base shadow-sm text-gray-700 bg-white border-gray-300 focus:outline-none rounded-full lg:col-span-6">
        <FontAwesomeIcon icon={faCar} className="style-quote-icons z-10" />
        {/* Child components, such as markers, info windows, etc. */}
        <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
          restrictions={{ country: ["za"] }}
        >
          <input
            id="location"
            name="location"
            type="text"
            className={`style-input mt-0 pl-9 border-0`}
            onChange={(event) => {
              setValidDistance(false);
              setDetails((prevState) => ({
                ...prevState,
                [`${type.toUpperCase().replace("-", "")}_BRANCH_ADDRESS`]:
                  event.value,
              }));
            }}
            styles={customStyles}
            value={
              details[`${type.toUpperCase().replace("-", "")}_BRANCH_ADDRESS`]
            }
          />
        </Autocomplete>
      </div>

      {type === CONSTANTS.PICKUP && (
        <>
          <div className="col-span-8 relative text-base lg:col-span-4">
            <DatePicker
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              ref={openDateRef}
              minDate={
                type === CONSTANTS.PICKUP
                  ? new Date()
                  : new Date(details.PICKUP_DATE)
              }
              dateFormat="iii dd MMM yy"
              selected={details[`${type.toUpperCase().replace("-", "")}_DATE`]}
              onChange={(event) => {
                setValidDistance(false);
                setDetails((prevState) => ({
                  ...prevState,
                  [`${type.toUpperCase().replace("-", "")}_DATE`]: new Date(
                    event
                  ),
                }));

                if (
                  type.toUpperCase().replace("-", "").toUpperCase() ===
                  CONSTANTS.PICKUP.replace("-", "").toUpperCase()
                ) {
                  setDetails((prevState) => ({
                    ...prevState,
                    [`DROPOFF_DATE`]: add(event, { minutes: 1 }),
                  }));
                }
              }}
              className="style-quote-input"
            />
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className="style-quote-icons z-10"
              onClick={() => openCalendarDate()}
            />
          </div>
          <div className="col-span-4 relative text-base lg:col-span-2">
            <DatePicker
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              ref={openTimeRef}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              selected={details[`${type.toUpperCase().replace("-", "")}_TIME`]}
              onChange={(event) => {
                let tmpTime = `${getDate(
                  details[`${type.toUpperCase().replace("-", "")}_DATE`]
                )} ${getTime(event)}`;
                setDetails((prevState) => ({
                  ...prevState,
                  [`${type.toUpperCase().replace("-", "")}_TIME`]: new Date(
                    tmpTime
                  ),
                }));
                if (
                  type.toUpperCase().replace("-", "").toUpperCase() ===
                  CONSTANTS.PICKUP.replace("-", "").toUpperCase()
                ) {
                  setDetails((prevState) => ({
                    ...prevState,
                    [`DROPOFF_TIME`]: add(new Date(tmpTime), { minutes: 1 }),
                  }));
                }
              }}
              className="style-quote-input"
            />
            <FontAwesomeIcon
              icon={faClock}
              className="style-quote-icons z-10"
              onClick={() => openCalendarTime()}
            />
          </div>
        </>
      )}
    </>
  );
};
export default D2DSectionSelectBranch;
