const Navigation = ({
  progressBar,
  setProgressBar,
  validateCustomerDetails,
  setError,
}) => {
  return (
    <>
      <nav aria-label="Progress">
        <ol
          role="list"
          className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0"
        >
          <li className="relative md:flex-1 md:flex">
            <div
              className="cursor-pointer px-4 py-2 group flex items-center w-full lg:px-6 lg:py-4"
              onClick={() => {
                setProgressBar(1)
                setError('')
              }}
            >
              <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-blue-400 rounded-full">
                <span className="text-blue-400">01</span>
              </span>
              <span className="ml-4 font-medium text-blue-400">
                Customer Details
              </span>
            </div>

            <div
              className="hidden md:block absolute top-0 right-0 h-full w-5"
              aria-hidden="true"
            >
              <svg
                className="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>

          <li className="relative md:flex-1 md:flex">
            <div
              className="cursor-pointer px-4 py-2 group flex items-center w-full lg:px-6 lg:py-4"
              aria-current="step"
              onClick={() => validateCustomerDetails()}
            >
              <span
                className={`flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 ${
                  progressBar === 2 ? `border-blue-400` : `border-gray-300`
                } rounded-full`}
              >
                <span
                  className={
                    progressBar === 2 ? `text-blue-400` : `text-gray-500`
                  }
                >
                  02
                </span>
              </span>
              <span
                className={`ml-4 font-medium ${
                  progressBar === 2 ? `text-blue-400` : `text-gray-500`
                }`}
              >
                Payment Details
              </span>
            </div>

            <div
              className="hidden md:block absolute top-0 right-0 h-full w-5"
              aria-hidden="true"
            >
              <svg
                className="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>

          <li className="relative md:flex-1 md:flex">
            <div
              className="cursor-pointer px-4 py-2 group flex items-center w-full lg:px-6 lg:py-4"
              aria-current="step"
            >
              <span
                className={`flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 ${
                  progressBar === 3 ? `border-blue-400` : `border-gray-300`
                } rounded-full`}
              >
                <span
                  className={
                    progressBar === 3 ? `text-blue-400` : `text-gray-500`
                  }
                >
                  03
                </span>
              </span>
              <span
                className={`ml-4 font-medium ${
                  progressBar === 3 ? `text-blue-400` : `text-gray-500`
                }`}
              >
                Booking Confirmation
              </span>
            </div>
          </li>
        </ol>
      </nav>
    </>
  )
}
export default Navigation
