import {useState,useContext,useEffect} from "react";
import Loader from "components/loader";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faCalendarAlt, faCar, faClock, faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import VehicleImage from 'components/availability/vehicle-image';
import VehicleName from 'components/availability/vehicle-name';
import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";
import {BranchContext} from "contexts/branch";
import * as Sentry from "@sentry/react";
import {useUtils} from "hooks/utils";

const CancelBooking = ({quoteNo, setPage, reservation}) => {

    const [details, setDetails] = useState({})
    const [message, setMessage] = useState('')
    let {branches} = useContext(BranchContext)
    const [showLoader, setShowLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    let {getBranchById, formatDate, formatTime} = useUtils()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    function cancel() {
        setShowLoader(true)
        if (details.email === "" || details.email === null) {
            setErrorMessage('Please provide an Email address.')
            setShowLoader(false)
            return
        }
        if (details.name === "" || details.name === null) {
            setErrorMessage('Please provide a First name.')
            setShowLoader(false)
            return
        }
        if (details.surname === "" || details.surname === null) {
            setErrorMessage('Please provide a Last name.')
            setShowLoader(false)
            return
        }

        const payload = {
            "reservationNo": quoteNo,
            "email": details.email,
            "accountType": details.accountType ?? "I"
        }

        ApiConsumer.post(API_ROUTES.BOOKING.CANCELLED, payload)
            .then(res => {
                setMessage(res.data.errors.error[0].errorMessage)
                window.scrollTo(0, 0);
            })
            .catch(err => Sentry.captureException(err))
            .finally(() => setShowLoader(false))
    }

    return <>

        <div className="grid gap-4 p-5 lg:p-10 lg:grid-cols-12">

            <div className="col-span-12 bg-gray-100">
                {message === "" ? <h2 className="text-2xl mb-2">Cancel Your Booking ({quoteNo})</h2> :
                    <h2 className="text-2xl mb-2">{message}</h2>}
                {message === "" ? <>Your reservation details are below. Simply click on "Cancel Booking" to cancel your
                    booking.</> : null}

            </div>

            {message === "" ? <>
                <div className="col-span-12 lg:col-span-6 bg-white rounded-lg border border-gray-100">
                    <div className="grid lg:grid-cols-12">
                        <div
                            className="col-span-12 leading-none p-3 rounded-tl-lg rounded-tr-lg border-l border-r border-t border-gray-100 z-10">
                            <h2 className="text-gray-900 text-xl font-semibold leading-tight w-full mt-2">{reservation.reservation.vehGroup} - <VehicleName vehicleGrp={reservation.reservation.vehGroup} /></h2>
                            <span className="text-xs text-blue-400">or similar</span>
                        </div>
                        <div className="col-span-12 relative border-l border-r border-gray-100">
                            <VehicleImage vehicleGrp={reservation.reservation.vehGroup} />
                            <div
                                className="absolute rounded-full w-14 h-14 right-4 bottom-4 border border-blue-400 text-blue-400 bg-white text-center px-2 pt-2.5 pb-2 leading-none">
                                <div className="text-xs">Group</div>
                                <div className="text-xl leading-none">{reservation.reservation.vehGroup}</div>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="col-span-12 lg:col-span-6 py-3 px-4 bg-white rounded-lg border border-gray-100">


                    <div className=" leading-6 mb-3">
                        <p className="font-bold text-blue-900 uppercase">Pick-up Location</p>
                        <span className="mr-2"><FontAwesomeIcon icon={faCar}
                                                                className="text-gray-700 text-lg"/> {getBranchById(branches, reservation?.reservation?.pickupBranch)?.branchName}</span><br/>
                        <span className="mr-2"><FontAwesomeIcon icon={faCalendarAlt}
                                                                className="text-gray-700 text-lg"/> {formatDate(reservation.reservation.pickupDate)}</span>
                        <span className="mr-2"><FontAwesomeIcon icon={faClock}
                                                                className="text-gray-700 text-lg"/> {formatTime(reservation.reservation.pickupTime)}</span>
                    </div>


                    <div className=" leading-6 mb-5">
                        <p className="font-bold text-blue-900 uppercase">Drop-off Location</p>
                        <span className="mr-2"><FontAwesomeIcon icon={faCar}
                                                                className="text-gray-700 text-lg"/> {getBranchById(branches, reservation?.reservation?.duebackBranch)?.branchName}</span><br/>
                        <span className="mr-2"><FontAwesomeIcon icon={faCalendarAlt}
                                                                className="text-gray-700 text-lg"/> {formatDate(reservation.reservation.duebackDate)}</span>
                        <span className="mr-2"><FontAwesomeIcon icon={faClock}
                                                                className="text-gray-700 text-lg"/> {formatTime(reservation.reservation.duebackTime)}</span>
                    </div>


                    <p className="font-bold text-blue-900 uppercase">Selected add-ons:</p>
                    <ul>
                        {
                            reservation.quote.products.map((product, index) => {
                                return <li key={index}
                                           className="w-3/6 float-left py-1 pr-1">{product.productDescription} </li>
                            })
                        }
                        {
                            reservation.quote.products.length === 0 &&
                            <li className="w-3/6 float-left py-1 pr-1">No products selected.</li>
                        }
                    </ul>
                </div>

                <div className="col-span-12 ">
                    <p className="text-left font-bold text-blue-900 uppercase">Cancelled by:</p>
                </div>

                <div className="col-span-12 lg:col-span-4">
                    <label htmlFor="CancelledByName"
                           className="block text-sm font-medium text-gray-700">First name: <span
                        className="text-red-600">*</span></label>
                    <input id="CancelledByName" name="CancelledByName" type="text"
                           className="style-input" onChange={event => setDetails(prevState => ({
                        ...prevState,
                        [`name`]: details?.name ?? event.target.value
                    }))}/>
                </div>

                <div className="col-span-12 lg:col-span-4">
                    <label htmlFor="CancelledBySurname"
                           className="block text-sm font-medium text-gray-700">Last name: <span
                        className="text-red-600">*</span></label>
                    <input id="CancelledBySurname" name="CancelledBySurname" type="text"
                           className="style-input" onChange={event => setDetails(prevState => ({
                        ...prevState,
                        [`surname`]: details?.surname ?? event.target.value
                    }))}/>
                </div>

                <div className="col-span-12 lg:col-span-4">
                    <label htmlFor="CancelledByPhoneNo"
                           className="block text-sm font-medium text-gray-700">Email address: <span
                        className="text-red-600">*</span></label>
                    <input id="CancelledByPhoneNo" name="CancelledByPhoneNo" type="text"
                           className="style-input" onChange={event => setDetails(prevState => ({
                        ...prevState,
                        [`email`]: details?.email ?? event.target.value
                    }))}/>
                </div>
                {errorMessage && <div className="col-span-12 text-red-500 text-right">{errorMessage}</div>}
            </> : null}
            <div className="col-span-12">
                <button
                    type="button"
                    onClick={() => setPage("")}
                    className="inline-block float-left px-4 py-2 border border-transparent rounded-full shadow-sm text-lg text-white focus:outline-none bg-blue-400 hover:bg-blue-400"
                ><FontAwesomeIcon icon={faAngleLeft} className="text-white"/> Back
                </button>
                {message === "" ?
                    <button
                        type="button"
                        disabled={showLoader}
                        onClick={cancel}
                        className={`inline-block float-right ml-2 px-4 py-2 border border-transparent rounded-full shadow-sm text-lg text-white focus:outline-none ${showLoader ? `bg-gray-300 hover:bg-gray-300` : `bg-blue-900 hover:bg-blue-900`}`}
                    ><FontAwesomeIcon icon={faCheck} className="text-white"/> Cancel Booking
                    </button> : null}
                {showLoader && <div className="mt-2 float-right"><Loader/></div>}
            </div>
        </div>
    </>
}
export default CancelBooking;
