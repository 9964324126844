import { useContext } from 'react'
import { DetailsContext } from 'contexts/details'
import VehiclePreviewHeader from 'components/availability/vehicle-preview-header'

const VehiclePreview = ({
  vehicleItem,
  executeChooseVehicleScroll,
  size,
  vehicleData,
}) => {
  let { details } = useContext(DetailsContext)
  return (
    <>
      <div
        className={`${
          size === 'small' ? `col-span-5` : `col-span-12`
        } p-3 pb-0 z-10`}
      >
        <VehiclePreviewHeader
          vehicleItem={vehicleItem}
          size={size}
          vehicleData={vehicleData}
        />

        <div className="clear-both" />
        {!details.isD2D && (
          <>
            {vehicleItem.selectedWaiver && size !== 'small' && (
              <button
                type="button"
                className=" mt-2 px-2 py-0.5 border border-transparent rounded-full shadow-sm text-xs text-white bg-blue-400 hover:bg-blue-400  focus:outline-none"
                onClick={() => executeChooseVehicleScroll()}
              >
                Change Vehicle Selection
              </button>
            )}
          </>
        )}
      </div>
      <div
        className={`${
          size === 'small' ? `col-span-7` : `col-span-12`
        } relative`}
      >
        <img alt="" src={vehicleData[0]?.fimg_url} className="-mt-5 z-0" />

        {size !== 'small' && ( //Hide on sidebar
          <div className="absolute rounded-full w-14 h-14 right-4 bottom-4 border border-blue-400 text-blue-400 bg-white text-center px-2 pt-2.5 pb-2 leading-none">
            <div className="text-xs">Group</div>
            <div className="text-xl leading-none">{vehicleItem.vehicleGrp}</div>
          </div>
        )}
      </div>
    </>
  )
}
export default VehiclePreview
