
import Header from "components/header";
import Footer from "components/footer";
import { useParams } from 'react-router-dom';
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretRight,faLock} from '@fortawesome/free-solid-svg-icons';
import {CONSTANTS} from "contants/constants";
import Loader from "components/loader";
import {ApiConsumer} from "api/ApiConsumer";
import * as Sentry from "@sentry/react";
import {API_ROUTES} from "routes/api";
import {UserContext} from "contexts/user";
import {useHistory} from "react-router-dom";
import {DetailsContext} from "contexts/details";
import {useUtils} from "hooks/utils";
import {Helmet} from "react-helmet";

const PasswordResetPage = () => {

    const params = useParams()

    useEffect(() => {

        //Check if link has expired

        setResetPasswordDetails(prevState => ({
            ...prevState,
            [`txtU`]: params.txtU
        }))

        setResetPasswordDetails(prevState => ({
            ...prevState,
            [`txtC`]: params.txtC
        }))
    }, [])

    const [loading, setLoading] = useState(false)
    const [resetPasswordError, setResetPasswordError] = useState('')
    const [validresetPasswordDetails, setValidresetPasswordDetails] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [resetPasswordDetails, setResetPasswordDetails] = useState({})

    const validateResetPassword = () => {
    
        let invalidFieldFound = false;
        setResetPasswordError("");

        const formFields = [
            {name: 'password', displayName : 'New password' },
            {name: 'passwordConfirm', displayName : 'New password confirmation' },
        ]
        
        formFields.map((field, i) => {

            if (!invalidFieldFound && (
                resetPasswordDetails[field.name]===undefined || 
                resetPasswordDetails[field.name].length===0 || 
                (typeof resetPasswordDetails[field.name]==='string' && resetPasswordDetails[field.name].trim()==="") ||
                resetPasswordDetails[field.name]==="selected"
            )){
                setValidresetPasswordDetails( prevState => ({
                    ...prevState,
                    [field.name]:true
                }));
                
                setResetPasswordError("Please enter "+field.displayName+".");
                
                invalidFieldFound = true;
            }
            else if (field.name==='passwordConfirm' && resetPasswordDetails['passwordConfirm']!==resetPasswordDetails['password']){
                setValidresetPasswordDetails( prevState => ({
                    ...prevState,
                    [field.name]:true
                }));
                setResetPasswordError("Passwords do not match.");
                invalidFieldFound = true;
            }
            else{
                setValidresetPasswordDetails( prevState => ({
                    ...prevState,
                    [field.name]:false
                }));
            } 
        })

        if (invalidFieldFound)
            return false
        else{
            submit()
        }
    }

    function submit() {

        setLoading(true)

        ApiConsumer.post("https://weblogs.bluevinegroup.co.za/user/send-external-password-reset/ajax/", JSON.stringify({resetPasswordDetails}) )
        .then(res => {
        
            if (res.data.error!==undefined)
                setResetPasswordError(res.data.error);
            else{
                
                setFormSubmitted(true)
            }
            setLoading(false)
        })
        .catch(error => Sentry.captureException(error))
        .finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        setResetPasswordError('')
        setValidresetPasswordDetails({})
        setFormSubmitted(false)
    }, [resetPasswordDetails])

    return <>

    <Helmet>
        <title>Password Reset | Bluu Car Rental</title>
    </Helmet>

    <Header />

    <div className="w-full bg-gray-200 text-center">
            <div className="container m-auto py-10 max-w-screen-xl">
                <div className="col-span-12 ">
                    <h1 className="text-3xl lg:text-5xl px-4 lg:p-6 text-blue-900">Password Reset</h1>
                </div>
            </div>
        </div>
  
    <div className="relative bg-gray-200 pb-20 px-4 ">
            <div className="mx-auto lg:w-6/12">


            {formSubmitted ?
            <div className="col-span-12">

                <div className="bg-green-100 border-t-4 border-green-300 rounded-b-lg p-6 shadow-sm mb-5" role="alert">
                    <div className="flex">
                        <div>
                            <p>Your password has been successfully changed.</p>
                        </div>
                    </div>
                </div>

            </div>

            :

            <form onSubmit={event => {
            event.preventDefault()
            return validateResetPassword()
        }}>
                <div className="grid lg:grid-cols-12 gap-4 p-5 lg:p-10 rounded-md bg-gray-100">

                
                      
                  <div className="col-span-12 lg:col-span-6 mt-2">
                        <label htmlFor="password"
                                className="block text-sm font-medium text-gray-700">New password: <span
                            className="text-red-600">*</span></label>
                        <input id="password"
                                name="password"
                                type="password"
                                className={`style-input ${(validresetPasswordDetails.password) ? `border-red-700` : `border-gray-300` }`}
                            
                                onChange={event => {
                                    setResetPasswordDetails(prevState => ({
                                        ...prevState,
                                        [`password`]: event.target.value
                                    }))
                                }}
                                value={resetPasswordDetails.password} 
                        />
                    </div>



                    <div className="col-span-12 lg:col-span-6 mt-2">
                        <label htmlFor="passwordConfirm"
                                className="block text-sm font-medium text-gray-700">Confirm new password: <span
                            className="text-red-600">*</span></label>
                        <input id="passwordConfirm"
                                name="passwordConfirm"
                                type="password"
                                className={`style-input ${(validresetPasswordDetails.passwordConfirm) ? `border-red-700` : `border-gray-300` }`}
                        
                                onChange={event => {
                                    setResetPasswordDetails(prevState => ({
                                        ...prevState,
                                        [`passwordConfirm`]: event.target.value
                                    }))
                                }}
                                value={resetPasswordDetails.passwordConfirm} 
                        />
                    </div>

                    <div className="col-span-12 lg:col-span-12 mt-2">

                    <div className="col-span-12 my-2 text-gray-500 text-sm">All passwords are case-sensitive. Please check CAPS LOCK is turned off.</div>



            

            <div className="col-span-12 text-red-500 text-right my-1">{resetPasswordError}</div>
            <div className="clear-both"/>

            <button 
                type="submit" 
                disabled={loading}
                className={`float-right inline-flex justify-center btn-light ml-2 ${loading ? `bg-gray-300 hover:bg-gray-300` : `bg-blue-400 hover:bg-blue-400`}`}>
                Submit
            </button>
            {loading && <div className="mt-2 float-right"><Loader/></div>}
            
        </div>


        
            
                </div>
                </form>

                 }
            </div>
    </div>

    <Footer />
    </>
}
export default PasswordResetPage;
