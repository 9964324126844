import { Fragment, useContext, useState, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga'
import bluuLogo from 'assets/images/bluu-logo.png'
import bluuLogoMobile from 'assets/images/bluu-logo-mobile.png'
import { CONSTANTS } from 'contants/constants'
import { ApiConsumer } from 'api/ApiConsumer'
import Modals from 'components/modals/index'
import { UserContext } from 'contexts/user'
import { DetailsContext } from 'contexts/details'
import SocialIcons from 'components/header/social-icons'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'

const rentals = [
  { name: 'Manage Existing Booking', href: '/manage-booking/' },
  { name: 'Individual Renters', href: '/get-quote/' },
  { name: 'Corporate Customers', href: '/rentals/corporate-customers/' },
  { name: 'Tour Operators', href: '/rentals/tour-operators/' },
  { name: 'International Travel', href: '/partners/international-travel/' },
]
const transfers = [
  { name: 'Chauffeur Drive', href: '/transfer-services/chauffeur-drive/' },
  { name: 'Door2Door', href: '/transfer-services/door2door-transfers/' },
]
const fleets = [
  { name: 'Car Rental South Africa', href: '/fleet/south-africa-fleet/' },
  { name: 'Car Rental Namibia', href: '/fleet/namibia-fleet/' },
  { name: 'Van & Truck Rental Fleet', href: '/fleet/van-truck-rental-fleet/' },
  { name: 'Door 2 Door Fleet', href: '/fleet/door2door/' },
]
const branches = [
  { name: 'All Branches', href: '/branches/' },
  {
    name: ' Car Rental South Africa Branches',
    href: '/branches/car-rental-branches/',
  },
  {
    name: '  Car Rental Namibia Branches',
    href: '/branches/namibia-rental-branches/',
  },
  { name: 'Door2Door Branches', href: '/branches/door2door-branches/' },
  { name: 'Van Rental Branches', href: '/branches/van-rental-branches/' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function HeaderTest() {
  const [mobileShowing, setMobileShowing] = useState(false)

  const [visibleModal, setVisibleModal] = useState('')
  const [pagePostModalSlug, setPagePostModalSlug] = useState('')
  const [loginAccountType, setLoginAccountType] = useState(
    CONSTANTS.LOGIN_ACCOUNT_TYPE.CORPORATE,
  )

  let { user, setUser } = useContext(UserContext)
  let { details, setDetails } = useContext(DetailsContext)
  let history = useHistory()

  return (
    <>
      <Modals
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        loginAccountType={loginAccountType}
        setLoginAccountType={setLoginAccountType}
        pagePostModalSlug={pagePostModalSlug}
        setPagePostModalSlug={setPagePostModalSlug}
      />

      <div className="relative bg-dark">
        <div className="container m-auto">
          {!mobileShowing ? (
            <>
              <div className="grid gap-4 grid-cols-12 p-4">
                <div className="col-span-8 md:col-span-2 md:pl-4">
                  <NavLink
                    to="/"
                    className="leading-0 inline-block"
                    title="Bluu Car Rental"
                  >
                    <img
                      alt="Bluu Logo"
                      src={bluuLogo}
                      className="w-40 h-auto inline-block"
                    />
                  </NavLink>
                </div>
                <div className="col-span-4 md:col-span-10 md:flex md:justify-end">
                  <div className="md:hidden float-right">
                    <button
                      onClick={() => setMobileShowing(true)}
                      className="text-white font-bold p-2 inline-flex items-center justify-center "
                    >
                      <span className="sr-only">Open menu</span>
                      <MenuIcon className="h-8 w-8" aria-hidden="true" />
                    </button>
                  </div>

                  <Popover.Group
                    as="nav"
                    className="hidden md:inline-flex space-x-6 pt-2"
                  >
                    <Popover className="relative">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? 'text-gray-400' : 'text-gray-white',
                              'group inline-flex items-center text-base text-white hover:text-gray-400 focus:outline-none',
                            )}
                          >
                            <span>Rentals</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? 'text-gray-400' : 'text-white',
                                'h-5 w-5 group-hover:text-gray-400',
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute z-50 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-60 max-w-xs sm:px-0">
                              <div className="shadow-lg overflow-hidden">
                                <div className="relative grid bg-blue-400">
                                  {rentals.map((rental) => (
                                    <NavLink
                                      key={rental.name}
                                      to={rental.href}
                                      className="p-3 block hover:bg-blue-900"
                                      onClick={() => close()}
                                    >
                                      <p className="text-base text-white">
                                        {rental.name}
                                      </p>
                                    </NavLink>
                                  ))}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>

                    <Popover className="relative">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? 'text-gray-400' : 'text-gray-white',
                              'group inline-flex items-center text-base text-white hover:text-gray-400 focus:outline-none',
                            )}
                          >
                            <span>Transfers</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? 'text-gray-400' : 'text-white',
                                'h-5 w-5 group-hover:text-gray-400',
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute z-50 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-60 max-w-xs sm:px-0">
                              <div className="shadow-lg overflow-hidden">
                                <div className="relative grid bg-blue-400">
                                  {transfers.map((transfer) => (
                                    <NavLink
                                      key={transfer.name}
                                      to={transfer.href}
                                      className="p-3 block hover:bg-blue-900"
                                      onClick={() => close()}
                                    >
                                      <p className="text-base text-white">
                                        {transfer.name}
                                      </p>
                                    </NavLink>
                                  ))}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>

                    <Popover className="relative">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? 'text-gray-400' : 'text-gray-white',
                              'group inline-flex items-center text-base text-white hover:text-gray-400 focus:outline-none',
                            )}
                          >
                            <span>Fleet</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? 'text-gray-400' : 'text-white',
                                'h-5 w-5 group-hover:text-gray-400',
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute z-50 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-60 max-w-xs sm:px-0">
                              <div className="shadow-lg overflow-hidden">
                                <div className="relative grid bg-blue-400">
                                  {fleets.map((fleet) => (
                                    <NavLink
                                      key={fleet.name}
                                      to={fleet.href}
                                      className="p-3 block hover:bg-blue-900"
                                      onClick={() => close()}
                                    >
                                      <p className="text-base text-white">
                                        {fleet.name}
                                      </p>
                                    </NavLink>
                                  ))}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>

                    <Popover className="relative">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? 'text-gray-400' : 'text-gray-white',
                              'group inline-flex items-center text-base text-white hover:text-gray-400 focus:outline-none',
                            )}
                          >
                            <span>Branches</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? 'text-gray-400' : 'text-white',
                                'h-5 w-5 group-hover:text-gray-400',
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute z-50 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-60 max-w-xs sm:px-0">
                              <div className="shadow-lg overflow-hidden">
                                <div className="relative grid bg-blue-400">
                                  {branches.map((branch) => (
                                    <NavLink
                                      key={branch.name}
                                      to={branch.href}
                                      className="p-3 block hover:bg-blue-900"
                                      onClick={() => close()}
                                    >
                                      <p className="text-base text-white">
                                        {branch.name}
                                      </p>
                                    </NavLink>
                                  ))}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>

                    <NavLink
                      to="/contacts/"
                      className="text-base text-white hover:text-gray-300"
                    >
                      Contact Us
                    </NavLink>
                  </Popover.Group>
                  <div className="ml-3 hidden md:block">
                    {Object.keys(user).length === 0 ? (
                      <>
                        <div
                          onClick={() => {
                            setVisibleModal(CONSTANTS.MODAL_TYPE.USER)
                          }}
                          className="btn-transparent float-left uppercase px-3 py-2 ml-1 text-sm cursor-pointer"
                          title="Sign In"
                        >
                          Sign In
                        </div>
                        <div
                          onClick={() => {
                            setVisibleModal(CONSTANTS.MODAL_TYPE.REGISTER)
                          }}
                          className="btn-transparent float-left uppercase px-3 py-2 ml-1 text-sm cursor-pointer"
                          title="Register"
                        >
                          Register
                        </div>
                        <SocialIcons />
                      </>
                    ) : (
                      <>
                        <NavLink
                          to={`${
                            user.accountEmail === null ||
                            user.accountEmail === undefined ||
                            user.accountEmail === ''
                              ? `/get-quote/`
                              : `/profile/`
                          }`}
                          className="btn-transparent float-left uppercase px-3 py-2 ml-1 text-sm cursor-pointer"
                          title={user.accountName}
                        >
                          {user.accountName}
                        </NavLink>

                        <div
                          onClick={() => {
                            setUser({})
                            sessionStorage.clear()
                            history.push('/')
                            setDetails((prevState) => ({
                              ...prevState,
                              [`accountType`]: 'I',
                            }))
                            setDetails((prevState) => ({
                              ...prevState,
                              [`accountNo`]: null,
                            }))
                            setDetails((prevState) => ({
                              ...prevState,
                              [`accountName`]: null,
                            }))
                          }}
                          className="btn-transparent float-left uppercase px-3 py-2 text-sm ml-1 cursor-pointer"
                          title="Logout"
                        >
                          Logout
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="grid gap-4 grid-cols-12 px-0 md:px-4 z-50">
                <div className="col-span-12 p-4 pb-0">
                  <NavLink
                    to="/"
                    className="leading-0 inline-block"
                    title="Bluu Car Rental"
                  >
                    <img
                      alt="Bluu Logo"
                      src={bluuLogo}
                      className="w-40 h-auto inline-block"
                    />
                  </NavLink>
                </div>
                <div className="col-span-12 ">
                  <div className="md:hidden float-right absolute top-6 right-6">
                    <button
                      onClick={() => setMobileShowing(false)}
                      className="bg-dark text-white font-bold inline-flex items-center justify-center "
                    >
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-8 w-8" aria-hidden="true" />
                    </button>
                  </div>

                  <Popover className="z-50">
                    <Transition
                      as={Fragment}
                      enter="duration-200 ease-out"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="duration-100 ease-in"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                      show={mobileShowing}
                    >
                      <Popover.Panel focus>
                        <div className="shadow-lg bg-blue-400 divide-y-2 divide-gray-50 pt-2 pb-4">
                          <div className="px-4 py-0">
                            <nav className="w-full py-2">
                              {Object.keys(user).length === 0 ? (
                                <>
                                  <div
                                    onClick={() => {
                                      setMobileShowing(false)
                                      setVisibleModal(CONSTANTS.MODAL_TYPE.USER)
                                    }}
                                    className="btn-navy inline-block bg-dark m-1 uppercase px-3 py-2 text-sm ml-1 cursor-pointer"
                                    title="Sign In"
                                  >
                                    Sign In
                                  </div>
                                  <div
                                    onClick={() => {
                                      setMobileShowing(false)
                                      setVisibleModal(
                                        CONSTANTS.MODAL_TYPE.REGISTER,
                                      )
                                    }}
                                    className="btn-navy inline-block bg-dark m-1 uppercase px-3 py-2 text-sm ml-1 cursor-pointer"
                                    title="Register"
                                  >
                                    Register
                                  </div>
                                </>
                              ) : (
                                <>
                                  <NavLink
                                    to={`${
                                      user.accountEmail === null ||
                                      user.accountEmail === undefined ||
                                      user.accountEmail === ''
                                        ? `/get-quote/`
                                        : `/profile/`
                                    }`}
                                    className="btn-navy inline-block bg-dark m-1 uppercase px-3 py-2 text-sm ml-1 cursor-pointer"
                                    title={user.accountName}
                                  >
                                    {user.accountName}
                                  </NavLink>
                                  <div
                                    onClick={() => {
                                      setUser({})
                                      sessionStorage.clear()
                                      history.push('/')
                                      setDetails((prevState) => ({
                                        ...prevState,
                                        [`accountType`]: 'I',
                                      }))
                                      setDetails((prevState) => ({
                                        ...prevState,
                                        [`accountNo`]: null,
                                      }))
                                      setDetails((prevState) => ({
                                        ...prevState,
                                        [`accountName`]: null,
                                      }))
                                    }}
                                    className="btn-navy inline-block bg-dark m-1 uppercase px-3 py-2 text-sm ml-1 cursor-pointer"
                                    title="Logout"
                                  >
                                    Logout
                                  </div>
                                </>
                              )}
                              <div className="clear-both" />
                            </nav>
                          </div>

                          <div className="mt-2">
                            <nav className="grid grid-cols-1 border-b border-white border-opacity-50 px-4 py-2">
                              {rentals.map((rental) => (
                                <NavLink
                                  key={rental.name}
                                  to={rental.href}
                                  className="p-2 block"
                                  onClick={() => setMobileShowing(false)}
                                >
                                  <p className="text-base text-white">
                                    {rental.name}
                                  </p>
                                </NavLink>
                              ))}
                            </nav>

                            <nav className="grid grid-cols-1 border-b border-white border-opacity-50 px-4 py-2">
                              {transfers.map((transfer) => (
                                <NavLink
                                  key={transfer.name}
                                  to={transfer.href}
                                  className="p-2 block"
                                  onClick={() => setMobileShowing(false)}
                                >
                                  <p className="text-base text-white">
                                    {transfer.name}
                                  </p>
                                </NavLink>
                              ))}
                            </nav>
                            <nav className="grid grid-cols-1 border-b border-white border-opacity-50 px-4 py-2">
                              {fleets.map((fleet) => (
                                <NavLink
                                  key={fleet.name}
                                  to={fleet.href}
                                  className="p-2 block"
                                  onClick={() => setMobileShowing(false)}
                                >
                                  <p className="text-base text-white">
                                    {fleet.name}
                                  </p>
                                </NavLink>
                              ))}
                            </nav>
                            <nav className="grid grid-cols-1 border-b border-white border-opacity-50 px-4 py-2">
                              {branches.map((branch) => (
                                <NavLink
                                  key={branch.name}
                                  to={branch.href}
                                  className="p-2 block"
                                  onClick={() => setMobileShowing(false)}
                                >
                                  <p className="text-base text-white">
                                    {branch.name}
                                  </p>
                                </NavLink>
                              ))}
                            </nav>
                            <nav className="grid grid-cols-1 border-b border-white mb-4 p-2 border-opacity-50 px-4 py-2">
                              <NavLink
                                to="/contacts/"
                                className="text-base text-white hover:text-gray-300 p-2"
                                onClick={() => setMobileShowing(false)}
                              >
                                Contact Us
                              </NavLink>
                            </nav>
                            <nav className="w-full px-4 py-2">
                              <SocialIcons />
                            </nav>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
