import { useState, useEffect, useContext } from "react";
import Loader from "components/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { VehicleContext } from "contexts/vehicle";
import { DetailsContext } from "contexts/details";

const VehicleCostsSection = ({
  headerTitle,
  bookingCosts,
  loading,
  rentalDuration,
}) => {
  let namibiaBranches = ["WH", "WB", "KM", "WT"];
  let { details } = useContext(DetailsContext);

  const [showSection, setShowSection] = useState(false);
  let { vehicle } = useContext(VehicleContext);

  let numbFormatter = new Intl.NumberFormat();

  let sectionCosts = 0;
  bookingCosts.map((costs, i) => {
    sectionCosts += parseFloat(costs.quoteLineChrg);
  });

  return (
    <>
      <div
        onClick={() => {
          setShowSection(!showSection);
        }}
        className="col-span-12 bg-gray-100 p-3 cursor-pointer focus:outline-none border-b border-gray-200"
      >
        {showSection ? (
          <FontAwesomeIcon icon={faAngleDown} />
        ) : (
          <FontAwesomeIcon icon={faAngleRight} />
        )}
        &nbsp;{headerTitle}
        <span className="float-right">
          {!loading ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : (
            <>
              {" "}
              {namibiaBranches.includes(details?.PICKUP_BRANCH)
                ? "N$"
                : "R"}{" "}
              {numbFormatter.format(sectionCosts)}
            </>
          )}
        </span>
      </div>
      {showSection && (
        <div className="col-span-12 p-3 text-xs">
          {!loading ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : !bookingCosts.length ? (
            <div>No {headerTitle.toLowerCase()} included.</div>
          ) : (
            bookingCosts.map((costs, index) => (
              <div key={index} className="py-2 border-b border-gray-100">
                <div className="w-3/4 float-left">
                  {costs.quoteLineDesc1}&nbsp;
                  {costs.quoteLineChrgUnit.trim() === "Per Day" && (
                    <>({rentalDuration} days)</>
                  )}
                  <br />
                  {costs.quoteLineUnitChrg === "0.00" ? (
                    <>{costs.quoteLineDesc2}</>
                  ) : (
                    <>
                      {namibiaBranches.includes(details?.PICKUP_BRANCH)
                        ? "N$"
                        : "R"}
                      {costs.quoteLineUnitChrg}&nbsp;
                      {details.isD2D
                        ? costs.quoteLineChrgUnit.replace("Rental", "Transfer")
                        : costs.quoteLineChrgUnit}
                    </>
                  )}
                </div>
                <div className="w-1/4 float-left text-right">
                  {costs.quoteLineChrgUnit.indexOf("Etolls") === -1
                    ? namibiaBranches.includes(details?.PICKUP_BRANCH)
                      ? "N$" + numbFormatter.format(costs.quoteLineChrg)
                      : "R" + numbFormatter.format(costs.quoteLineChrg)
                    : "TBA"}
                </div>
                <div className="clear-both"></div>
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
};
export default VehicleCostsSection;
