import {useHistory} from "react-router-dom";
import {WEBSITE_ROUTES} from "routes/website";

const BookingConfirmation = ({confirmation,vehicleGrp,confirmationDetails}) => {

    let history = useHistory();

    history.push({
        pathname: WEBSITE_ROUTES.BOOKING_CONFIRMATION.link,
        state: {confirmation,vehicleGrp,confirmationDetails}
    });
    
    return <></>

}
export default BookingConfirmation;
